import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NavigationSidebarContentComponent } from '../navigation-sidebar-content/navigation-sidebar-content.component';
import { CurrentContentChannelIdStore } from '../../../../../pages/content-channel/content-channel/content-channel.service';

@Component({
  selector: 'core-content-channel-overview-subnav',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    NavigationSidebarContentComponent,
  ],
  templateUrl: './content-channel-overview-subnav.component.html',
  styleUrls: [
    './content-channel-overview-subnav.component.scss',
    '../navigation-sidebar-content/navigation-sidebar-content.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentChannelOverviewSubnavComponent extends NavigationSidebarContentComponent {
  currentContentChannelIdStore = inject(CurrentContentChannelIdStore);
  constructor(public router: Router) {
    super();
  }

  baseRoute = this.router.url.split('/', 3).join('/');
}
