import { Deserializable } from '../../shared/interfaces/deserializable';
import { OptionCollection } from '../../shared/interfaces/option-collection';

export interface CountryInterface {
  id: string;
  name: string;
  stateName: string;
  isO3166_1_Alpha2_Code: string;
  isO3166_1_Alpha3_Code: string;
  defaultLanguageId: string;
  enabled: boolean;
}

export type CountryKeys = keyof CountryInterface;

export class Country implements Deserializable, OptionCollection {
  constructor(
    private _id: string = '',
    private _name: string = '',
    private _stateName: string = '',
    private _isO3166_1_Alpha2_Code: string = '',
    private _isO3166_1_Alpha3_Code: string = '',
    private _defaultLanguageId: string = ''
  ) {}

  get optionName(): string {
    return this.name;
  }
  get optionValue(): string {
    return this.id;
  }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }
  get stateName() {
    return this._stateName;
  }
  set stateName(stateName) {
    this._stateName = stateName;
  }
  get isO3166_1_Alpha2_Code() {
    return this._isO3166_1_Alpha2_Code;
  }
  set isO3166_1_Alpha2_Code(isO3166_1_Alpha2_Code) {
    this._isO3166_1_Alpha2_Code = isO3166_1_Alpha2_Code;
  }
  get isO3166_1_Alpha3_Code() {
    return this._isO3166_1_Alpha3_Code;
  }
  set isO3166_1_Alpha3_Code(isO3166_1_Alpha3_Code) {
    this._isO3166_1_Alpha3_Code = isO3166_1_Alpha3_Code;
  }
  get defaultLanguageId(): string {
    return this.defaultLanguageId;
  }
  set defaultLanguageId(defaultLanguageId: string) {
    this._defaultLanguageId = defaultLanguageId;
  }

  deserialize(input: CountryInterface): this {
    Object.assign(this, input);
    // this.defaultLanguageId = new Language().deserialize(<LanguageInterface>input.defaultLanguageId);
    return this;
  }
}
