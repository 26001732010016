import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserAccountStoreService } from '../../account/stores/user-account-store.service';

export const authenticatedGuard: CanActivateFn = (route, state) => {
  const userAccountStore = inject(UserAccountStoreService);
  return userAccountStore.isAuthenticated()
    ? true
    : inject(Router).createUrlTree(['/sign-in']);
};
