import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlComponent } from '../form-control.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';

@Component({
    selector: 'shared-form-textarea',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        GetAsFormControlPipe
    ],
    templateUrl: './form-textarea.component.html',
    styleUrls: ['./form-textarea.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormTextareaComponent extends FormControlComponent {
  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

}
