<ng-container *ngIf="userAccountMenuData$ | async as userAccountMenuData">
  <div (click)="toggle(appMenus.UserAccountDropdown)" id="user-account-menu" *ngIf="userAccountMenuData.authenticatedUserAccount && userAccountMenuData.userAccountLoggedIn" [ngClass]="!(userAccountMenuData.userAccountMenuState)?'is-closed':'is-open'">
    <nav [ngClass]="userAccountMenuData.theme">
      <header>
        <p class="h5">{{userAccountMenuData.authenticatedUserAccount.displayName}}</p>
      </header>
      <ul>
        <li>
          <a (click)="toggle(appMenus.UserAccountDropdown)" [routerLink]="['/account/profile']" [routerLinkActive]="'is-active'">Profile</a>
        </li>
        <li>
          <a (click)="toggle(appMenus.UserAccountDropdown)" [routerLink]="['/account/twofactor/enable']" [routerLinkActive]="'is-active'">Two Factor Authentication</a>
        </li>
        <li>
          <a (click)="toggle(appMenus.UserAccountDropdown)" [routerLink]="['/account/password/change']" [routerLinkActive]="'is-active'">Change Password</a>
        </li>
        <li>
          <a (click)="toggle(appMenus.UserAccountDropdown)" [routerLink]="['/account/settings/preferences']" [routerLinkActive]="'is-active'">Preferences</a>
        </li>
        <li class="mt-1">
          <a (click)="toggle(appMenus.UserAccountDropdown)" [routerLink]="['/sign-out']" [routerLinkActive]="'is-active'">Sign-out</a>
        </li>
      </ul>
    </nav>
  </div>
</ng-container>
