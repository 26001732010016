import { OptionCollection } from '../../shared/interfaces/option-collection';
import { Deserializable } from '../../shared/interfaces/deserializable';

export interface PronounsInterface {
  id: string;
  languageId: string;
  name: string;
}

export type PronounsKeys = keyof PronounsInterface;

export class Pronouns implements Deserializable, OptionCollection {
  constructor(
    private _id: string = '',
    private _languageId: string = '',
    private _name: string = ''
  ) {}

  get optionName(): string {
    return this.name;
  }
  get optionValue(): string {
    return this.id;
  }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  set languageId(languageId) {
    this._languageId = languageId;
  }
  get languageId() {
    return this._languageId;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }

  deserialize(input: PronounsInterface): this {
    Object.assign(this, input);
    return this;
  }
}
