<ng-container
  *ngIf="
    navigationSecondarySubnavData$ | async as navigationSecondarySubnavData
  "
>
  <core-navigation-sidebar-content [snapshot]="snapshot">
    <nav [ngClass]="navigationSecondarySubnavData.theme.class">
      <ul>
        <li
          *ngIf="navigationSecondarySubnavData.userAccountLoggedIn"
          class="breadcrumb"
        >
          <a [routerLink]="'/'">
            <span class="mci-arrowhead-left"></span>
            <span class="text">OTT Xperience</span>
          </a>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button type="button" (click)="toggle(appMenus.XpGeneralMenu)">
            <span class="nav-icon mci-setting-solid"></span>
            <span class="feature-label">General</span>
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.XpGeneralMenu
                ]
              "
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.XpGeneralMenu
                ]
              "
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul
            *ngIf="
              navigationSecondarySubnavData.appMenusState[
                appMenus.XpGeneralMenu
              ]
            "
          >
            <li>
              <a
                [routerLink]="'/xperience/general/settings'"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
                >Settings</a
              >
            </li>
            <li>
              <a
                [routerLink]="'/xperience/general/content'"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
                >Templates</a
              >
            </li>
            <li>
              <a
                [routerLink]="'/xperience/general/languages'"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
                >Languages</a
              >
            </li>
            <li>
              <a
                [routerLink]="'/xperience/faqs/categories'"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
                >FAQ Categories</a
              >
            </li>
            <li>
              <a
                [routerLink]="'/xperience/faqs'"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
                >FAQs</a
              >
            </li>
          </ul>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button
            type="button"
            (click)="toggle(appMenus.XpPaymentSubscriptionsMenu)"
          >
            <span class="nav-icon mci-cash-payment"></span>
            <span class="feature-label"
              >Payment&nbsp;&amp;&nbsp;Subscriptions</span
            >
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.XpPaymentSubscriptionsMenu
                ]
              "
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.XpPaymentSubscriptionsMenu
                ]
              "
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul
            *ngIf="
              navigationSecondarySubnavData.appMenusState[
                appMenus.XpPaymentSubscriptionsMenu
              ]
            "
          >
            <li *ngIf="navigationSecondarySubnavData.isAdmin">
              <a
                [routerLink]="'/xperience/payment-methods'"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
                >Payment methods</a
              >
            </li>
            <li>
              <a
                [routerLink]="'/xperience/subscriptions'"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
                >Subscriptions</a
              >
            </li>
            <li>
              <a
                [routerLink]="'/xperience/subscriptions/usps'"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
                >USP's<s></s
              ></a>
            </li>
            <!-- <li><a [routerLink]="'/xperience/subscriptions/special-offers'" [routerLinkActive]="'is-active'" [routerLinkActiveOptions]="{exact: true}">Special Offers</a></li> -->
          </ul>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button
            type="button"
            (click)="toggle(appMenus.XpContentPromotionMenu)"
          >
            <span class="nav-icon mci-megaphone"></span>
            <span class="feature-label">Content&nbsp;&amp;&nbsp;Promotion</span>
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.XpContentPromotionMenu
                ]
              "
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.XpContentPromotionMenu
                ]
              "
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul
            *ngIf="
              navigationSecondarySubnavData.appMenusState[
                appMenus.XpContentPromotionMenu
              ]
            "
          >
            <li>
              <a
                [routerLink]="'/xperience/banners'"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
                >Banners</a
              >
            </li>
            <!-- <li><a [routerLink]="'/xperience/sliders'" [routerLinkActive]="'is-active'" [routerLinkActiveOptions]="{exact: true}">Sliders</a></li> -->
          </ul>
        </li>
      </ul>
    </nav>
  </core-navigation-sidebar-content>
</ng-container>
