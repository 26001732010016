<ng-container [formGroup]="formGroup">
  <div
    class="form-control {{ styleClass }} {{ additionalStyleClasses }}"
    [ngClass]="{ 'hide-errors-hints': !showErrorHints }"
  >
    <label
      for="{{ id }}"
      [ngClass]="{ 'visually-hidden': !showLabel, disabled: isDisabled }"
      >{{ label }}</label
    >
    <div class="search-wrapper control-wrapper">
      <div *ngIf="showSearchIconInInput" class="search-icon">
        <span class="mci-search textless"></span>
      </div>
      <input
        class="search"
        id="{{ id }}"
        #entitySearchElement
        [formControl]="control | getAsFormControl"
        [attr.placeholder]="showPlaceholder ? placeholder + '&#8230;' : null"
      />
      <div
        (click)="clearSearchInput($event)"
        class="search-x"
        [ngClass]="{ 'can-clear-me': control.value }"
      >
        <span class="mci-close"></span>
      </div>
    </div>
    <div
      class="submit-warning"
      [ngClass]="{ 'invalid-after-submit': submitted && control.invalid }"
    >
      <span class="mci-warning"></span>
    </div>
  </div>
  <div
    class="form-errors {{ styleClass }}"
    *ngIf="showErrorHints && control.invalid && (submitted || control.touched)"
  >
    <p>{{ getErrorMessage() }}</p>
  </div>
</ng-container>
