import { Deserializable } from 'src/app/shared/interfaces/deserializable';
import { OptionCollection } from '../../../shared/interfaces/option-collection';

export interface UserAccountPersonInterface {
  personId: string;
  createdByTenantId: string;
  createdByContentChannelId: string;
}
export interface UserAccountInterface {
  id: string;
  activated: boolean;
  activationKey: string;
  avatar: string | null;
  creationDate: string;
  displayName: string;
  emailAddress: string;
  email: string;
  enabled: boolean;
  languageId: string;
  lastLogin: string;
  lastPasswordChange: string;
  level: number;
  name: string;
  newsletter: number;
  passwordChangeEnforced: boolean;
  person: UserAccountPersonInterface | null;
  surname: string;
  twoFactorAuthEnabled: boolean;
  username: string;
  password: string;
  isPublic: boolean;
}

export type UserAccountKeys = keyof UserAccountInterface;

export class UserAccount implements Deserializable, OptionCollection {
  constructor(
    private _id: string = '',
    private _activated: boolean = false,
    private _activationKey: string = '',
    private _avatar: string | null = null,
    private _creationDate: string = '',
    private _displayName: string = '',
    private _emailAddress: string = '',
    private _email: string = '',
    private _enabled: boolean = false,
    private _languageId: string = '',
    private _lastLogin: string = '',
    private _lastPasswordChange: string = '',
    private _level: number = 0,
    private _name: string = '',
    private _newsletter: number = 0,
    private _passwordChangeEnforced: boolean = false,
    private _person: UserAccountPersonInterface | null = null,
    private _surname: string = '',
    private _twoFactorAuthEnabled: boolean = false,
    private _username: string = '',
    private _password: string = '',
    private _isPublic: boolean = false,
  ) {}

  get optionName() {
    return this.name;
  }
  get optionValue() {
    return this.id;
  }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get languageId() {
    return this._languageId;
  }
  set languageId(languageId) {
    this._languageId = languageId;
  }
  get person() {
    return this._person;
  }
  set person(person: UserAccountPersonInterface | null) {
    this._person = person;
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(enabled) {
    this._enabled = enabled;
  }
  get username() {
    return this._username;
  }
  set username(username) {
    this._username = username;
  }
  get newsletter() {
    return this._newsletter;
  }
  set newsletter(newsletter) {
    this._newsletter = newsletter;
  }
  get emailAddress() {
    return this._emailAddress;
  }
  set emailAddress(emailAddress) {
    this._emailAddress = emailAddress;
  }
  get email() {
    return this._emailAddress;
  }
  set email(emailAddress) {
    this._emailAddress = emailAddress;
  }
  get password() {
    return this._password;
  }
  set password(password) {
    this._password = password;
  }
  get twoFactorAuthEnabled() {
    return this._twoFactorAuthEnabled;
  }
  set twoFactorAuthEnabled(twoFactorAuthEnabled) {
    this._twoFactorAuthEnabled = twoFactorAuthEnabled;
  }
  get isPublic() {
    return this._isPublic;
  }
  set isPublic(isPublic) {
    this._isPublic = isPublic;
  }
  get activated() {
    return this._activated;
  }
  set activated(activated) {
    this._activated = activated;
  }
  get activationKey() {
    return this._activationKey;
  }
  set activationKey(activationKey) {
    this._activationKey = activationKey;
  }
  get passwordChangeEnforced() {
    return this._passwordChangeEnforced;
  }
  set passwordChangeEnforced(passwordChangeEnforced) {
    this._passwordChangeEnforced = passwordChangeEnforced;
  }
  get creationDate() {
    return this._creationDate;
  }
  set creationDate(creationDate) {
    this._creationDate = creationDate;
  }
  get lastLogin() {
    return this._lastLogin;
  }
  set lastLogin(lastLogin) {
    this._lastLogin = lastLogin;
  }
  get lastPasswordChange() {
    return this._lastPasswordChange;
  }
  set lastPasswordChange(lastPasswordChange) {
    this._lastPasswordChange = lastPasswordChange;
  }
  get level() {
    return this._level;
  }
  set level(level) {
    this._level = level;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }
  get surname() {
    return this._surname;
  }
  set surname(surname) {
    this._surname = surname;
  }
  get displayName() {
    return this._displayName;
  }
  set displayName(displayName) {
    this._displayName = displayName;
  }
  get avatar() {
    return this._avatar;
  }
  set avatar(avatar) {
    this._avatar = avatar;
  }

  deserialize(input: UserAccountInterface): this {
    Object.assign(this, input);
    return this;
  }
}
