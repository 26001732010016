import { inject } from "@angular/core";
import { AuthenticationStoreService } from "./stores/authentication-store.service";
import { environment } from "src/environments/environment";
import { AppService } from "../core/services/app.service";

export function isTimeToRefreshToken(): boolean {
  const appService = inject(AppService);
  const authenticationStore = inject(AuthenticationStoreService);
  // appService.debug("Token: checking if time to refresh...");
  let result = false;
  const d = new Date();
  // Create date limit margin X minutes before token expiry.
  // Get token expiry from user object in storage, not local (browser) storage.
  const tokenExpiry = authenticationStore.getTokenExpiry();
  if (tokenExpiry) {
    const limit = new Date(tokenExpiry);
    limit.setTime(limit.getTime() - (environment.refreshTokenMargin * 1000));
    if (d.getTime() > limit.getTime()) {
      appService.debug("Time to refresh...");
      result = true;
    }
  }
  return result;
}
