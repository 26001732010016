<ng-container *ngIf="tenantData$ | async as tenantData">
  <div class="tenant-select">
    @if(tenantData.myTenants.length > 1) {
    <button
      (click)="isOpen = !isOpen"
      class="is-transparent select-button"
    >
      @if(tenantData.currentTenant) {
      <picture class="tenant-icon">
        <img
          *ngIf="icon()"
          [src]="icon()"
          [alt]="tenantData.currentTenant.name"
        >
      </picture>
      <span
        class="current-tenant"
        [ngClass]="{'is-active': isOpen}"
      >
        {{tenantData.currentTenant.name}}
        <span class='mci-arrowhead-down-solid'></span>
      </span>
      }
    </button>
    } @else {
    @if(tenantData.currentTenant) {
    <div class="single-tenant">
      <picture class="tenant-icon">
        <img
          *ngIf="icon()"
          [src]="icon()"
          [alt]="tenantData.currentTenant.name"
        >
      </picture>
      <span class="current-tenant">
        {{tenantData.currentTenant.name}}
      </span>
    </div>
    }
    }
  </div>

  <div
    id="tenants-menu"
    [ngClass]="{'is-open' : isOpen}"
  >
    <ul>
      <li *ngFor="let tenant of tenantData.myTenants; trackBy myTenantsTracker">
        <button
          role="button"
          tabindex="0"
          [ngClass]="{'is-active': tenant?.id === tenantData.currentTenant?.id}"
          (click)="updateCurrentTenant(tenant)"
          class="is-transparent"
        >{{tenant.name}}</button>
      </li>
    </ul>
  </div>
</ng-container>