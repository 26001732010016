import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FeedbackComponent } from './components/feedback.component';

@Component({
    selector: 'app-home',
    imports: [FeedbackComponent],
    providers: [],
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {}
