import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FrontendCookieStorageService } from '../../core/services/storage/frontend-cookie-storage.service';

export const authenticatedGuard: CanActivateFn = () => {
  const cookieStorageService = inject(FrontendCookieStorageService);
  const token =
    localStorage.getItem('t') || cookieStorageService.getItem('token');
  const refreshToken =
    localStorage.getItem('rt') || cookieStorageService.getItem('refreshToken');
  return token && refreshToken
    ? true
    : inject(Router).createUrlTree(['/sign-in']);
};
