import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { KeyStringObj } from '../../services/ad-insertion-monitor.service';

interface Headers {
  name: string;
  value: string;
}

@Component({
  selector: 'app-data-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent {
  @Input() headers?: Headers[];
  @Input() data?: KeyStringObj[];
  @Output() updated = new EventEmitter<KeyStringObj[]>();

  selectedRows: KeyStringObj[] = [];

  selectRow(row: KeyStringObj) {
    const i = this.selectedRows.findIndex((e) => e === row);
    if (i > -1) {
      this.selectedRows.splice(i, 1);
    } else {
      this.selectedRows.push(row);
    }
    this.updated.emit(this.selectedRows);
  }
  checkActive(item: KeyStringObj) {
    return this.selectedRows.some((e) => e === item);
  }
}
