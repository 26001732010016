import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, combineLatest, map } from 'rxjs';
import { ErrorService } from '../../services/error.service';
import { Q90ErrorResponseTypes } from '../../interfaces/q90-response';

interface ErrorData {
  error: Q90ErrorResponseTypes | null;
}

@Component({
  selector: 'shared-error',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  private errorService = inject(ErrorService);

  errorData$!: Observable<ErrorData>;

  ngOnInit(): void {
    this.errorData$ = combineLatest(
      [
        this.errorService.error$
      ]
    ).pipe(
      map((
        [
          error
        ]
      ) => {
        return {
          error,
        };
      })
    );
  }
}
