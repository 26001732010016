import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { UserAccountStoreService } from '../../../account/stores/user-account-store.service';
import { TeamServiceService } from '../../../entity/team/services/team-service.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentTeamIdStore {
  private userAccountStore = inject(UserAccountStoreService);
  private teamServiceService = inject(TeamServiceService);

  private currentTeamIdStore = new BehaviorSubject<string>('');
  private currentTeamNameStore = new BehaviorSubject<string>('');
  currentTeamId$: Observable<string> = this.currentTeamIdStore.asObservable();
  currentTeamName$: Observable<string> =
    this.currentTeamNameStore.asObservable();

  setTeamId(id: string): void {
    this.currentTeamIdStore.next(id);
  }
  setTeamName(name: string): void {
    this.currentTeamNameStore.next(name);
  }

  getTeamId(): string {
    let result: string = '';
    this.currentTeamIdStore.subscribe((id) => {
      result = id;
    });
    return result;
  }
  getTeamName(): string {
    let result: string = '';
    this.currentTeamNameStore.subscribe((name) => {
      result = name;
    });
    return result;
  }

  getTeamNameById(id: string) {
    this.teamServiceService
      .fetchTeamName(id)
      .pipe(take(1))
      .subscribe((team) => {
        this.setTeamId(id);
        this.setTeamName(team.data.value);
      });
  }
}
