// https://refine.dev/blog/typescript-decorators/#property-decorators-in-typescript
export function PrimeNgDate() {
  return function (target: any, key: string) {
    let currentValue = target[key];
    Object.defineProperty(target, key, {
      set: (newValue: string) => {
        currentValue = new Date(newValue);
      },
      get: () => currentValue
    });
  };
}
