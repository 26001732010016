import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthenticationStoreService } from '../../authentication/stores/authentication-store.service';

export const TokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authenticationStore = inject(AuthenticationStoreService);

  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authenticationStore.getToken()}`,
    },
  });

  return next(authReq);
};
