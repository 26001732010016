<ng-container *ngIf="userAccountTenantsMenuData$ | async as userAccountTenantsMenuData">
  <div (click)="toggle(appMenus.UserAccountTenantsDropdown)" id="user-account-tenants-menu" *ngIf="userAccountTenantsMenuData.authenticatedUserAccount" [ngClass]="!(userAccountTenantsMenuData.userAccountTenantsMenuState)?'is-closed':'is-open'">
    <nav [ngClass]="userAccountTenantsMenuData.theme.class">
      <header>
        <p class="h5">My Tenants</p>
      </header>
      <ul>
        <li *ngFor="let tenant of userAccountTenantsMenuData.myTenants; trackBy myTenantsTracker">
          <a role="button" tabindex="0" [ngClass]="{'is-active': tenant?.id === userAccountTenantsMenuData.myCurrentTenant?.id}" (click)="setCurrentTenant(tenant, userAccountTenantsMenuData.myCurrentTenant)">{{tenant.name}}</a>
        </li>
      </ul>
    </nav>
  </div>
</ng-container>
