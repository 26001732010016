import { Injectable } from '@angular/core';
import { take, filter, Observable, map, takeUntil } from 'rxjs';
import { PersonInterface, Person } from '../person/models/person';
import { EntityDetailStoreService } from './entity-detail-store.service';
import { Q90Response } from '../../shared/models/q90-response';
import { Q90ResponseData } from '../../shared/interfaces/q90-response';
import { Q90EntityInterface } from '../models/q90-entity-base';

@Injectable({
  providedIn: null,
})
export class PersonDetailStoreService extends EntityDetailStoreService {
  protected entitySearchEndpoint = '';

  constructor() {
    super();
  }

  hasAvatar(): Observable<boolean> {
    return this.entity$.pipe(
      filter(person => person instanceof Person),
      map(person => ((person as Person).avatar) !== null && ((person as Person).avatar) !== ''),
      takeUntil(this.destroyed)
    );
  }

  updateStore(data: Partial<PersonInterface>): void {
    this.entity$
      .pipe(
        take(1),
        filter((entity) => entity !== null)
      )
      .subscribe((entity) => {
        const person = entity as Person;
        const newPerson = this.updateEntity<Person, PersonInterface>(person, data);
        this.setStore(newPerson);
      });
  }

  override loadEntity(id: string): void {
    this.loadingStore.next(true);
    this.getById(id)
      .pipe(take(1))
      .subscribe({
        next: (response: Q90ResponseData<Q90EntityInterface>) => {
          this.loadingStore.next(false);
          if (response.statusCode === 200) {
            const tenantObj: Person = new Person(id).deserialize(
              <PersonInterface>response.data
            );
            this.entityStore.next(tenantObj);
          } else {
            this.errorMessagesStore.setErrorMessage(
              new Q90Response().deserialize(response)
            );
          }
        },
        error: (error) => {
          this.loadingStore.next(false);
          this.errorService.setError(new Error('Unable to get person.'));
        },
        complete: () => {
          this.loadingStore.next(false);
        },
      });
  }
}
