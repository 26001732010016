<ng-container *ngIf="footerData$ | async as footerData">
  <footer
    id="q90-footer"
    [ngClass]="footerData.theme.class">
    <p
      class="my-0"
      *ngIf="env.version">
      Version <span class="text-color-max">{{ env.version }}</span>
    </p>
    <p class="my-0">&copy; 2024 Quatro90 | Product of <a href="https://www.mediachoice.eu/">Media Choice</a></p>
    <p class="my-0">{{ angularVersion }} ({{ env.name }})</p>
  </footer>
</ng-container>
