import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrentContentChannelIdStore {
  private currentContentChannelIdStore = new BehaviorSubject<string>('');
  private currentContentChannelNameStore = new BehaviorSubject<string>('');
  currentContentChannelId$: Observable<string> =
    this.currentContentChannelIdStore.asObservable();
  currentContentChannelName$: Observable<string> =
    this.currentContentChannelNameStore.asObservable();

  setContentChannelId(id: string): void {
    this.currentContentChannelIdStore.next(id);
  }
  setContentChannelName(name: string): void {
    this.currentContentChannelNameStore.next(name);
  }

  getContentChannelId(): string {
    let result: string = '';
    this.currentContentChannelIdStore.subscribe((id) => {
      result = id;
    });
    return result;
  }
  getContentChannelName(): string {
    let result: string = '';
    this.currentContentChannelNameStore.subscribe((name) => {
      result = name;
    });
    return result;
  }
}
