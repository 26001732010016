import { Deserializable } from "../interfaces/deserializable";

export interface Q90MessageInterface {
  message: string;
  level: string;
}

export type Q90MessageLevels = 'success' | 'warning' | 'error';

export class Q90Message implements Deserializable {

  constructor(
    private _message: string | null = null,
    private _level: Q90MessageLevels = 'warning'
  ) {

  }

  get message() {
    return this._message;
  }
  set message(message) {
    this._message = message;
  }
  get level() {
    return this._level;
  }
  set level(level) {
    this._level = level;
  }

  deserialize(input: Q90MessageInterface): this {
    Object.assign(this, input);
    return this;
  }

}
