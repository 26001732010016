import { Deserializable } from "../interfaces/deserializable";
import { Q90ResponseMessageInterface, Q90ErrorResponseInterface, Q90ResponseData } from "../interfaces/q90-response";

export enum Q90BackendErrorCodes {
  UsernameInUse = 1,
  EmailAddressInUse = 2,
  ComplexPasswordRequired = 3,
  ConnectionError = 4,
  UserDoesNotExists = 5,
  ActivationKeyNotFound = 6,
  UsernameOrPasswordIncorrect = 7,
  NotLoggedIn = 8,
  AlreadyLoggedIn = 9,
  InvalidTFA = 10,
  KeyExpired = 11,
  EmailAddressNotFound = 12,
  InvalidInputDataType = 13,
  InvalidAuthenticationToken = 14,
  AccessDenied = 15,
  InternalError = 16,
  FatalError = 17,
  GatewayError = 18,
  UserNotActivated = 19,
  EmailNotValid = 20,
  UsernameEmpty = 21,
  NotAllDataProvided = 22,
  UserDisabled = 23,
  OperationNotAllowed = 24,
  LicenseError = 25,
  InvalidInputData = 26,
  TFAAlreadySetup = 27,
  TFANotEnabled = 27,
  TenantAlreadyExists = 28,
  TenantDoesNotExists = 29,
  AlreadyMember = 30,
  ChannelDoesNotExists = 31,
  ChannelAlreadyExists = 32,
  TeamDoesNotExists = 33,
  TeamAlreadyExists = 34,
  NoInvitationFound = 35,
  NotATenantMember = 36,
  NotATeamMember = 37,
  ItemAlreadyExists = 38,
  RemoveMediaNotAllowedBecauseReferenced = 39,
  RemoveGroupNotAllowedBecauseReferenced = 40,
  RemoveCategoryNotAllowedBecauseReferenced = 41,
  WaitBetweenOperations = 42,
  InvalidApiKey = 43,
  ItemDoesNotExists = 44,
  MinimumSearchLengthNotMet = 45,
  MediaDoesNotExists = 46,
  ProgramGridItemMediaLinkDoesNotExists = 47,
  ContentTypeAlreadyReferenced = 48,
  TargetGroupAlreadyAdded = 49,
  GenreAlreadyReferenced = 50,
  ChannelIdOrTenantIdNotReferenced = 51,
  InvalidCaptcha = 52,
  TimeOverlap = 53,
  ProgramGridIsReferenced = 54,
  GenreIsReferenced = 55,
  ProgramGridSeries_SeasonAlreadyExists = 56,
  ProgramGridItemTemplateNotExists = 57,
  ProgramGridSeasonNotExists = 58,
  ParameterAlreadyAdded = 59,
  ProgramGridParameterNotFound = 60,
  AssetNotFound = 61,
  ItemNotFound = 62,
  AssetNotLinkedToItem = 63,
  AssetAlreadyLinkedToitem = 64,
  ItemTypeNotFound = 65,
  VariableListNotFound = 66,
  VariableListValueNotFound = 67,
  VariableNotFound = 68,
  NoThirdpartyLogIn = 69,
  NoThirdpartyCookiesAvailable = 70,
  TextTVTemplateNotFound = 71,
  QueueMessageError = 72,
  ItemNotFolder = 73,
  FaqCategoryDoesntExist = 74,
  ContentAlreadyExistInLanguage = 75,
  ContentDoesntExistInLanguage = 76,
  MediaContentTypeDoesNotExists = 77,
  VariableMediaTypeNotFound = 78,
  PrimaryKeyNotFound = 79,
  ProgramGridNotFound = 80,
  DeleteAbortDueReferences = 81,
  AlreadyLinked = 82,
  LinkNotFound = 83,
  AllDayRepeatNotAllowed = 84,
  InvalidAuthenticationType = 85,
  EmailAddressNotConfigured = 86,
  ContentChannelIdRequired = 87,
  RemoteEnvironmentException = 88,
  TenantRequired = 89,
  UnspecifiedError = 5000, // Added manually to bakcend list
}

export class Q90Response implements Deserializable {

  constructor(
    private _data: any = {},
    private _success: boolean = false,
    private _performanceCost: number = 0,
    private _source: string = "",
    private _statusCode: number = 0,
    private _resultMessages: Q90ResponseMessageInterface[] = [],
    private readonly _message: string | null = null
  ) {

  }

  isError(): boolean {
    return this.hasBackendErrors();
  }

  static isSuccess(response: Q90ResponseData<any>): boolean {
    const responseObj = new Q90Response().deserialize(response);
    return (response.statusCode === 200 && !responseObj.isError());
  }

  get message(): string | null {
    if (this.hasBackendErrors()) {
      return this.getBackendMessages().join()
    } else {
      return null;
    }
  }

  get data() {
    return this._data;
  }
  set data(data) {
    this._data = data;
  }
  get success() {
    return this._success;
  }
  set success(success) {
    this._success = success;
  }
  get performanceCost() {
    return this._performanceCost;
  }
  set performanceCost(performanceCost) {
    this._performanceCost = performanceCost;
  }
  get source() {
    return this._source;
  }
  set source(source) {
    this._source = source;
  }
  get statusCode() {
    return this._statusCode;
  }
  set statusCode(statusCode) {
    this._statusCode = statusCode;
  }
  get resultMessages() {
    return this._resultMessages;
  }
  set resultMessages(resultMessages) {
    this._resultMessages = resultMessages;
  }


  private hasBackendErrors(): boolean {
    let result = false;
    this.resultMessages.forEach(message => {
      if (Object.values(Q90BackendErrorCodes).includes(+message.code)) {
        result = true;
      }
    })
    return result;
  }

  private getBackendMessages(): string[] {
    const result: string[] = [];
    this.resultMessages.forEach(message => {
      if (message.code === Q90BackendErrorCodes.RemoteEnvironmentException && message.innerMessage) {
        message.innerMessage.forEach(innerMessage => {
          result.push(innerMessage.message);
        })
      } else {
        result.push(message.message);
      }
    })
    return result;
  }

  deserialize(input: Q90ErrorResponseInterface): this {
    Object.assign(this, input);
    return this;
  }

}
