import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ModalComponent } from '../../../../shared/components/modal/modal.component';
('../../services/ad-insertion-monitor.service');

@Component({
  selector: 'app-schedule-modal',
  standalone: true,
  imports: [CommonModule, ModalComponent],
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleModalComponent {
  @Input() modalContent: string[] = [];

  setElements(content: string[]): string[] {
    // Set up the return array
    const elmCollection: string[] = [];
    // loop through all the elements
    content.forEach((elm) => {
      // split all elements into new array
      let elms = (elm as string).split(' ').filter((n) => n);
      // Check if the array is the right length
      if (elms.length < 15) {
        // Add empty blocks if length is not long enough
        const diff = 15 - elms.length;
        for (let i = 0; i < diff; i++) {
          elms.push('');
        }
      } else if (elms.length > 15) {
        // Merge elements if length is longer than 15 elements
        const lastElm = elms.pop();
        const slicedArr = elms.slice(13);
        const newArr = elms.slice(0, 13);
        newArr.push(slicedArr.join(' '));
        newArr.push(lastElm || '');
        elms = newArr;
      }
      // Add a break to last element
      elms[14] = elms[14] + '<br>';
      // Push it all to the return array
      elms.forEach((elm) => {
        elmCollection.push(elm);
      });
    });
    return elmCollection;
  }
}
