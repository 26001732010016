import { ApplicationRef, Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, filter, take, takeUntil } from 'rxjs';
import { FrontendLocalStorageService } from './storage/frontend-local-storage.service';
import { LocalStorageKeys } from './app.service';
import { Theme } from '../models/theme';
import { PrimeableService } from './primeable.service';

export type ApplicationThemeSettings = | 'light-mode' | 'dark-mode' | 'system-mode';

// See: https://pkief.medium.com/automatic-dark-mode-detection-in-angular-material-8342917885a0
@Injectable({
  providedIn: 'root',
})
export class AppThemesService extends PrimeableService {

  private lightTheme: Theme = new Theme('light-mode', "Light");
  private darkTheme: Theme = new Theme('dark-mode', "Dark");
  private systemTheme: Theme = new Theme('system-mode', "System");

  private localStorageService = inject(FrontendLocalStorageService);
  private ref = inject(ApplicationRef);

  // All themes
  protected themesStore = new BehaviorSubject<Theme[]>([]);
  themes$: Observable<Theme[]> = this.themesStore.asObservable();

  // Is the application set to light, dark or system mode?
  protected themeSettingStore = new BehaviorSubject<Theme | null>(null);
  themeSetting$: Observable<Theme | null> = this.themeSettingStore.asObservable();

  // Actual store of theme that should be used in templates.
  // System is a possible themeSetting but has no class.
  protected themeStore = new BehaviorSubject<Theme>(this.lightTheme);
  theme$: Observable<Theme> = this.themeStore.asObservable();

  constructor() {
    super();
    this.themes$.pipe(
      takeUntil(this.destroyed)
    ).subscribe(
      (themes) => {
        // Get mode value from local storage if available and prime setting store...
        const themeInStorage = this.getThemeInStorage();
        if (themeInStorage) {
          const storedThemeId = themes.filter(t => t.id === themeInStorage)[0];
          if (storedThemeId instanceof Theme) {
            this.themeSettingStore.next(storedThemeId);
          }
        }
      }
    )
    this.setTheme();

    // Watch for changes of the preference in the browser/OS settings...
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        this.setTheme();
        this.ref.tick();
      });
  }

  protected prime() {
    this.themesStore.next([this.lightTheme, this.darkTheme, this.systemTheme]);
  }

  protected authenticated(): void { }

  protected refresh(): void {
    this.themesStore.next([this.lightTheme, this.darkTheme, this.systemTheme]);
  }
  protected logout(): void { }

  setThemeSetting(theme: Theme) {
    this.localStorageService.setItem(LocalStorageKeys.Theme, theme.id);
    this.themeSettingStore.next(theme);
    this.setTheme();
  }

  setThemeSettingById(themeId: string): void {
    this.themes$.pipe(
      take(1)
    ).subscribe(
      (themes) => {
        const choseTheme = themes.find(theme => theme.id === themeId);
        if (choseTheme) {
          this.setThemeSetting(choseTheme);
        }
      }
    );
  }

  private getThemeInStorage(): ApplicationThemeSettings {
    return this.localStorageService.getItem(
      LocalStorageKeys.Theme
    ) as ApplicationThemeSettings;
  }

  private setTheme() {
    this.themeSetting$.pipe(
      take(1),
      filter(theme => theme instanceof Theme)
    ).subscribe(
      (theme) => {
        switch (theme!.id) {
          case 'dark-mode':
            this.themeStore.next(theme!);
            // document.body.classList.add(theme!.id);
            break;
          case 'light-mode':
            this.themeStore.next(theme!);
            // document.body.classList.remove('dark-mode');
            break;
          case 'system-mode':
            if (
              window.matchMedia &&
              window.matchMedia('(prefers-color-scheme: dark)').matches
            ) {
              this.themeStore.next(this.darkTheme);
              // document.body.classList.add('dark-mode');
            }
            if (
              window.matchMedia &&
              window.matchMedia('(prefers-color-scheme: light)').matches
            ) {
              this.themeStore.next(this.lightTheme);
              // document.body.classList.remove('dark-mode');
            }
            break;
          default:
            break;
        }
      }
    );
  }
}
