import {
  ApplicationConfig,
  importProvidersFrom,
  NgModule,
} from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';
import { DatePipe } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { ApiModule } from './src/api/api.module';
import { MainRoutes } from './src/app/app-routing';
import { AuthenticationInterceptor } from './src/app/authentication/authentication-interceptor';
import { TokenInterceptor } from './src/app/core/interceptors/tokenInterceptor';
import { environment } from './src/environments/environment';
import { definePreset } from "@primeng/themes";

NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [],
});

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    providePrimeNG({}),

    DatePipe,
    provideRouter(MainRoutes),
    provideHttpClient(withInterceptors([AuthenticationInterceptor])),
    importProvidersFrom([BrowserAnimationsModule]),
    provideAngularSvgIcon(),
    importProvidersFrom(
      ApiModule.forRoot({ rootUrl: environment.tempAiApiEndpointRoot }),
    ),
    provideHttpClient(
      withInterceptors([AuthenticationInterceptor, TokenInterceptor]),
    ),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: definePreset(Aura, {
          semantic: {
            primary: {
              50: '#f2f5fc',
              100: '#f2f5fc',
              200: '#cbd9f2',
              300: '#a7c0e9',
              400: '#7da0dd',
              500: '#4c68c7',
              600: '#4055b5',
              700: '#394694',
              800: '#323e76',
              900: '#222849',
              950: '#222849'
            }
          }
        }),
        options: {
          darkModeSelector: false
        }
      }
    })
  ],
};
