<div
  *ngIf="channelCardData()"
  class="channel-card"
  [class]="{
    'status-ok': errorCount() === 0,
    'status-warn': errorCount() > 0,
    'status-error': mediaErrors().length > 0 || scheduleErrors().length > 0,
  }"
>
  <h3>
    {{ channelCardData()?.friendlyName }}
  </h3>
  <h3
    *ngIf="
      errorCount() === 0 &&
      mediaErrors().length === 0 &&
      scheduleErrors().length === 0
    "
    class="status"
  >
    OK
  </h3>
  <h5 *ngIf="errorCount() > 0">
    Contains <span>{{ errorCount() }}</span> unverified abnormality.
  </h5>
  <h5 *ngIf="mediaErrors().length > 0">
    Missing media for:
    <span *ngFor="let error of mediaErrors()" [innerHtml]="error"></span>
  </h5>
  <h5 *ngIf="scheduleErrors().length > 0">
    Schedule errors:
    <span>{{ scheduleErrors().length }}</span>
  </h5>
  <ng-content></ng-content>
</div>
<div *ngIf="!channelCardData()" class="channel-card">Loading</div>
