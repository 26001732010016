import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from '../main-layout/main-layout.component';
import { RouterOutlet } from '@angular/router';
import { NavigationHeaderComponent } from 'src/app/core/components/navigation/navigation-header/navigation-header.component';
import { RefreshingTokenComponent } from 'src/app/core/components/refreshing-token/refreshing-token.component';

@Component({
  selector: 'layout-no-sidebar-layout',
  imports: [
    CommonModule,
    RouterOutlet,
    NavigationHeaderComponent,
    RefreshingTokenComponent,
  ],
  templateUrl: './no-sidebar-layout.component.html',
  styleUrls: ['./no-sidebar-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoSidebarLayoutComponent
  extends MainLayoutComponent
  implements OnInit
{
  override ngOnInit(): void {
    super.ngOnInit();
  }
}
