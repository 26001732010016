import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NavigationSidebarContentComponent } from '../navigation-sidebar-content/navigation-sidebar-content.component';
import { CurrentTeamIdStore } from '../../../../../pages/team/team/team.service';

@Component({
  selector: 'core-team-overview-subnav',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    NavigationSidebarContentComponent,
  ],
  templateUrl: './team-overview-subnav.component.html',
  styleUrls: [
    './team-overview-subnav.component.scss',
    '../navigation-sidebar-content/navigation-sidebar-content.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamOverviewSubnavComponent extends NavigationSidebarContentComponent {
  currentTeamIdStore = inject(CurrentTeamIdStore);
  constructor(public router: Router) {
    super();
  }

  baseRoute = this.router.url.split('/', 3).join('/');
}
