import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'graphicBoolean',
  standalone: true
})
export class GraphicBooleanPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {
    if (value) {
      return "<span class='nav-icon mci-check color-primary'></span>";
    } else {
      return "<span class='nav-icon mci-close color-danger'></span>";
    }
  }
}
