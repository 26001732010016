import { AddressInterface, Address } from "../../../entity-secondary/entity-address/models/address";
import { BankAccountInterface, BankAccount } from "../../../entity-secondary/entity-bank-account/models/bank-account";
import { SocialMedia } from "../../../entity-secondary/entity-social-media/models/social-media";
import { Deserializable } from "../../../shared/interfaces/deserializable";
import { OptionCollection } from "../../../shared/interfaces/option-collection";
import { XperienceItemColumnModel, XperienceItemColumnType } from "../../../xperience/models/xperience-models";
import { TenantTypesPipe } from "../../../xperience/pipes/entity-list-value/tenant-types.pipe";
import { BusinessActivityInterface, BusinessActivity } from "./business-activity";
import { BusinessRegisterInterface, BusinessRegister } from "./business-register";
import { TenantProfileInterface, TenantProfile } from "./tenant-profile";
import { TenantTypeInterface, TenantType } from "./tenant-type";

export interface TenantInterface {
  id: string;
  name: string;
  parent: TenantInterface | null;
  addresses: AddressInterface[];
  bankAccounts: BankAccountInterface[];
  businessActivities: BusinessActivityInterface[];
  businessRegisters: BusinessRegisterInterface[];
  profiles: TenantProfileInterface[];
  requiresTwoFactorAuthentication: boolean;
  socialMedia: SocialMedia[];
  types: TenantTypeInterface[];
}

export type TenantKeys = keyof TenantInterface;

export const TenantAdminColumns: XperienceItemColumnModel<TenantKeys>[] = [
  {
    column: 'name',
    orderBy: true,
    searchBy: true,
    friendly: 'Name',
    columnClass: XperienceItemColumnType.TextColumn,
  },
  {
    column: 'types',
    orderBy: true,
    searchBy: true,
    friendly: 'Types',
    columnClass: XperienceItemColumnType.TextColumn,
    pipe: new TenantTypesPipe()
  },
];

export class Tenant
  implements Deserializable, OptionCollection {
  constructor(
    private _id: string = '',
    private _name: string = '',
    private _parent: TenantInterface | null = null,
    private _addresses: Address[] = [],
    private _bankAccounts: BankAccount[] = [],
    private _businessActivities: BusinessActivity[] = [],
    private _businessRegisters: BusinessRegister[] = [],
    private _requiresTwoFactorAuthentication: boolean = false,
    private _profiles: TenantProfile[] = [],
    private _types: TenantType[] = [],
    private _socialMedia: SocialMedia[] = []
  ) {
  }

  get optionName() {
    return this.name;
  }
  get optionValue() {
    return this.id;
  }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }
  get parent() {
    return this._parent;
  }
  set parent(parent) {
    this._parent = parent;
  }
  get addresses() {
    return this._addresses;
  }
  set addresses(addresses) {
    this._addresses = addresses;
  }
  get bankAccounts() {
    return this._bankAccounts;
  }
  set bankAccounts(bankAccounts) {
    this._bankAccounts = bankAccounts;
  }
  get businessActivities() {
    return this._businessActivities;
  }
  set businessActivities(businessActivities) {
    this._businessActivities = businessActivities;
  }
  get businessRegisters() {
    return this._businessRegisters;
  }
  set businessRegisters(businessRegisters) {
    this._businessRegisters = businessRegisters;
  }
  get profiles(): TenantProfile[] {
    return this._profiles;
  }
  set profiles(profiles: TenantProfile[]) {
    this._profiles = profiles;
  }
  get requiresTwoFactorAuthentication() {
    return this._requiresTwoFactorAuthentication;
  }
  set requiresTwoFactorAuthentication(requiresTwoFactorAuthentication) {
    this._requiresTwoFactorAuthentication = requiresTwoFactorAuthentication;
  }
  get types(): TenantType[] {
    return this._types;
  }
  set types(types: TenantType[]) {
    this._types = types;
  }
  get socialMedia(): SocialMedia[] {
    return this._socialMedia;
  }
  set socialMedia(socialMedia: SocialMedia[]) {
    this._socialMedia = socialMedia;
  }

  deserialize(input: TenantInterface): this {
    Object.assign(this, input);
    if (input.profiles)
      this.profiles = input.profiles.map((obj) =>
        new TenantProfile().deserialize(<TenantProfileInterface>obj)
      );
    if (input.types)
      this.types = input.types.map((obj) =>
        new TenantType().deserialize(<TenantTypeInterface>obj)
      );
    return this;
  }
}

