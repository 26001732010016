import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAccountStoreService } from '../../account/stores/user-account-store.service';
import { Router } from '@angular/router';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ServerClockComponent } from '../ad-insertion-monitor/components/server-clock/server-clock.component';

@Component({
  selector: 'app-quatro-monitor',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, ServerClockComponent],
  templateUrl: './quatro-monitor.component.html',
  styleUrls: ['./quatro-monitor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuatroMonitorComponent implements OnInit {
  private userAccountStore = inject(UserAccountStoreService);
  private router = inject(Router);

  ngOnInit(): void {
    this.userAccountStore.userAccountLoggedIn$.subscribe((res) => {
      if (res === false) {
        this.router.navigate(['sign-in']);
      }
    });
  }
}
