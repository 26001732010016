import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, style, animate, transition } from '@angular/animations';
import { AppThemesService } from '../../../core/services/app-themes.service';
import { Observable, combineLatest, map } from 'rxjs';
import { Theme } from '../../../core/models/theme';

interface NavigationHeaderData {
  theme: Theme;
}

@Component({
  selector: 'shared-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ModalComponent {
  private applicationThemesService = inject(AppThemesService);
  navigationHeaderData$!: Observable<NavigationHeaderData>;

  @Input() template!: string;
  @Input() customWidth!: string;
  @Input() withCloseButton = true;
  @Output() closeModal = new EventEmitter<boolean>();

  closing: boolean = false;
  ngOnInit(): void {
    this.navigationHeaderData$ = combineLatest([
      this.applicationThemesService.theme$,
    ]).pipe(
      map(([theme]) => {
        return {
          theme,
        };
      }),
    );
  }

  closeModalOnClick(event: Event) {
    this.closing = true;
    setTimeout(() => {
      event.preventDefault();
      this.closeModal.emit(true);
    }, 200);
  }

  closeModalOnEscape(event: Event) {
    if (event instanceof KeyboardEvent) {
      if (event.key === 'Escape') {
        this.closing = true;
        setTimeout(() => {
          this.closeModal.emit(true);
        }, 200);
      }
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      // Your row selection code
      this.closing = true;
      this.closeModal.emit(true);
    }
  }
}
