import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { Language } from '../models/language';
import { Timezone } from '../models/timezone';
import { Country } from '../models/country';
import { Pronouns } from '../models/pronouns';
import { Gender } from '../models/gender';
import { SocialMediaType } from '../../entity-secondary/entity-social-media/models/social-media-type';
import { DynamicFilter } from '../models/dynamic-filter';

@Injectable({
  providedIn: 'root',
})
export class GeneralStoreService {
  // Store for languages
  private languagesStore = new BehaviorSubject<Language[]>([]);
  languages$: Observable<Language[]> = this.languagesStore.asObservable();

  // Store for current language
  private currentLanguageStore = new BehaviorSubject<Language | null>(null);
  currentLanguage$: Observable<Language | null> = this.currentLanguageStore.asObservable();

  // Store for application locale
  private currentLocaleStore = new BehaviorSubject<string>('en-EN');
  currentLocale$: Observable<string> = this.currentLocaleStore.asObservable();

  // Store for timezones
  private timezonesStore = new BehaviorSubject<Timezone[]>([]);
  timezones$: Observable<Timezone[]> = this.timezonesStore.asObservable();

  // Store for countries
  private countriesStore = new BehaviorSubject<Country[]>([]);
  countries$: Observable<Country[]> = this.countriesStore.asObservable();

  // Store for pronouns
  private pronounsStore = new BehaviorSubject<Pronouns[]>([]);
  pronouns$: Observable<Pronouns[]> = this.pronounsStore.asObservable();

  // Store for genders
  private gendersStore = new BehaviorSubject<Gender[]>([]);
  genders$: Observable<Gender[]> = this.gendersStore.asObservable();

  // Store for filters
  private filtersStore = new BehaviorSubject<DynamicFilter[]>([]);
  filters$: Observable<DynamicFilter[]> = this.filtersStore.asObservable();

  // Store for socialmedia types
  private socialmediaTypesStore = new BehaviorSubject<SocialMediaType[]>([]);
  socialmediaTypes$: Observable<SocialMediaType[]> = this.socialmediaTypesStore.asObservable();

  constructor() { }

  setLanguages(languages: Language[]): void {
    this.languagesStore.next(languages);
  }
  setCurrentLanguage(language: Language): void {
    this.currentLanguageStore.next(language);
  }
  setCurrentLanguageById(languageId: string): void {
    this.languages$.pipe(
      take(1)
    ).subscribe(
      (languages) => {
        const newLanguage = languages.find(l => l.id === languageId);
        if (newLanguage) {
          this.setCurrentLanguage(newLanguage);
        }
      }
    )
  }
  setCurrentLocale(locale: string): void {
    this.currentLocaleStore.next(locale);
  }
  setTimezones(timezones: Timezone[]): void {
    this.timezonesStore.next(timezones);
  }
  setCountries(countries: Country[]): void {
    this.countriesStore.next(countries);
  }
  setPronouns(pronouns: Pronouns[]): void {
    this.pronounsStore.next(pronouns);
  }
  setGenders(genders: Gender[]): void {
    this.gendersStore.next(genders);
  }
  setFilters(filters: DynamicFilter[]): void {
    this.filtersStore.next(filters);
  }
  setSocialmediaTypes(socialmediaTypes: SocialMediaType[]): void {
    this.socialmediaTypesStore.next(socialmediaTypes);
  }
}
