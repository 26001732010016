import { Deserializable } from '../../shared/interfaces/deserializable';

export interface DynamicFilterInterface {
  name: string;
  endPointPath: string;
  filterPropertyName: string;
  isArrayFilter: boolean;
  orderByEndPointPath: string;
}

export class DynamicFilter implements Deserializable {
  constructor(
    private _name: string = '',
    private _endPointPath: string = '',
    private _filterPropertyName: string = '',
    private _isArrayFilter: boolean = false,
    private _orderByEndPointPath: string = ''
  ) {}

  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }
  get endPointPath() {
    return this._endPointPath;
  }
  set endPointPath(endPointPath) {
    this._endPointPath = endPointPath;
  }
  get filterPropertyName() {
    return this._filterPropertyName;
  }
  set filterPropertyName(filterPropertyName) {
    this._filterPropertyName = filterPropertyName;
  }
  get isArrayFilter() {
    return this._isArrayFilter;
  }
  set isArrayFilter(isArrayFilter) {
    this._isArrayFilter = isArrayFilter;
  }
  get orderByEndPointPath() {
    return this._orderByEndPointPath;
  }
  set orderByEndPointPath(orderByEndPointPath) {
    this._orderByEndPointPath = orderByEndPointPath;
  }

  deserialize(input: DynamicFilterInterface): this {
    Object.assign(this, input);
    return this;
  }
}
