import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { FormControlComponent } from '../form-control.component';
import { GetAsFormControlPipe } from '../../../pipes/get-as-form-control.pipe';

@Component({
  selector: 'shared-form-input-file',
  imports: [CommonModule, ReactiveFormsModule, GetAsFormControlPipe],
  templateUrl: './form-input-file.component.html',
  styleUrls: ['./form-input-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputFileComponent
  extends FormControlComponent
  implements OnInit
{
  protected uploads!: FileList;

  @Input() maxNumberOfFiles = 1;
  @Output() uploadsChange = new EventEmitter<FileList>();
  @Output() removeFileEmitter = new EventEmitter<Event>();

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.control.addValidators([
      RxwebValidators.file({ minFiles: 1, maxFiles: this.maxNumberOfFiles }),
      RxwebValidators.extension({ extensions: ['png', 'jpg'] }),
    ]);
  }

  onUploadsInputChange(e: Event): void {
    const i = e.target as HTMLInputElement;
    if (i.files && i.files.length > 0) {
      this.uploads = i.files;
    }
    this.uploadsChange.emit(this.uploads);
  }

  removeFile(e: Event): void {
    this.removeFileEmitter.emit(e);
  }

  override getErrorMessage(): string {
    if (this.control.hasError('file')) {
      return 'You have exceeded the upload limit on the number of files.';
    }
    if (this.control.hasError('extension')) {
      return 'The file type you are trying to upload is not supported.';
    }
    return super.getErrorMessage();
  }
}
