import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  WritableSignal,
  signal,
} from '@angular/core';

@Component({
  selector: 'app-server-clock',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './server-clock.component.html',
  styleUrls: ['./server-clock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerClockComponent implements OnInit {
  currentTime: WritableSignal<string> = signal(
    new Date().toLocaleTimeString('nl-NL'),
  );

  ngOnInit(): void {
    setInterval(() => {
      this.currentTime.set(new Date().toLocaleTimeString('nl-NL'));
    }, 50);
  }
}
