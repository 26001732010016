import { OptionCollection } from '../../shared/interfaces/option-collection';
import { Deserializable } from '../../shared/interfaces/deserializable';
import {
  XperienceItemColumnModel,
  XperienceItemColumnType,
} from '../../xperience/models/xperience-models';
import { GraphicBooleanPipe } from '../../xperience/pipes/entity-list-value/graphic-boolean.pipe';

export interface LanguageInterface {
  id: string;
  ietfCode: string;
  isO639_1_Code: string;
  isO639_2_Code: string;
  name: string;
  enabled: boolean;
  isDefault: boolean;
  languageId: string;
}

export type LanguageKeys = keyof LanguageInterface;

export const LanguagesAdminColumns: XperienceItemColumnModel<LanguageKeys>[] = [
  {
    column: 'name',
    orderBy: true,
    searchBy: true,
    friendly: 'Name',
    columnClass: XperienceItemColumnType.TextColumn,
  },
  {
    column: 'enabled',
    orderBy: true,
    searchBy: false,
    friendly: 'Enabled',
    columnClass: XperienceItemColumnType.NumberColumn,
    pipe: new GraphicBooleanPipe(),
  },
  {
    column: 'isDefault',
    orderBy: true,
    searchBy: false,
    friendly: 'Is Default',
    columnClass: XperienceItemColumnType.NumberColumn,
    pipe: new GraphicBooleanPipe(),
  },
];

export class Language implements Deserializable, OptionCollection {
  constructor(
    private _id: string = '',
    private _ietfCode: string = '',
    private _isO639_1_Code: number = 0,
    private _isO639_2_Code: string = '',
    private _name: string = '',
    private _enabled: boolean = false,
    private _isDefault: boolean = false,
    private _languageId: string = '',
  ) {}

  get optionName(): string {
    return this.name;
  }
  get optionValue(): string {
    return this.id;
  }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get ietfCode() {
    return this._ietfCode;
  }
  set ietfCode(ietfCode) {
    this._ietfCode = ietfCode;
  }
  get isO639_1_Code() {
    return this._isO639_1_Code;
  }
  set isO639_1_Code(isO639_1_Code) {
    this._isO639_1_Code = isO639_1_Code;
  }
  get isO639_2_Code() {
    return this._isO639_2_Code;
  }
  set isO639_2_Code(isO639_2_Code) {
    this._isO639_2_Code = isO639_2_Code;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(enabled) {
    this._enabled = enabled;
  }
  get isDefault() {
    return this._isDefault;
  }
  set isDefault(isDefault) {
    this._isDefault = isDefault;
  }
  get default() {
    return this._isDefault;
  }
  set default(isDefault) {
    this._isDefault = isDefault;
  }
  get languageId() {
    return this._languageId;
  }
  set languageId(languageId) {
    this._languageId = languageId;
  }

  deserialize(input: LanguageInterface): this {
    Object.assign(this, input);
    return this;
  }
}
