import { Deserializable } from '../../shared/interfaces/deserializable';
import { OptionCollection } from '../../shared/interfaces/option-collection';
import {
  XperienceItemColumnModel,
  XperienceItemColumnType,
} from '../../xperience/models/xperience-models';
import { GraphicBooleanPipe } from '../../xperience/pipes/entity-list-value/graphic-boolean.pipe';

export interface TimezoneInterface {
  id: string;
  enabled: boolean;
  name: string;
  isDefault: boolean;
}

export type TimezoneKeys = keyof TimezoneInterface;

export const TimezonesAdminColumns: XperienceItemColumnModel<TimezoneKeys>[] = [
  {
    column: 'name',
    orderBy: true,
    searchBy: true,
    friendly: 'Name',
    columnClass: XperienceItemColumnType.TextColumn,
  },
  {
    column: 'enabled',
    orderBy: true,
    searchBy: false,
    friendly: 'Enabled',
    columnClass: XperienceItemColumnType.BoolColumn,
    pipe: new GraphicBooleanPipe(),
  },
  {
    column: 'isDefault',
    orderBy: true,
    searchBy: false,
    friendly: 'isDefault',
    columnClass: XperienceItemColumnType.BoolColumn,
    pipe: new GraphicBooleanPipe(),
  },
];

export class Timezone implements Deserializable, OptionCollection {
  constructor(
    private _id: string = '',
    private _name: string = '',
    private _enabled: boolean = false,
    private _isDefault: boolean = false
  ) {}

  get optionName(): string {
    return this.name;
  }
  get optionValue(): string {
    return this.id;
  }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }
  get enabled() {
    return this._enabled;
  }
  set enabled(enabled) {
    this._enabled = enabled;
  }
  get isDefault() {
    return this._isDefault;
  }
  set isDefault(isDefault) {
    this._isDefault = isDefault;
  }

  deserialize(input: TimezoneInterface): this {
    Object.assign(this, input);
    return this;
  }
}
