import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NavigationSidebarContentComponent } from '../navigation-sidebar-content/navigation-sidebar-content.component';

@Component({
  selector: 'core-xperience-modules-subnav',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    NavigationSidebarContentComponent
  ],
  templateUrl: './xperience-modules-subnav.component.html',
  styleUrls: ['./xperience-modules-subnav.component.scss', '../navigation-sidebar-content/navigation-sidebar-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XperienceModulesSubnavComponent extends NavigationSidebarContentComponent {

  constructor() {
    super();
  }

}
