import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { UserAccountStoreService } from '../../../../../account/stores/user-account-store.service';
import {
  AppMenusInterface,
  AppMenusService,
  MasterMenus,
} from '../../../../services/app-menus.service';
import {
  tenantUrlRegex,
  teamUrlRegex,
  AppRoutingService,
} from '../../../../services/app-routing.service';
import { AppThemesService } from '../../../../services/app-themes.service';
import { AppService } from '../../../../services/app.service';
import { DestroyService } from '../../../../../shared/services/destroy.service';
import { CurrentTenantService } from '../../../../services/current-tenant.service';
import { Tenant } from '../../../../../entity/tenant/models/tenant';
import { Theme } from '../../../../models/theme';

interface NavigationSecondarySubnavData {
  isAdmin: boolean;
  userAccountLoggedIn: boolean;
  theme: Theme;
  currentRoute: RouterStateSnapshot | null;
  currentRouteFiltered: RouterStateSnapshot | null;
  appMenusState: AppMenusInterface;
  myTenants: Tenant[];
  currentTenant: Tenant | null;
  userAccountTenantMenuState: boolean;
}

@Component({
    selector: 'core-navigation-sidebar-content',
    imports: [CommonModule],
    templateUrl: './navigation-sidebar-content.component.html',
    styleUrls: ['./navigation-sidebar-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarContentComponent implements OnInit {
  @Input() snapshot!: RouterStateSnapshot | null;

  appMenus = MasterMenus;

  tenantUrlRegex = tenantUrlRegex;
  teamUrlRegex = teamUrlRegex;

  navigationSecondarySubnavData$!: Observable<NavigationSecondarySubnavData>;

  protected destroyed = inject(DestroyService);
  protected appService = inject(AppService);
  protected userAccountStore = inject(UserAccountStoreService);
  protected currentTenantService = inject(CurrentTenantService);
  public appRoutingService = inject(AppRoutingService);
  protected applicationThemesService = inject(AppThemesService);
  protected appMenusService = inject(AppMenusService);

  ngOnInit(): void {
    this.navigationSecondarySubnavData$ = combineLatest([
      this.userAccountStore.isAdmin(),
      this.userAccountStore.userAccountLoggedIn$,
      this.applicationThemesService.theme$,
      this.appRoutingService.currentRoute$,
      this.appRoutingService.currentRouteFiltered$,
      this.appMenusService.appMenusState$,
      this.userAccountStore.userAccountMyTenants$,
      this.currentTenantService.currentTenant$,
      this.appMenusService.appMenusState$.pipe(
        map((menusState) => menusState[MasterMenus.UserAccountTenantsDropdown]),
      ),
    ]).pipe(
      map(
        ([
          isAdmin,
          userAccountLoggedIn,
          theme,
          currentRoute,
          currentRouteFiltered,
          appMenusState,
          myTenants,
          currentTenant,
          userAccountTenantMenuState,
        ]) => {
          return {
            isAdmin,
            userAccountLoggedIn,
            theme,
            currentRoute,
            currentRouteFiltered,
            appMenusState,
            myTenants,
            currentTenant,
            userAccountTenantMenuState,
          };
        },
      ),
    );
  }

  toggle(appMenu: string): void {
    this.appMenusService.setMenuCollapsedState(false);
    this.appMenusService.toggle(appMenu);
  }

  updateCurrentTenant(tenant: Tenant, currentTenant: Tenant | null) {
    if (tenant?.id === currentTenant?.id) {
      this.currentTenantService.clearCurrentTenant();
    } else {
      this.currentTenantService.setCurrentTenant(tenant);
    }
  }
}
