import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  VERSION,
  inject,
} from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { UserAccountStoreService } from '../../../../account/stores/user-account-store.service';
import { Person } from '../../../../entity/person/models/person';
import { DestroyService } from '../../../../shared/services/destroy.service';
import { AppMenusService } from '../../../services/app-menus.service';
import { AppRoutingService } from '../../../services/app-routing.service';
import {
  AppThemesService,
} from '../../../services/app-themes.service';
import { Theme } from '../../../models/theme';

interface FooterData {
  theme: Theme;
  userAccountLoggedIn: boolean;
  authenticatedPerson: Person | null;
  appMenuState: boolean;
}

@Component({
  selector: 'core-navigation-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './navigation-footer.component.html',
  styleUrls: ['./navigation-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationFooterComponent implements OnInit {
  footerData$!: Observable<FooterData>;

  angularVersion = VERSION.full;

  public appMenusService = inject(AppMenusService);
  public appRoutingService = inject(AppRoutingService);
  public appThemesService = inject(AppThemesService);

  private destroyed = inject(DestroyService);

  private userAccountStore = inject(UserAccountStoreService);

  env = environment;

  constructor() { }

  ngOnInit(): void {
    this.footerData$ = combineLatest([
      this.appThemesService.theme$,
      this.userAccountStore.userAccountLoggedIn$,
      this.userAccountStore.authenticatedPersonStore.entity$,
      this.appMenusService.appMenuState$,
    ]).pipe(
      map(([theme, userAccountLoggedIn, personEntity, appMenuState]) => {
        const authenticatedPerson = personEntity as Person;

        return {
          theme,
          userAccountLoggedIn,
          authenticatedPerson,
          appMenuState,
        };
      })
    );
  }
}
