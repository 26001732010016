import {
  ChangeDetectionStrategy,
  Component,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NavigationSidebarContentComponent } from '../navigation-sidebar-content/navigation-sidebar-content.component';
import { environment } from '../../../../../../environments/environment';

@Component({
    selector: 'core-home-navigation',
    imports: [
        CommonModule,
        RouterLink,
        RouterLinkActive,
        NavigationSidebarContentComponent,
    ],
    templateUrl: './home-navigation.component.html',
    styleUrls: [
        './home-navigation.component.scss',
        '../navigation-sidebar-content/navigation-sidebar-content.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeNavigationComponent extends NavigationSidebarContentComponent {
  isCollapsed: WritableSignal<boolean> = signal(false);
  env = environment;

  constructor() {
    super();
    this.checkCollapsed();
  }

  checkCollapsed() {
    this.appMenusService.appMenuCollapsedState$.subscribe((collapsed) => {
      this.isCollapsed.set(collapsed);
    });
  }
}
