<ng-container
  *ngIf="
    navigationSecondarySubnavData$ | async as navigationSecondarySubnavData
  "
>
  <core-navigation-sidebar-content [snapshot]="snapshot">
    <nav [ngClass]="navigationSecondarySubnavData.theme.class">
      <ul>
        <li
          *ngIf="navigationSecondarySubnavData.userAccountLoggedIn"
          class="breadcrumb"
        >
          <button
            type="button"
            (click)="toggle(appMenus.UserAccountTenantsDropdown)"
          >
            <span *ngIf="!navigationSecondarySubnavData.currentTenant"
              >Select tenant:</span
            >
            <span
              *ngIf="
                navigationSecondarySubnavData.currentTenant as currentTenant
              "
              >Tenant: {{ currentTenant.name }}</span
            >
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.UserAccountTenantsDropdown
                ]
              "
              class="mci-close-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.UserAccountTenantsDropdown
                ]
              "
              class="mci-arrowhead-right"
            ></span>
          </button>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button type="button" (click)="toggle(appMenus.HomeDashboardMenu)">
            <span class="nav-icon mci-chart-area"></span>
            <span class="feature-label">Dashboard</span>
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.HomeDashboardMenu
                ]
              "
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.HomeDashboardMenu
                ]
              "
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul
            *ngIf="
              navigationSecondarySubnavData.appMenusState[
                appMenus.HomeDashboardMenu
              ]
            "
          >
            <li>
              <a [routerLink]="['/dashboard']" [routerLinkActive]="'is-active'"
                >Main dashboard</a
              >
            </li>
          </ul>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button type="button" (click)="toggle(appMenus.HomeMonitorMenu)">
            <span class="nav-icon mci-apps-small-solid"></span>
            <span class="feature-label">Monitor Tools</span>
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.HomeMonitorMenu
                ]
              "
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.HomeMonitorMenu
                ]
              "
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul
            *ngIf="
              navigationSecondarySubnavData.appMenusState[
                appMenus.HomeMonitorMenu
              ]
            "
          >
            <li>
              <a
                [routerLink]="['/monitor/admonitor']"
                [routerLinkActive]="'is-active'"
                >Ad Monitor</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/monitor/quatromonitor']"
                [routerLinkActive]="'is-active'"
                >Quatro Monitor</a
              >
            </li>
          </ul>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button type="button" (click)="toggle(appMenus.HomeMyGroupsMenu)">
            <span class="nav-icon mci-user-group-solid"></span>
            <span class="feature-label">My Groups</span>
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.HomeMyGroupsMenu
                ]
              "
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.HomeMyGroupsMenu
                ]
              "
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul
            *ngIf="
              navigationSecondarySubnavData.appMenusState[
                appMenus.HomeMyGroupsMenu
              ]
            "
          >
            <li>
              <a [routerLink]="['/my-groups']" [routerLinkActive]="'is-active'"
                >My Groups</a
              >
            </li>
          </ul>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button type="button" (click)="toggle(appMenus.HomeAppsWebsitesMenu)">
            <span class="nav-icon mci-domain-solid"></span>
            <span class="feature-label">Apps&nbsp;&amp;&nbsp;Websites</span>
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.HomeAppsWebsitesMenu
                ]
              "
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.HomeAppsWebsitesMenu
                ]
              "
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul
            *ngIf="
              navigationSecondarySubnavData.appMenusState[
                appMenus.HomeAppsWebsitesMenu
              ]
            "
          >
            <li>
              <a [routerLink]="['/xperience']" [routerLinkActive]="'is-active'">
                OTT Xperience
              </a>
            </li>
          </ul>
        </li>
        <!-- <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button
            type="button"
            (click)="toggle(appMenus.HomePlayoutMenu)">
            <span class="nav-icon mci-broadcast-tower-solid"></span>
            <span class="feature-label">Playout</span>
            <span
              *ngIf="navigationSecondarySubnavData.appMenusState[appMenus.HomePlayoutMenu]"
              class="mci-arrowhead-up-solid"></span>
            <span
              *ngIf="!navigationSecondarySubnavData.appMenusState[appMenus.HomePlayoutMenu]"
              class="mci-arrowhead-down-solid"></span>
          </button>
          <ul *ngIf="navigationSecondarySubnavData.appMenusState[appMenus.HomePlayoutMenu]">
            <li>
              <a
                [routerLink]="['/playout']"
                [routerLinkActive]="'is-active'"
                >Playout 001</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/playout']"
                [routerLinkActive]="'is-active'"
                >Playout 002</a
              >
            </li>
          </ul>
        </li> -->
        <!-- <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button
            type="button"
            (click)="toggle(appMenus.HomeContentMenu)">
            <span class="nav-icon mci-save-solid"></span>
            <span class="feature-label">Content</span>
            <span
              *ngIf="navigationSecondarySubnavData.appMenusState[appMenus.HomeContentMenu]"
              class="mci-arrowhead-up-solid"></span>
            <span
              *ngIf="!navigationSecondarySubnavData.appMenusState[appMenus.HomeContentMenu]"
              class="mci-arrowhead-down-solid"></span>
          </button>
          <ul *ngIf="navigationSecondarySubnavData.appMenusState[appMenus.HomeContentMenu]">
            <li>
              <a
                [routerLink]="['/playout']"
                [routerLinkActive]="'is-active'"
                >Content 001</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/playout']"
                [routerLinkActive]="'is-active'"
                >Content 002</a
              >
            </li>
          </ul>
        </li> -->
        <!-- <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button
            type="button"
            (click)="toggle(appMenus.HomeGridsMenu)">
            <span class="nav-icon mci-table"></span>
            <span class="feature-label">Grids</span>
            <span
              *ngIf="navigationSecondarySubnavData.appMenusState[appMenus.HomeGridsMenu]"
              class="mci-arrowhead-up-solid"></span>
            <span
              *ngIf="!navigationSecondarySubnavData.appMenusState[appMenus.HomeGridsMenu]"
              class="mci-arrowhead-down-solid"></span>
          </button>
          <ul *ngIf="navigationSecondarySubnavData.appMenusState[appMenus.HomeGridsMenu]">
            <li>
              <a
                [routerLink]="['/playout']"
                [routerLinkActive]="'is-active'"
                >Grids 001</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/playout']"
                [routerLinkActive]="'is-active'"
                >Grids 002</a
              >
            </li>
          </ul>
        </li> -->
        <!-- <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button
            type="button"
            (click)="toggle(appMenus.HomeGraphicsTemplatesMenu)">
            <span class="nav-icon mci-photo-solid"></span>
            <span class="feature-label">Graphics&nbsp;Templates</span>
            <span
              *ngIf="navigationSecondarySubnavData.appMenusState[appMenus.HomeGraphicsTemplatesMenu]"
              class="mci-arrowhead-up-solid"></span>
            <span
              *ngIf="!navigationSecondarySubnavData.appMenusState[appMenus.HomeGraphicsTemplatesMenu]"
              class="mci-arrowhead-down-solid"></span>
          </button>
          <ul *ngIf="navigationSecondarySubnavData.appMenusState[appMenus.HomeGraphicsTemplatesMenu]">
            <li>
              <a
                [routerLink]="['/playout']"
                [routerLinkActive]="'is-active'"
                >Graphics Templates 001</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/playout']"
                [routerLinkActive]="'is-active'"
                >Graphics Templates 002</a
              >
            </li>
          </ul>
        </li> -->
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button type="button" (click)="toggle(appMenus.HomeHelpMenu)">
            <span class="nav-icon mci-help-circle-solid"></span>
            <span class="feature-label">Help</span>
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.HomeHelpMenu
                ]
              "
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.HomeHelpMenu
                ]
              "
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul
            *ngIf="
              navigationSecondarySubnavData.appMenusState[appMenus.HomeHelpMenu]
            "
          >
            <li>
              <a [routerLink]="['/help/faqs']" [routerLinkActive]="'is-active'"
                >FAQs</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/help/contact']"
                [routerLinkActive]="'is-active'"
                >Contact</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/help/terms-conditions']"
                [routerLinkActive]="'is-active'"
                >Terms &amp; conditions</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/help/privacy-policy']"
                [routerLinkActive]="'is-active'"
                >Privacy policy</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/help/cookie-policy']"
                [routerLinkActive]="'is-active'"
                >Cookie policy</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/help/release-notes']"
                [routerLinkActive]="'is-active'"
                >Release notes</a
              >
            </li>
          </ul>
        </li>
        <!-- <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <a [routerLink]="['/tenant/overview']" [ngClass]="{
              'is-active': navigationSecondarySubnavData.currentRoute && navigationSecondarySubnavData.currentRoute.url.search(tenantUrlRegex) > -1
            }">
            Tenants
          </a>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <a
            [routerLink]="['/team/overview']"
            [ngClass]="{
              'is-active': navigationSecondarySubnavData.currentRoute && navigationSecondarySubnavData.currentRoute.url.search(teamUrlRegex) > -1
            }">
            Teams
          </a>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <a
            [routerLink]="['/contentchannel/overview']"
            [routerLinkActive]="'is-active'">
            Content Channels
          </a>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <a
            [routerLink]="['/project/overview']"
            [routerLinkActive]="'is-active'">
            Projects
          </a>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <a
            [routerLink]="['/member/overview']"
            [routerLinkActive]="'is-active'">
            Users
          </a>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <a [routerLink]="['/person/overview']" [routerLinkActive]="'is-active'"> Persons </a>
        </li> -->
      </ul>
    </nav>
  </core-navigation-sidebar-content>
</ng-container>
