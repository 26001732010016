import { Injectable, inject } from '@angular/core';
import * as Immutable from 'immutable';
import { BehaviorSubject, Observable, takeUntil, take } from 'rxjs';
import { DestroyService } from '../../shared/services/destroy.service';
import { AppService, ApplicationEvent } from './app.service';

export enum MasterMenus {
  HomeDashboardMenu = 'homeDashboardMenu',
  HomeMonitorMenu = 'HomeMonitorMenu',
  HomeMyGroupsMenu = 'homeMyGroupsMenu',
  HomeAppsWebsitesMenu = 'homeAppsWebsitesMenu',
  HomePlayoutMenu = 'homePlayoutMenu',
  HomeContentMenu = 'homeContentMenu',
  HomeGridsMenu = 'homeGridsMenu',
  HomeGraphicsTemplatesMenu = 'homeGraphicsTemplatesMenu',
  HomeHelpMenu = 'homeHelpMenu',
  HomeMamMenu = 'homeMamMenu',
  XpGeneralMenu = 'xpGeneralMenu',
  XpPaymentSubscriptionsMenu = 'xpPaymentSubscriptionsMenu',
  XpContentPromotionMenu = 'xpContentPromotionMenu',
  UserAccountMyAccountMenu = 'userAccountMyAccountMenu',
  UserAccountSettingsMenu = 'userAccountSettingsMenu',
  MyGroupsGeneralMenu = 'myGroupsGeneralMenu',
  TenantsGeneralMenu = 'tenantsGeneralMenu',
  TeamsGeneralMenu = 'teamsGeneralMenu',
  ContentChannelGeneralMenu = 'contentChannelGeneralMenu',
  UserAccountDropdown = 'userAccountDropdown',
  UserAccountTenantsDropdown = 'userAccountTenantsDropdown',
}

export type AppMenusInterface = {
  [key: string]: boolean;
};

function getAppMenusInterface(menus: Record<string, string>, setting: boolean): AppMenusInterface {
  const result: AppMenusInterface = {};
  Object.entries(menus).map((e) => {
    result[e[1]] = setting;
  });
  return result;
}

@Injectable({
  providedIn: 'root',
})
export class AppMenusService {
  private destroyed = inject(DestroyService);
  private appService = inject(AppService);

  private menus: Record<string, string> = {};

  private appMenuStateStore = new BehaviorSubject<boolean>(true);
  appMenuState$: Observable<boolean> = this.appMenuStateStore.asObservable();

  private appMenuCollapsedStateStore = new BehaviorSubject<boolean>(true);
  appMenuCollapsedState$: Observable<boolean> = this.appMenuCollapsedStateStore.asObservable();

  private appMenusStateStore = new BehaviorSubject<AppMenusInterface>(
    getAppMenusInterface(MasterMenus, false),
  );
  appMenusState$: Observable<AppMenusInterface> = this.appMenusStateStore.asObservable();

  constructor() {
    this.appService.applicationEvent$.pipe(takeUntil(this.destroyed)).subscribe((setting) => {
      if (setting === ApplicationEvent.Logout) {
        this.closeAll();
      }
      if (setting === ApplicationEvent.Login) {
        this.closeAll();
      }
    });

    this.setMenuCollapsedState(localStorage.getItem('menuState') === 'true');
  }

  setMenuState(setting: boolean) {
    this.appMenuStateStore.next(setting);
  }

  setMenuCollapsedState(setting: boolean) {
    this.appMenuCollapsedStateStore.next(setting);
    localStorage.setItem('menuState', setting.toString());
  }

  closeAll(): void {
    const newSetting = getAppMenusInterface(this.menus, false);
    this.appMenusStateStore.next(newSetting);
  }

  toggle(menu: string): void {
    this.appMenusStateStore.pipe(take(1)).subscribe((state) => {
      const current = Immutable.fromJS(state);
      const updated = current.setIn([menu], !state[menu as keyof AppMenusInterface]);
      const final = updated.toJS() as AppMenusInterface;
      this.appMenusStateStore.next(final);
    });
  }

  set(menu: string, setting: boolean): void {
    this.appMenusStateStore.pipe(take(1)).subscribe((state) => {
      const current = Immutable.fromJS(state);
      const updated = current.setIn([menu], setting);
      const final = updated.toJS() as AppMenusInterface;
      this.appMenusStateStore.next(final);
    });
  }
}
