import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  combineLatest,
  tap,
  takeUntil,
  Observable,
  map,
  catchError,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PrimeableService } from '../../../core/services/primeable.service';
import { Q90Response } from '../../../shared/models/q90-response';
import { Q90ResponseData } from '../../../shared/interfaces/q90-response';
import { Q90EntitySearchResult } from '../../interfaces/entity-search';
import { UserAccount } from '../../user/models/user-account';
import { PersonInterface, Person } from '../models/person';

@Injectable({
  providedIn: 'root',
})
export class PersonService extends PrimeableService {
  constructor() {
    super();
    // Load corresponding Person store with Person object when userAccount object store emits
    // because the authenticated Person object is dependant on authenticated UserAccount. WIll
    // keep this as primeable service, though it's "priming" is dependant on value in userAccount
    // store which is primed etc via UserAccountService.
    // this.userAccountStore.authenticatedUserAccountStore.entity$
    //   .pipe(
    //     filter((userAccount) => userAccount instanceof UserAccount),
    //     takeUntil(this.destroyed)
    //   )
    //   .subscribe((userAccount) => {
    //     this.loadAuthenticatedPerson();
    //   });

    combineLatest([
      this.userAccountStore.userAccountLoggedIn$,
      this.userAccountStore.authenticatedUserAccountStore.entity$,
    ])
      .pipe(
        tap(([userAccountLoggedIn, authenticatedUser]) => {
          if (authenticatedUser instanceof UserAccount && userAccountLoggedIn) {
            this.loadAuthenticatedPerson();
          }
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  protected override prime(): void { }
  protected override authenticated(): void { }
  protected override refresh(): void { }
  protected override logout(): void {
    this.userAccountStore.authenticatedPersonStore.setStore(null);
  }

  loadAuthenticatedPerson(): void {
    this.fetchPerson().subscribe({
      next: (response: Q90ResponseData<PersonInterface>) => {
        if (response.statusCode == 200) {
          const person = new Person().deserialize(response.data);
          this.userAccountStore.authenticatedPersonStore.setStore(person);
        } else {
          this.errorService.setError(
            new Q90Response().deserialize(response)
          );
        }
      },
      error: (error) => {
        this.errorService.setError(error);
      },
    });
  }

  private fetchPerson(): Observable<Q90ResponseData<PersonInterface>> {
    return this.http
      .get<Q90ResponseData<PersonInterface>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/person`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  add(person: Partial<PersonInterface>) {
    return this.http
      .post<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/person/add`,
        {
          name: person.name,
          tenantId: person.tenantId,
          contentChannelId: person.contentChannelId,
          teamLeadId: person.teamLeadId ? person.teamLeadId : null,
          middleName: person.middleName ? person.middleName : null,
          lastName: person.lastName,
          familyNamePrePosition: person.familyNamePrePosition,
          dateOfBirth: person.dateOfBirth,
          about: person.about,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  update(person: Partial<PersonInterface>) {
    return this.http
      .put<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/person/update`,
        {
          ...person,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  updateById(person: Partial<PersonInterface>) {
    return this.http
      .put<any>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/person/${person.id}/update`,
        {
          ...person,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  personSearch(query: any): Observable<Q90ResponseData<Q90EntitySearchResult>> {
    return this.http
      .post<Q90ResponseData<Q90EntitySearchResult>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/person/search`,
        {
          tenantIds: query.tenantIds,
          contentChannelIds: query.contentChannelIds,
          search: query.search,
          pageSize: query.pageSize,
          page: query.page,
          orders: query.orders,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}
