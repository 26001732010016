import { Language, LanguageInterface } from 'src/app/core/models/language';
import { Timezone, TimezoneInterface } from 'src/app/core/models/timezone';
import { Deserializable } from 'src/app/shared/interfaces/deserializable';
import { Tenant, TenantInterface } from '../../tenant/models/tenant';

export interface ContentChannelProfileInterface {
  contentChannelId: string;
  defaultLanguage: LanguageInterface;
  defaultLanguageId: string;
  defaultTimeZone: TimezoneInterface;
  defaultTimeZoneId: string;
  description: string;
  entityName: string;
  icon: string;
  logoDarkBackground: string;
  logoLightBackground: string;
  logoVideoImage: string;
  ratingTenant: TenantInterface;
  ratingTenantId: string;
  relationId: string;
  streamingURL: string;
  styleGuide: string;
}

export type ContentChannelProfileKeys = keyof ContentChannelProfileInterface;

export class ContentChannelProfile implements Deserializable {
  constructor(
    private _contentChannelId: string = '',
    private _defaultLanguage: Language | null = null,
    private _defaultLanguageId: string = '',
    private _defaultTimeZone: Timezone | null = null,
    private _defaultTimeZoneId: string = '',
    private _description: string = '',
    private _entityName: string = '',
    private _icon: string = '',
    private _logoDarkBackground: string = '',
    private _logoLightBackground: string = '',
    private _logoVideoImage: string = '',
    private _ratingTenant: Tenant | null = null,
    private _ratingTenantId: string = '',
    private _relationId: string = '',
    private _streamingURL: string = '',
    private _styleGuide: string = '',
  ) { }

  get contentChannelId() {
    return this._contentChannelId;
  }
  set contentChannelId(contentChannelId) {
    this._contentChannelId = contentChannelId;
  }
  get description() {
    return this._description;
  }
  set description(description) {
    this._description = description;
  }
  get relationId() {
    return this._relationId;
  }
  set relationId(relationId) {
    this._relationId = relationId;
  }
  get entityName() {
    return this._entityName;
  }
  set entityName(entityName) {
    this._entityName = entityName;
  }
  get logoLightBackground() {
    return this._logoLightBackground;
  }
  set logoLightBackground(logoLightBackground) {
    this._logoLightBackground = logoLightBackground;
  }
  get logoDarkBackground() {
    return this._logoDarkBackground;
  }
  set logoDarkBackground(logoDarkBackground) {
    this._logoDarkBackground = logoDarkBackground;
  }
  get logoVideoImage() {
    return this._logoVideoImage;
  }
  set logoVideoImage(logoVideoImage) {
    this._logoVideoImage = logoVideoImage;
  }
  get icon() {
    return this._icon;
  }
  set icon(icon) {
    this._icon = icon;
  }
  get styleGuide() {
    return this._styleGuide;
  }
  set styleGuide(styleGuide) {
    this._styleGuide = styleGuide;
  }
  get streamingURL() {
    return this._streamingURL;
  }
  set streamingURL(streamingURL) {
    this._streamingURL = streamingURL;
  }
  get defaultTimeZoneId() {
    return this._defaultTimeZoneId;
  }
  set defaultTimeZoneId(defaultTimeZoneId) {
    this._defaultTimeZoneId = defaultTimeZoneId;
  }
  get defaultLanguageId() {
    return this._defaultLanguageId;
  }
  set defaultLanguageId(defaultLanguageId) {
    this._defaultLanguageId = defaultLanguageId;
  }
  get ratingTenantId() {
    return this._ratingTenantId;
  }
  set ratingTenantId(ratingTenantId) {
    this._ratingTenantId = ratingTenantId;
  }
  get defaultTimeZone() {
    return this._defaultTimeZone;
  }
  set defaultTimeZone(defaultTimeZone: Timezone | null) {
    this._defaultTimeZone = defaultTimeZone;
  }
  get defaultLanguage() {
    return this._defaultLanguage;
  }
  set defaultLanguage(defaultLanguage: Language | null) {
    this._defaultLanguage = defaultLanguage;
  }
  get ratingTenant() {
    return this._ratingTenant;
  }
  set ratingTenant(ratingTenant: Tenant | null) {
    this._ratingTenant = ratingTenant;
  }

  deserialize(input: ContentChannelProfileInterface): this {
    Object.assign(this, input);
    return this;
  }
}
