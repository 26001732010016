import { Injectable, inject } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  filter,
  map,
  take,
  takeUntil,
} from 'rxjs';
import { BreadcrumbInterface } from '../../shared/models/breadcrumb';
import { DestroyService } from '../../shared/services/destroy.service';

export const filteredLast = [
  /^\/sign-in$/,
  /^\/account\/sign-out$/,
  /^\/team\/overview$/,
];

export const tenantUrlRegex = /^\/tenant/;
export const teamUrlRegex = /^\/team/;

@Injectable({
  providedIn: 'root',
})
export class AppRoutingService {
  public router = inject(Router);
  public activatedRoute = inject(ActivatedRoute);
  public destroyed = inject(DestroyService);

  // Broadcasts a space-separated srtring of classnames which can be added to page parent/body tag
  private bodyClassesStore = new BehaviorSubject<string>('');
  bodyClasses$: Observable<string> = this.bodyClassesStore.asObservable();

  // Broadcasts breadcrumb interface for current page route
  private breadcrumbsStore = new BehaviorSubject<BreadcrumbInterface[]>([]);
  breadcrumbs$: Observable<BreadcrumbInterface[]> =
    this.breadcrumbsStore.asObservable();

  // Set current route
  private currentRouteStore = new BehaviorSubject<RouterStateSnapshot | null>(
    null
  );
  currentRoute$: Observable<RouterStateSnapshot | null> =
    this.currentRouteStore.asObservable();

  private currentRouteFilteredStore =
    new BehaviorSubject<RouterStateSnapshot | null>(null);
  currentRouteFiltered$: Observable<RouterStateSnapshot | null> =
    this.currentRouteFilteredStore.asObservable();

  constructor() {
    this.router.events
      .pipe(
        takeUntil(this.destroyed),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe((e) => {
        this.currentRouteStore.next(this.router.routerState.snapshot);
        if (!this.isFilteredRoute(this.router.routerState.snapshot)) {
          this.currentRouteFilteredStore.next(this.router.routerState.snapshot);
        }
      });
  }

  getCurrentUrl(): Observable<string> {
    let result: string = '';
    return this.currentRoute$.pipe(
      filter((r) => r !== null),
      map((r) => r!.url),
      take(1)
    );
  }

  getCurrentUrlFiltered(): string {
    let result: string = '';
    this.currentRouteFiltered$
      .pipe(
        take(1),
        filter((r) => r !== null)
      )
      .subscribe((r) => {
        result = r!.url;
      });
    return result;
  }

  getLastPage(): string | UrlTree {
    const currentRoute: RouterStateSnapshot | null =
      this.currentRouteStore.getValue();
    if (currentRoute && !this.isSignInPage()) {
      return currentRoute.url;
    } else {
      return '/';
    }
  }

  openExternalWebsite(e: Event, url: string) {
    window.open(url, '_blank');
  }

  isSignInPage(): boolean {
    const signInPage = /^\/sign-in$/;
    let result: boolean = false;
    this.getCurrentUrl()
      .pipe(take(1))
      .subscribe((r) => {
        if (r.search(signInPage) > -1) {
          result = true;
        }
      });
    return result;
  }

  isEntityOverviewPage(): boolean {
    const overviewPage =
      /^\/(?:tenant|contentchannel|team|person|project)\/overview$/;
    let result: boolean = false;
    this.getCurrentUrl()
      .pipe(take(1))
      .subscribe((r) => {
        if (r.search(overviewPage) > -1) {
          result = true;
        }
      });
    return result;
  }

  isEntityDetailPage(): boolean {
    const detailPage =
      /^\/(?:tenant|contentchannel|team|person|project)\/[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/;
    let result: boolean = false;
    this.getCurrentUrl()
      .pipe(take(1))
      .subscribe((r) => {
        if (r.search(detailPage) > -1) {
          result = true;
        }
      });
    return result;
  }

  private isFilteredRoute(route: RouterStateSnapshot | null): boolean {
    let result: boolean = false;
    filteredLast.forEach((regExpRoute) => {
      if (
        route instanceof RouterStateSnapshot &&
        route.url.search(regExpRoute) > -1
      ) {
        result = true;
      }
    });
    return result;
  }
}
