import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map, catchError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthenticationStoreService } from '../../../authentication/stores/authentication-store.service';
import { Q90ResponseData, Q90SearchResult } from '../../../shared/interfaces/q90-response';
import { TenantInterface, Tenant } from '../models/tenant';
import { TenantProfileInterface } from '../models/tenant-profile';
import { Q90Response } from '../../../shared/models/q90-response';
import { Q90EntitySearchQuery } from '../../interfaces/entity-search';
import { ErrorService } from '../../../shared/services/error.service';
import { UserAccountInterface } from '../../user/models/user-account';

@Injectable({
  providedIn: 'root',
})
export class TenantService {

  private authenticationStore = inject(AuthenticationStoreService);
  private http = inject(HttpClient);
  private errorService = inject(ErrorService);

  constructor() { }

  fetchTenants(query: Q90EntitySearchQuery): Observable<Q90ResponseData<Q90SearchResult<TenantInterface[]>>> {
    return this.http.post<Q90ResponseData<Q90SearchResult<TenantInterface[]>>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/tenant/search`,
      {
        ...query
      },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Bearer ' + this.authenticationStore.getToken()
        ),
        responseType: 'json',
      }
    )
      .pipe(
        map(res => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  fetchTenantOrderBy(): Observable<Q90ResponseData<Record<string, | string | number | boolean>>> {
    return this.http.get<Q90ResponseData<Record<string, | string | number | boolean>>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/tenant/search/orderby/list`,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Bearer ' + this.authenticationStore.getToken()
        ),
        responseType: 'json',
      }
    )
      .pipe(
        map(res => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  getProfile(
    tenantId: string
  ): Observable<Q90ResponseData<TenantProfileInterface>> {
    return this.http
      .get<Q90ResponseData<TenantProfileInterface>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/tenant/${tenantId}/profile`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  setToBoolean(query: Partial<TenantProfileInterface>) {
    Object.keys(query).forEach((key) => {
      const value = query[key as keyof typeof query];
      if (typeof value === 'string' && (value === 'true' || value === 'false')) {
        (query[key as keyof typeof query] as unknown as boolean) = value === 'true';
      }
    });
  }

  updateTenant(
    query: Partial<TenantInterface>,
    tenantId: string
  ): Observable<Q90ResponseData<TenantInterface>> {
    return this.http
      .post<Q90ResponseData<TenantInterface>>(
        environment.apiEndpointRoot +
        environment.apiEndpointVersion +
        `/tenant/${tenantId}/update`,
        {
          id: query.id,
          name: query.name,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  updateTenantProfile(
    query: Partial<TenantProfileInterface>,
    tenantId: string
  ): Observable<Q90ResponseData<TenantProfileInterface>> {
    this.setToBoolean(query);
    return this.http
      .post<Q90ResponseData<TenantProfileInterface>>(
        environment.apiEndpointRoot +
        environment.apiEndpointVersion +
        `/tenant/${tenantId}/profile/update`,

        query,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  createTenant(query: Partial<TenantInterface>) {
    return this.http
      .post<Q90ResponseData<TenantInterface>>(
        environment.apiEndpointRoot +
        environment.apiEndpointVersion +
        `/tenant/create`,
        {
          ...query,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  removeTenant(tenant: Tenant) {
    return this.http
      .post<Q90ResponseData<TenantInterface>>(
        environment.apiEndpointRoot +
        environment.apiEndpointVersion +
        `/tenant/remove`,
        {
          data: tenant.id,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  fetchTenantMembers(query: Q90EntitySearchQuery): Observable<Q90ResponseData<Q90SearchResult<UserAccountInterface[]>>> {
    return this.http.post<Q90ResponseData<Q90SearchResult<UserAccountInterface[]>>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/tenant/members/search`,
      {
        ...query
      },
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Bearer ' + this.authenticationStore.getToken()
        ),
        responseType: 'json',
      }
    )
      .pipe(
        map(res => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError(err => {
          throw err;
        })
      );
  }

  fetchTenantMembersOrderBy(): Observable<Q90ResponseData<Record<string, | string | number | boolean>>> {
    return this.http.get<Q90ResponseData<Record<string, | string | number | boolean>>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion}/tenant/members/search/orderby/list`,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Bearer ' + this.authenticationStore.getToken()
        ),
        responseType: 'json',
      }
    )
      .pipe(
        map(res => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

}
