import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, combineLatest, map } from 'rxjs';
import {
  AppMenusService,
  MasterMenus,
} from '../../../core/services/app-menus.service';
import { AppThemesService } from '../../../core/services/app-themes.service';
import { AppService } from '../../../core/services/app.service';
import { Person } from '../../../entity/person/models/person';
import { UserAccount } from '../../../entity/user/models/user-account';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Tenant } from '../../../entity/tenant/models/tenant';
import { CurrentTenantService } from '../../../core/services/current-tenant.service';
import { Theme } from '../../../core/models/theme';

interface UserAccountTenantsMenuData {
  userAccountLoggedIn: boolean;
  authenticatedUserAccount: UserAccount | null;
  isAdmin: boolean;
  authenticatedPerson: Person | null;
  refreshingToken: boolean;
  theme: Theme;
  userAccountTenantsMenuState: boolean;
  myTenants: Tenant[];
  myCurrentTenant: Tenant | null;
}

@Component({
  selector: 'account-user-account-menu-tenants',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive],
  templateUrl: './user-account-menu-tenants.component.html',
  styleUrls: ['./user-account-menu-tenants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAccountMenuTenantsComponent {
  appMenus = MasterMenus;

  userAccountTenantsMenuData$!: Observable<UserAccountTenantsMenuData>;

  private appService = inject(AppService);
  private appThemesService = inject(AppThemesService);
  private appMenusService = inject(AppMenusService);
  private userAccountStore = inject(UserAccountStoreService);
  private currentTenantService = inject(CurrentTenantService);

  ngOnInit(): void {
    this.userAccountTenantsMenuData$ = combineLatest([
      this.userAccountStore.userAccountLoggedIn$,
      this.userAccountStore.authenticatedUserAccountStore.entity$,
      this.userAccountStore.isAdmin(),
      this.userAccountStore.authenticatedPersonStore.entity$,
      this.appService.refreshingToken$,
      this.appThemesService.theme$,
      this.appMenusService.appMenusState$.pipe(
        map((menuState) => menuState[MasterMenus.UserAccountTenantsDropdown]),
      ),
      this.userAccountStore.userAccountMyTenants$,
      this.currentTenantService.currentTenant$,
    ]).pipe(
      map(
        ([
          userAccountLoggedIn,
          authenticatedUserAccountEntity,
          isAdmin,
          authenticatedPersonEntity,
          refreshingToken,
          theme,
          userAccountTenantsMenuState,
          myUnfilteredTenants,
          myCurrentTenant,
        ]) => {
          const authenticatedUserAccount =
            authenticatedUserAccountEntity as UserAccount;
          const authenticatedPerson = authenticatedPersonEntity as Person;

          const myTenants = myUnfilteredTenants.sort((a, b) =>
            a.name.localeCompare(b.name),
          );

          return {
            userAccountLoggedIn,
            authenticatedUserAccount,
            isAdmin,
            authenticatedPerson,
            refreshingToken,
            theme,
            userAccountTenantsMenuState,
            myTenants,
            myCurrentTenant,
          };
        },
      ),
    );
  }

  myTenantsTracker(index: number, tenant: Tenant): string {
    return tenant.id;
  }

  toggle(appMenu: string): void {
    this.appMenusService.toggle(appMenu);
  }

  setUserAccountMenuState(): void {
    this.appMenusService.toggle(MasterMenus.UserAccountTenantsDropdown);
  }

  setCurrentTenant(tenant: Tenant, currentTenant: Tenant | null) {
    if (tenant?.id === currentTenant?.id) {
      this.currentTenantService.setCurrentTenant(null);
    } else {
      this.currentTenantService.setCurrentTenant(tenant);
    }
  }
}
