<section *ngIf="data">
  <table class="data-table">
    <tr class="header">
      <th *ngFor="let header of headers">
        {{ header.name }}
      </th>
    </tr>
    @if (data.length > 0) {
      <tr
        *ngFor="let row of data"
        (click)="selectRow(row)"
        [ngClass]="{ 'is-active': checkActive(row) }"
      >
        <td *ngFor="let cel of headers">
          {{ row[cel.value] }}
        </td>
      </tr>
    } @else {
      <td>No errors found</td>
    }
  </table>
</section>
