import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from '../main-layout/main-layout.component';
import { RouterOutlet, RouterLink } from '@angular/router';
import { UserAccountNavigationComponent } from 'src/app/account/components/user-account-navigation/user-account-navigation.component';
import { CookieDisclaimerComponent } from 'src/app/core/components/cookie-disclaimer/cookie-disclaimer.component';
import { CurrentTenantComponent } from 'src/app/core/components/current-tenant/current-tenant.component';
import { NavigationHeaderComponent } from 'src/app/core/components/navigation/navigation-header/navigation-header.component';
import { NavigationSidebarComponent } from 'src/app/core/components/navigation/navigation-sidebar/navigation-sidebar.component';
import { RefreshingTokenComponent } from 'src/app/core/components/refreshing-token/refreshing-token.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { NavigationFooterComponent } from 'src/app/core/components/navigation/navigation-footer/navigation-footer.component';
import { Person } from '../../entity/person/models/person';
import { NavigationSidebarContentComponent } from '../../core/components/navigation/navigation-sidebar/navigation-sidebar-content/navigation-sidebar-content.component';
import { Theme } from '../../core/models/theme';

interface ApplicationData {
  theme: Theme;
  userAccountLoggedIn: boolean;
  authenticatedPerson: Person | null;
  appMenuState: boolean;
}

@Component({
  selector: 'layout-no-sidebar-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    NavigationHeaderComponent,
    CookieDisclaimerComponent,
    RefreshingTokenComponent,
    CurrentTenantComponent,
    ModalComponent,
    NavigationSidebarComponent,
    UserAccountNavigationComponent,
    NavigationSidebarContentComponent,
    NavigationFooterComponent
  ],
  templateUrl: './no-sidebar-layout.component.html',
  styleUrls: ['./no-sidebar-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoSidebarLayoutComponent extends MainLayoutComponent implements OnInit {

  override ngOnInit(): void {
    super.ngOnInit();
  }
}
