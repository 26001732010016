import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { Q90Entity, Q90EntityInterface } from '../models/q90-entity-base';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Q90ResponseData } from '../../shared/interfaces/q90-response';
import { AuthenticationStoreService } from '../../authentication/stores/authentication-store.service';
import { DestroyService } from '../../shared/services/destroy.service';
import { ErrorService } from '../../shared/services/error.service';
import { ErrorMessagesStoreService } from '../../shared/stores/error-messages-store.service';

@Injectable({ providedIn: null })
export abstract class EntityDetailStoreService {
  protected abstract entitySearchEndpoint: string;

  protected authenticationStore = inject(AuthenticationStoreService);
  protected destroyed = inject(DestroyService);
  protected http = inject(HttpClient);
  protected errorService = inject(ErrorService);
  public errorMessagesStore: ErrorMessagesStoreService;

  protected entityStore = new BehaviorSubject<Q90Entity | null>(null);
  entity$: Observable<Q90Entity | null> = this.entityStore.asObservable();

  protected loadingStore = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loadingStore.asObservable();

  constructor() {
    this.errorMessagesStore = new ErrorMessagesStoreService();
  }

  setStore(entityDetail: Q90Entity | null): void {
    this.entityStore.next(entityDetail);
  }

  abstract updateStore(data: Partial<Q90EntityInterface>): void;
  abstract loadEntity(id: string): void;

  protected updateEntity<T, K>(entity: T, data: Partial<K>): T {
    for (let prop in data) {
      const val = data[prop as keyof K] as never;
      entity[prop as never] = val;
    }
    return entity;
  }

  protected getEndpointWithId(id: string): string {
    // Not the most robust template but working at the moment...
    const regex = /(\/)id/;
    return this.entitySearchEndpoint.replace(regex, '$1' + id);
  }

  protected getById(id: string) {
    return this.http
      .get<Q90ResponseData<Q90EntityInterface>>(
        `${environment.apiEndpointRoot +
        environment.apiEndpointVersion +
        this.getEndpointWithId(id)
        }`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}
