import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, combineLatest, map } from 'rxjs';
import { ErrorMessagesStoreService } from '../../../../shared/stores/error-messages-store.service';
import { Q90Message, Q90MessageLevels } from '../../../../shared/models/q90-message';
import { Q90ErrorResponseTypes } from '../../../interfaces/q90-response';

interface FormErrorMessagesData {
  messages: Q90ErrorResponseTypes | Q90Message | null;
}

@Component({
    selector: 'shared-error-messages',
    imports: [CommonModule],
    providers: [{ provide: ErrorMessagesStoreService }],
    templateUrl: './error-messages.component.html',
    styleUrls: ['./error-messages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorMessagesComponent {

  data$!: Observable<FormErrorMessagesData>;
  level!: Q90MessageLevels;

  @Input() errorMessagesStore!: ErrorMessagesStoreService;
  @Input() showClose: boolean = true;
  @Input() styleClass!: string;

  ngOnInit(): void {
    this.data$ = combineLatest([
      this.errorMessagesStore.formMessages$
    ]).pipe(
      map(([messages]) => {
        this.level = 'error';
        if (messages instanceof Q90Message) {
          this.level = messages.level;
        }
        return {
          messages,
        };
      })
    );
  }

  closeMessage(e: Event) {
    this.errorMessagesStore.setMessage(null);
  }
}
