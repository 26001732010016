<ng-container *ngIf="applicationData$ | async as applicationData">
  <div
    id="q90-body-header"
    [ngClass]="applicationData.theme.class"
  >
    <div class="grid-layout grid-cols-3 spread align-center middle-heavy">
      <core-navigation-header></core-navigation-header>
      <core-navigation-tenant-switch />
      <account-user-account-navigation />
    </div>
  </div>
  <div
    id="q90"
    class="layout-main"
    [ngClass]="{'sidebar-collapsed': applicationData.appMenuCollapsedState}"
  >
    <div
      id="q90-sidebar"
      [ngClass]="applicationData.theme.class"
    >
      <button
        class="collapsed-toggle"
        (click)="toggleCollapse()"
      > {{applicationData.appMenuCollapsedState ? '⯈' : '⯇'}} </button>
      <div
        id="q90-sidebar-header"
        [ngClass]="applicationData.theme.class"
      >
      </div>
      <div
        id="q90-sidebar-nav"
        [ngClass]="applicationData.theme.class"
      >
        <core-navigation-sidebar />
        <core-navigation-footer />
      </div>
    </div>
    <div
      id="q90-body"
      [ngClass]="[applicationData.theme.class, (applicationData.appMenuState?'is-open':'is-closed')]"
    >
      <main [ngClass]="applicationData.theme.class">
        <div id="q90-body-wrapper">
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>
    <core-cookie-disclaimer />
    <core-refreshing-token />
  </div>
  <shared-modal
    *ngIf="applicationData.unsavedFormsWarning"
    title="Warning"
  >
    <div class="grid-layout grid-cols2 align-center spread">
      <div class="button-bar">
        <button type="button">Abort</button>
        <button type="button">Continue</button>
      </div>
    </div>
    You have unsaved forms on the page. Would you like to continue with form or abort changes?
  </shared-modal>
  <p-contextmenu
    #cm
    *ngIf="contextMenuService.cmContext()"
    [model]="contextMenuService.cmContext()"
    (onHide)="onHide()"
    [global]="true"
  >
    <ng-template
      #item
      let-item
    >
      <a
        pRipple
        class="flex items-center p-contextmenu-item-link"
      >
        <span [class]="item.icon"></span>
        <span class="ml-2">{{ item.label }}</span>
        <p-badge
          *ngIf="item.badge"
          class="ml-auto"
          [value]="item.badge"
        />
        <span
          *ngIf="item.shortcut"
          class="ml-auto border border-surface rounded bg-emphasis text-muted-color text-xs p-1"
        >{{ item.shortcut }}</span>
        <i
          *ngIf="item.items"
          class="pi pi-angle-right ml-auto"
        ></i>
      </a>
    </ng-template>
  </p-contextmenu>
</ng-container>