import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NavigationSidebarContentComponent } from '../navigation-sidebar-content/navigation-sidebar-content.component';
import { CurrentTenantIdStore } from '../../../../../pages/tenant/tenant/tenant.service';

@Component({
  selector: 'core-tenant-overview-subnav',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    NavigationSidebarContentComponent,
  ],
  templateUrl: './tenant-overview-subnav.component.html',
  styleUrls: [
    './tenant-overview-subnav.component.scss',
    '../navigation-sidebar-content/navigation-sidebar-content.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantOverviewSubnavComponent extends NavigationSidebarContentComponent {
  currentTenantIdStore = inject(CurrentTenantIdStore);
  constructor(public router: Router) {
    super();
  }

  baseRoute = this.router.url.split('/', 3).join('/');
}
