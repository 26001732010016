import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AddressType } from '../../entity-secondary/entity-address/models/address-type';

@Injectable({
  providedIn: 'root',
})
export class AddressStoreService {
  // Store for address types
  private addressTypesStore = new BehaviorSubject<AddressType[]>([]);
  addressTypes$: Observable<AddressType[]> =
    this.addressTypesStore.asObservable();

  constructor() {}

  setAddressTypes(addressTypes: AddressType[]): void {
    this.addressTypesStore.next(addressTypes);
  }
}
