import { OptionCollection } from "src/app/shared/interfaces/option-collection";
import { Deserializable } from "src/app/shared/interfaces/deserializable";

export interface AddressTypeInterface {
  id: string;
  name: string;
}

export type AddressTypeKeys = keyof AddressTypeInterface;

export class AddressType implements Deserializable, OptionCollection {
  constructor(
    private _id: string = '',
    private _name: string = '',
  ) {
  }

  get optionName() {
    return this.name;
  }
  get optionValue() {
    return this.id;
  }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }

  deserialize(input: AddressTypeInterface): this {
    Object.assign(this, input);
    return this;
  }
}
