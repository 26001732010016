import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output, } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from '../../../../core/services/app.service';
import { Q90Message } from '../../../models/q90-message';
import { Q90ErrorResponseTypes } from '../../../interfaces/q90-response';
import { DestroyService } from '../../../services/destroy.service';
import { ErrorMessagesStoreService } from '../../../stores/error-messages-store.service';
import { ErrorService } from '../../../services/error.service';
import { EInputType, TFormSchema, validatorMap } from "../../../../models/form/schema";

@Component({
  selector: 'shared-form',
  imports: [CommonModule],
  providers: [{ provide: ErrorMessagesStoreService }],
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class FormComponent  {
  protected appService = inject(AppService);
  protected errorService = inject(ErrorService);
  protected errorMessagesStore = inject(ErrorMessagesStoreService);
  protected destroyed = inject(DestroyService);
  protected formBuilder = inject(FormBuilder);
  public invalidFields: Record<string, string[]> = {};

  @Output() isSubmitted = new EventEmitter<FormComponent>();
  @Output() cancelEdit = new EventEmitter<boolean>();

  protected submittingStore = new BehaviorSubject<boolean>(false);
  submitting$: Observable<boolean> = this.submittingStore.asObservable();

  public formGroup!: FormGroup;
  protected submitted = false;

  constructor() {}

  ngOnInit(): void {}

  submittable(formGroup: FormGroup | FormArray = this.formGroup): boolean {
    let result: boolean = true;
    if (!formGroup.valid) {
      result = false;
      this.setErrorMessage(
        new Error('The form has errors. Please fix them and submit again.'),
      );
    }
    if (formGroup.pristine) {
      result = false;
      this.setMessage(
        new Q90Message('The form has not been changed.', 'warning'),
      );
    }
    return result;
  }

  onSubmit(e?: Event) {
    this.submitted = true;
    this.setInvalidFields();
    this.isSubmitted.emit(this);
  }

  cancelMe(e: Event): void {
    this.errorMessagesStore.setMessage(null);
    this.cancelEdit.emit(true);
  }

  formClicked(e: Event): void {
    this.errorMessagesStore.setMessage(null);
  }

  setErrorMessage(message: Q90ErrorResponseTypes) {
    this.errorMessagesStore.setErrorMessage(message);
  }

  setMessage(message: Q90Message) {
    this.errorMessagesStore.setMessage(message);
  }

  setSubmitting(setting: boolean) {
    this.submittingStore.next(setting);
  }

  clearFormArray(formArrayControl: FormArray): void {
    while (formArrayControl.length !== 0) {
      formArrayControl.removeAt(0);
    }
  }

  setInvalidFields(
    formGroupName: string | null = null,
    invalidFields: Record<string, string[]> = {},
  ): void {
    const formGroup = (
      formGroupName ? this.formGroup.get(formGroupName) : this.formGroup
    ) as FormGroup;
    for (const controlName in formGroup.controls) {
      const control = formGroup.get(controlName);
      if (control instanceof FormGroup) {
        if (!control.valid) {
          invalidFields[controlName] = [];
          this.setInvalidFields(controlName, invalidFields);
        }
      } else {
        if (control instanceof FormControl) {
          if (!control.valid) {
            invalidFields[controlName] = [];
            invalidFields[formGroupName ?? controlName].push(controlName);
          }
        }
      }
    }
    this.invalidFields = invalidFields;
  }

  // Obsolete if your formGroup structure matches your api call payload structure. In this case,
  // use const myPayloadData = myFormGroup.values to 'construct' query for API update call.
  getQueryFromFormGroup<T, K extends keyof T>(
    formGroupName: string | null = null,
    query: Partial<T> = {},
  ): Partial<T> {
    const formGroup = (
      formGroupName ? this.formGroup.get(formGroupName) : this.formGroup
    ) as FormGroup;
    for (const controlName in formGroup.controls) {
      const control = formGroup.get(controlName);
      if (control instanceof FormGroup) {
        this.getQueryFromFormGroup(controlName, query);
      } else {
        if (control instanceof FormControl) {
          query[<K>controlName] = control!.value;
        }
      }
    }
    return query;
  }

  getFormSchema(schema: TFormSchema) {
    const excludeFromFormControl: EInputType[] = [
      EInputType.divider,
      EInputType.title,
    ];
    Object.entries(schema).forEach(([key, value]) => {
      if (excludeFromFormControl.includes(value.type)) {
        return;
      }
      if (value.type === EInputType.container) {
        this.getFormSchema(value.schema!);
        return;
      }
      this.formGroup.addControl(
        key, new FormControl(
          value.value,
          value.rules?.map(rule => validatorMap[rule]) ?? []
        )
      );
    });
  }

  clearFormSchema() {
    Object.keys(this.formGroup.controls).forEach(controlName =>
      this.formGroup.removeControl(controlName)
    );
    // this.formGroup.removeControl()
  }

  /**
   * Recursively populates a formGroup of like structure with values in entity
   * Perhaps this can be replaced with use of the patchValue() call on FormGroups. ???
   *
   * @param entity
   * @param formGroup
   * @param controlNameList
   */
  protected populateForm<T, K extends keyof T>(
    entity: T,
    formGroup: FormGroup | string = this.formGroup,
    controlNameList: string[] = [],
  ): void {
    if (typeof formGroup === 'string') {
      this.appService.debug(
        'The string ' +
          formGroup +
          ' was used to populate form. Use a form group object instead.',
      );
      formGroup = this.formGroup.get(formGroup) as FormGroup;
    }
    for (const controlName in formGroup.controls) {
      const control = formGroup.get(controlName);
      if (control instanceof FormGroup) {
        this.populateForm<T, K>(entity, control, [
          ...controlNameList,
          controlName,
        ]);
      }
      if (control instanceof FormArray) {
        control.controls.forEach((control2, i) => {
          if (control2 instanceof FormGroup) {
            this.populateForm<T, K>(entity, control2, [
              ...controlNameList,
              controlName,
              i.toString(),
            ]);
          }
        });
      }
      if (control instanceof FormControl) {
        // console.log(controlNameList, controlName);
        // console.log(controlName, typeof control.value);
        control.patchValue(
          this.deepGetWrapper(entity, [...controlNameList, controlName]),
        );
      }
    }
  }

  // https://www.30secondsofcode.org/js/s/deep-get-object-value/
  private deepGetWrapper(entity: any, pathValue: string[] = []): any {
    const deepGet = (entity: any, pathValue: string[] = []) =>
      pathValue.reduce(
        (xs, x) => (xs && xs[x] !== null && xs[x] !== undefined ? xs[x] : null),
        entity,
      );
    return deepGet(entity, pathValue);
  }
}
