import { Deserializable } from 'src/app/shared/interfaces/deserializable';
import { TenantInterface, Tenant } from '../../tenant/models/tenant';
import { OptionCollection } from 'src/app/shared/interfaces/option-collection';
import {
  ContentChannelProfile,
  ContentChannelProfileInterface,
} from './content-channel-profile';
import {
  XperienceItemColumnModel,
  XperienceItemColumnType,
} from '../../../xperience/models/xperience-models';

export interface ContentChannelInterface {
  id: string;
  name: string;
  tenant: TenantInterface;
  profile: ContentChannelProfileInterface;
  contentChannelTypeId: string;
}

export type ContentChannelKeys = keyof ContentChannelInterface;

export const ContentChannelAdminColumns: XperienceItemColumnModel<ContentChannelKeys>[] =
  [
    {
      column: 'name',
      orderBy: true,
      searchBy: true,
      friendly: 'Name',
      columnClass: XperienceItemColumnType.TextColumn,
    },
  ];

export class ContentChannel implements Deserializable, OptionCollection {
  constructor(
    private _id: string = '',
    private _name: string = '',
    private _tenant: Tenant | null = null,
    private _profile: ContentChannelProfile | null = null,
    private _contentChannelTypeId: string = ''
  ) {}

  get optionName() {
    return this.name;
  }
  get optionValue() {
    return this.id;
  }
  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  set name(name) {
    this._name = name;
  }
  get name() {
    return this._name;
  }
  get tenant() {
    return this._tenant;
  }
  set tenant(tenant) {
    this._tenant = tenant;
  }
  get profile() {
    return this._profile;
  }
  set profile(profile) {
    this._profile = profile;
  }
  get contentChannelTypeId() {
    return this._contentChannelTypeId;
  }
  set contentChannelTypeId(contentChannelTypeId) {
    this._contentChannelTypeId = contentChannelTypeId;
  }

  deserialize(input: ContentChannelInterface): this {
    Object.assign(this, input);
    if (input.tenant)
      this.tenant = new Tenant().deserialize(<TenantInterface>input.tenant);
    if (input.profile)
      this.profile = new ContentChannelProfile().deserialize(
        <ContentChannelProfileInterface>input.profile
      );

    return this;
  }
}
