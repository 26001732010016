import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { aimonitorChannels$Json } from '../fn/ai-monitor/aimonitor-channels-json';
import { AimonitorChannels$Json$Params } from '../fn/ai-monitor/aimonitor-channels-json';
import { aimonitorChannels$Plain } from '../fn/ai-monitor/aimonitor-channels-plain';
import { AimonitorChannels$Plain$Params } from '../fn/ai-monitor/aimonitor-channels-plain';
import { aimonitorChannelsAcknowledge } from '../fn/ai-monitor/aimonitor-channels-acknowledge';
import { AimonitorChannelsAcknowledge$Params } from '../fn/ai-monitor/aimonitor-channels-acknowledge';
import { aimonitorChannelsAcknowledgeAll } from '../fn/ai-monitor/aimonitor-channels-acknowledge-all';
import { AimonitorChannelsAcknowledgeAll$Params } from '../fn/ai-monitor/aimonitor-channels-acknowledge-all';
import { aimonitorChannelsCheckresults$Json } from '../fn/ai-monitor/aimonitor-channels-checkresults-json';
import { AimonitorChannelsCheckresults$Json$Params } from '../fn/ai-monitor/aimonitor-channels-checkresults-json';
import { aimonitorChannelsCheckresults$Plain } from '../fn/ai-monitor/aimonitor-channels-checkresults-plain';
import { AimonitorChannelsCheckresults$Plain$Params } from '../fn/ai-monitor/aimonitor-channels-checkresults-plain';
import { aimonitorChannelsCheckresultsAll$Json } from '../fn/ai-monitor/aimonitor-channels-checkresults-all-json';
import { AimonitorChannelsCheckresultsAll$Json$Params } from '../fn/ai-monitor/aimonitor-channels-checkresults-all-json';
import { aimonitorChannelsCheckresultsAll$Plain } from '../fn/ai-monitor/aimonitor-channels-checkresults-all-plain';
import { AimonitorChannelsCheckresultsAll$Plain$Params } from '../fn/ai-monitor/aimonitor-channels-checkresults-all-plain';
import { aimonitorChannelsUnacknowledge } from '../fn/ai-monitor/aimonitor-channels-unacknowledge';
import { AimonitorChannelsUnacknowledge$Params } from '../fn/ai-monitor/aimonitor-channels-unacknowledge';
import { aimonitorChannelsUnacknowledgeAll } from '../fn/ai-monitor/aimonitor-channels-unacknowledge-all';
import { AimonitorChannelsUnacknowledgeAll$Params } from '../fn/ai-monitor/aimonitor-channels-unacknowledge-all';
import { aimonitorVersion$Json } from '../fn/ai-monitor/aimonitor-version-json';
import { AimonitorVersion$Json$Params } from '../fn/ai-monitor/aimonitor-version-json';
import { aimonitorVersion$Plain } from '../fn/ai-monitor/aimonitor-version-plain';
import { AimonitorVersion$Plain$Params } from '../fn/ai-monitor/aimonitor-version-plain';
import { CheckResults } from '../models/check-results';


@Injectable({ providedIn: 'root' })
export class AiMonitorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `aimonitorVersion()` */
  static readonly AimonitorVersionPath = '/api/v1/aimonitor/version';
  /** Path part for operation `aimonitorChannels()` */
  static readonly AimonitorChannelsPath = '/api/v1/aimonitor/channels';
  /** Path part for operation `aimonitorChannelsCheckresults()` */
  static readonly AimonitorChannelsCheckresultsPath = '/api/v1/aimonitor/channels/checkresults';
  /** Path part for operation `aimonitorChannelsCheckresultsAll()` */
  static readonly AimonitorChannelsCheckresultsAllPath = '/api/v1/aimonitor/channels/checkresults/all';
  /** Path part for operation `aimonitorChannelsAcknowledgeAll()` */
  static readonly AimonitorChannelsAcknowledgeAllPath = '/api/v1/aimonitor/channels/acknowledge/all';
  /** Path part for operation `aimonitorChannelsUnacknowledgeAll()` */
  static readonly AimonitorChannelsUnacknowledgeAllPath = '/api/v1/aimonitor/channels/unacknowledge/all';
  /** Path part for operation `aimonitorChannelsAcknowledge()` */
  static readonly AimonitorChannelsAcknowledgePath = '/api/v1/aimonitor/channels/acknowledge';
  /** Path part for operation `aimonitorChannelsUnacknowledge()` */
  static readonly AimonitorChannelsUnacknowledgePath = '/api/v1/aimonitor/channels/unacknowledge';


  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aimonitorVersion$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  aimonitorVersion$Plain(params?: AimonitorVersion$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return aimonitorVersion$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aimonitorVersion$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  aimonitorVersion$Json(params?: AimonitorVersion$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return aimonitorVersion$Json(this.http, this.rootUrl, params, context);
  }


  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aimonitorChannels$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  aimonitorChannels$Plain(params?: AimonitorChannels$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return aimonitorChannels$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aimonitorChannels$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  aimonitorChannels$Json(params?: AimonitorChannels$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return aimonitorChannels$Json(this.http, this.rootUrl, params, context);
  }


  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aimonitorChannelsCheckresults$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  aimonitorChannelsCheckresults$Plain(params?: AimonitorChannelsCheckresults$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CheckResults>> {
    return aimonitorChannelsCheckresults$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aimonitorChannelsCheckresults$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  aimonitorChannelsCheckresults$Json(params?: AimonitorChannelsCheckresults$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CheckResults>> {
    return aimonitorChannelsCheckresults$Json(this.http, this.rootUrl, params, context);
  }


  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aimonitorChannelsCheckresultsAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  aimonitorChannelsCheckresultsAll$Plain(params?: AimonitorChannelsCheckresultsAll$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: CheckResults;
}>> {
    return aimonitorChannelsCheckresultsAll$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aimonitorChannelsCheckresultsAll$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  aimonitorChannelsCheckresultsAll$Json(params?: AimonitorChannelsCheckresultsAll$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: CheckResults;
}>> {
    return aimonitorChannelsCheckresultsAll$Json(this.http, this.rootUrl, params, context);
  }


  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aimonitorChannelsAcknowledgeAll$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  aimonitorChannelsAcknowledgeAll(params?: AimonitorChannelsAcknowledgeAll$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return aimonitorChannelsAcknowledgeAll(this.http, this.rootUrl, params, context);
  }


  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aimonitorChannelsUnacknowledgeAll$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  aimonitorChannelsUnacknowledgeAll(params?: AimonitorChannelsUnacknowledgeAll$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return aimonitorChannelsUnacknowledgeAll(this.http, this.rootUrl, params, context);
  }


  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aimonitorChannelsAcknowledge$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  aimonitorChannelsAcknowledge(params?: AimonitorChannelsAcknowledge$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return aimonitorChannelsAcknowledge(this.http, this.rootUrl, params, context);
  }


  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `aimonitorChannelsUnacknowledge$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  aimonitorChannelsUnacknowledge(params?: AimonitorChannelsUnacknowledge$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return aimonitorChannelsUnacknowledge(this.http, this.rootUrl, params, context);
  }

}
