<shared-modal
  *ngIf="showCookieModal()"
  [withCloseButton]="true"
  (closeModal)="cookieDisclaimerClosed()"
  title="Cookie disclaimer"
>
  <section>
    <p class="p-0 m-0">We gebruiken cookies om onze website beter te laten werken, je ervaring te
      verbeteren en je relevante
      inhoud te laten
      zien. Klik op "Akkoord" als je dit goed vindt.</p>
    <footer class="mt-1">
      <div class="button-bar">
        <button
          type="button"
          (click)="cookieDisclaimerClosed()"
        >
          Weigeren
        </button>
        <button
          class="action"
          type="button"
          (click)="cookieDisclaimerAgreed()"
        >
          Akkoord
        </button>
        <a
          class="button m-0"
          [routerLink]="['/help/cookie-policy']"
        >
          Meer informatie
        </a>
      </div>
    </footer>
  </section>
</shared-modal>
