import { CommonModule } from '@angular/common';
import { Component, HostListener, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ApplicationEvent, AppService } from './core/services/app.service';
import { AppMenusService, MasterMenus, } from './core/services/app-menus.service';
import { AppRoutingService } from './core/services/app-routing.service';
import { AppThemesService } from './core/services/app-themes.service';
import { UserAccountService } from './account/services/user-account.service';
import { AuthenticationService } from './authentication/services/authentication.service';
import { AuthorizationService } from './authorization/services/authorization.service';
import { AddressService } from './core/services/address.service';
import { CoreContentChannelService } from './core/services/core-content-channel.service';
import { CurrentTenantService } from './core/services/current-tenant.service';
import { GeneralService } from './core/services/general.service';
import { PersonService } from './entity/person/services/person.service';
import { EntityFiltersService } from './entity/services/entity-filters.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule, RouterOutlet],
})
export class AppComponent implements OnInit {
  private appService = inject(AppService);
  private appMenusService = inject(AppMenusService);
  private appRoutingService = inject(AppRoutingService);
  private appThemesService = inject(AppThemesService);

  // Inject primeable services to trigger priming of them, the injection alone
  // is necessary to activate and prime them...
  private coreContentChannelService = inject(CoreContentChannelService);
  private generalService = inject(GeneralService);
  private entityFiltersService = inject(EntityFiltersService);
  private authenticationService = inject(AuthenticationService);
  private authorizationService = inject(AuthorizationService);
  private userAccountService = inject(UserAccountService);
  private personService = inject(PersonService);
  private addressService = inject(AddressService);
  private currentTenantService = inject(CurrentTenantService);

  ngOnInit(): void {
    this.appService.setApplicationEvent(ApplicationEvent.Prime);
  }

  @HostListener('window:keyup.escape')
  escapeKeyEvent() {
    this.appMenusService.set(MasterMenus.UserAccountDropdown, false);
    this.appMenusService.set(MasterMenus.UserAccountTenantsDropdown, false);
  }
}
