import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { AppService } from '../../../core/services/app.service';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { Person } from '../../../entity/person/models/person';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { AvatarComponent } from '../../../core/components/avatar/avatar.component';

interface AvatarData {
  authenticatedPerson: Person | null;
  refreshingToken: boolean;
}

@Component({
    selector: 'account-avatar',
    imports: [CommonModule, SpinnerComponent, AvatarComponent],
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountAvatarComponent {
  avatarData$!: Observable<AvatarData>;

  @Input() size = 'sm';
  @Input() negotiateFormat = true;
  @Input() withLink = true;

  public appService = inject(AppService);
  public router = inject(Router);
  public userAccountStore = inject(UserAccountStoreService);
  spinnerClass!: string;

  constructor() { }

  ngOnInit(): void {
    this.spinnerClass = 'spinner-white-' + this.size;
    this.avatarData$ = combineLatest([
      this.userAccountStore.authenticatedPersonStore.entity$,
      this.appService.refreshingToken$,
    ]).pipe(
      map(([personEntity, refreshingToken]) => {
        const authenticatedPerson = personEntity as Person;
        return {
          authenticatedPerson,
          refreshingToken,
        };
      })
    );
  }

  // toProfilePage(e: Event, platformUser: UserAccount): void {
  //   if (this.withLink) {
  //     e.stopPropagation();
  //     this.router.navigateByUrl(`/platform/user/${platformUser.id}/about`);
  //   }
  // }
}
