import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  forkJoin,
  takeUntil,
  map,
  catchError,
  BehaviorSubject,
  take,
} from 'rxjs';
import { PrimeableService } from '../../core/services/primeable.service';

import {
  Q90ResponseData,
  Q90ResponseDataCollection,
  Q90ResponseEndpointsCollection,
} from '../../shared/interfaces/q90-response';
import { environment } from '../../../environments/environment';
import { DynamicFilterOptionInterface } from '../../shared/models/dynamic-filter-option';
import { DynamicFilter } from '../../core/models/dynamic-filter';
import { DynamicFilterSearchEndpointInterface } from '../../core/models/dynamic-filter-search-endpoint';
import { DynamicFilterInterface } from '../interfaces/entity-search';
import { Q90Response } from '../../shared/models/q90-response';

@Injectable({
  providedIn: 'root',
})
export class EntityFiltersService extends PrimeableService {

  // Store for all application search filters metadata...
  private filtersStore = new BehaviorSubject<DynamicFilterSearchEndpointInterface[]>([]);
  filters$: Observable<DynamicFilterSearchEndpointInterface[]> = this.filtersStore.asObservable();

  constructor() {
    super();
  }

  protected prime() {
    this.setQueryingPrimeableService(true);
    const observablesArray: Observable<Q90ResponseData<Q90ResponseEndpointsCollection<DynamicFilterSearchEndpointInterface>>>[] = [];
    observablesArray.push(this.fetchFilters());
    const combinedObservable$ = forkJoin([...observablesArray]);
    combinedObservable$.pipe(takeUntil(this.destroyed)).subscribe({
      next: (response) => {
        this.setQueryingPrimeableService(false);
        // Process filters data response
        const filtersData = response[0] as Q90ResponseData<Q90ResponseEndpointsCollection<DynamicFilterSearchEndpointInterface>>;
        const filtersObjs: DynamicFilterSearchEndpointInterface[] = filtersData.data.searchEndpoints.map((obj) => obj);
        this.filtersStore.next(filtersObjs);
      },
      error: (error) => {
        this.setQueryingPrimeableService(false);
        this.errorService.setError(error);
      },
    });
  }

  protected authenticated(): void { }
  protected refresh(): void { }
  protected logout(): void { }

  getFiltersForEndpoint(entitySearchEndpoint: string): DynamicFilter[] {
    const endpointFilters: DynamicFilter[] = [];
    this.filters$.pipe(
      map((filters) => {
        filters.forEach((elm) => {
          if (elm.endPointPath === entitySearchEndpoint) {
            elm.filters.forEach((filter) => {
              filter.filterPropertyName = this.fixControlName(
                filter.filterPropertyName
              );
              const filterObj = new DynamicFilter().deserialize(filter);
              endpointFilters.push(filterObj);
            });
          }
        });
      }),
      take(1)
    ).subscribe();
    return endpointFilters;
  }

  getDynamicFiltersModel(entitySearchEndpoint: string): DynamicFilterInterface {
    const filterControls: DynamicFilterInterface = {};
    const controls = this.getFiltersForEndpoint(entitySearchEndpoint);
    controls.forEach((filter) => {
      filterControls[filter.filterPropertyName] = {
        ids: [],
        matchAll: true,
      };
    });
    return filterControls;
  }

  private fixControlName(name: string) {
    return (name as string).charAt(0).toLowerCase() + (name as string).slice(1);
  }

  fetchFilters(): Observable<Q90ResponseData<Q90ResponseEndpointsCollection<DynamicFilterSearchEndpointInterface>>> {
    return this.http.get<Q90ResponseData<Q90ResponseEndpointsCollection<DynamicFilterSearchEndpointInterface>>>(
      `${environment.apiEndpointRoot}${environment.apiEndpointVersion}/general/metadata/search/filters`,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Bearer ' + this.authenticationStore.getToken()
        ),
        responseType: 'json',
      }
    )
      .pipe(
        map((res) => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  fetchFilterOptions(filter: DynamicFilter): any {
    return this.http.get<Q90ResponseDataCollection<DynamicFilterOptionInterface>>(
      `${environment.apiEndpointRoot + environment.apiEndpointVersion}${filter.endPointPath}`,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Bearer ' + this.authenticationStore.getToken()
        ),
        responseType: 'json',
      }
    )
      .pipe(
        map((res) => {
          if (!Q90Response.isSuccess(res)) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}
