import { PrimeableService } from '../../../core/services/primeable.service';
import { inject, Injectable } from '@angular/core';
import { CheckResults } from '../../../../api/models';
import { AiMonitorService } from '../../../../api/services';

export type KeyStringObj = { [key: string]: unknown };

export enum CheckStatus {
  Exists = 0,
  NotExists = 1,
  NotChecked = 2,
}

export interface MediaErrorListResult {
  name: string;
  errorList: unknown;
}

export interface MediaCheckResult {
  initialized?: boolean;
  missingFilesMonday: object;
  missingFilesSunday: object;
  missingFilesToday: object;
  missingFilesTomorrow: object;
}

export interface ScheduleCheckResult {
  includesNextDay: boolean;
  incudesWeekend: boolean;
  initialized: boolean;
  monitoringError: boolean;
  scheduleFileMonday: string;
  scheduleFileMondayValid: boolean;
  scheduleFileSunday: string;
  scheduleFileSundayValid: boolean;
  scheduleFileToday: string;
  scheduleFileTodayValid: boolean;
  scheduleFileTomorrow: string;
  scheduleFileTomorrowValid: boolean;
  scheduleFilesMondayStatus: number;
  scheduleFilesSundayStatus: number;
  scheduleFilesTodayStatus: number;
  scheduleFilesTomorrowStatus: number;
}

export interface ErrorCheckResult {
  acknowledge: boolean;
  breakNumber: number;
  spotFileName: string;
  spotNumber: number;
  startTime: string;
  statusCode: number;
}

@Injectable({
  providedIn: 'root',
})
export class AddInsertionMonitorService extends PrimeableService {
  aiMonitorService = inject(AiMonitorService);

  protected override prime(): void {}
  constructor() {
    super();
  }

  protected authenticated(): void {}
  protected refresh(): void {}
  protected logout(): void {}

  getErrorsFromResults(res: CheckResults) {
    const errors = [];
    for (const insertionEntry in res.verificationResults?.insertionStatus) {
      const insertionPointer =
        res.verificationResults.insertionStatus[insertionEntry];
      if (insertionPointer.statusCode != 1 && !insertionPointer.acknowledge) {
        errors.push(insertionPointer);
      }
    }
    return errors;
  }

  mediaValid(mediaCheckResults: MediaCheckResult | undefined) {
    // const missingFilesArr = {
    //   initialized: true,
    //   missingFilesMonday: { JT266906: 'JT266906.ts' },
    //   missingFilesSunday: { JT266906: 'JT266906.ts' },
    //   missingFilesToday: { JT266906: 'JT266906.ts' },
    //   missingFilesTomorrow: {
    //     JT266904: 'JT266904.ts',
    //     JT266905: 'JT266905.ts',
    //   },
    // };
    const missingFilesArr = mediaCheckResults;
    const errors: unknown[] = [];

    if (missingFilesArr) {
      Object.keys(missingFilesArr).forEach((elm) => {
        const key = elm;
        const val = (missingFilesArr as unknown as KeyStringObj)[elm];
        if (Object.keys(val as object).length > 0) errors.push({ [key]: val });
      });
    }
    return errors;
  }

  scheduleValid(scheduleCheckResult: ScheduleCheckResult): string[] {
    // scheduleCheckResult.scheduleFileTomorrowValid = false;
    // scheduleCheckResult.scheduleFileTodayValid = false;
    const errors: string[] = [];
    const checkFileStatus = (
      status: CheckStatus,
      description: string,
      isValid: boolean,
    ) => {
      if (status === CheckStatus.NotExists) {
        errors.push(`${description} does not exist`);
      }
      if (!isValid) {
        errors.push(`${description} is not valid`);
      }
    };

    // Check today's schedule
    checkFileStatus(
      scheduleCheckResult.scheduleFilesTodayStatus,
      "Today's schedule",
      scheduleCheckResult.scheduleFileTodayValid,
    );

    // Check tomorrow's schedule, if included
    if (scheduleCheckResult.includesNextDay) {
      checkFileStatus(
        scheduleCheckResult.scheduleFilesTomorrowStatus,
        "Tomorrow's schedule",
        scheduleCheckResult.scheduleFileTomorrowValid,
      );
    }

    // Check weekend schedule, if included
    if (scheduleCheckResult.incudesWeekend) {
      const weekendExists =
        scheduleCheckResult.scheduleFilesSundayStatus !==
          CheckStatus.NotExists &&
        scheduleCheckResult.scheduleFilesMondayStatus !== CheckStatus.NotExists;
      const weekendValid =
        scheduleCheckResult.scheduleFileSundayValid &&
        scheduleCheckResult.scheduleFileMondayValid;

      checkFileStatus(
        weekendExists ? CheckStatus.Exists : CheckStatus.NotExists,
        "Weekend's schedule",
        weekendValid,
      );
    }
    return errors;
  }

  acknowledgeAll() {
    this.aiMonitorService.aimonitorChannelsAcknowledgeAll();
  }

  acknowledge(channelName: string, breakNumber: number, spotNumber: number) {
    this.aiMonitorService
      .aimonitorChannelsAcknowledge({
        body: {
          breakNumber: breakNumber,
          channelName: channelName,
          spotNumber: spotNumber,
        },
      })
      .subscribe((res) => {
        return res;
      });
  }
}
