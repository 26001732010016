import { CommonModule } from "@angular/common";
import { Component, ChangeDetectionStrategy, OnInit, inject, Output, EventEmitter, ViewChild, ElementRef, Input } from "@angular/core";
import { ReactiveFormsModule, Validators } from "@angular/forms";
import { fromEvent, filter, debounceTime, distinctUntilChanged, tap, takeUntil } from "rxjs";
import { DestroyService } from "../../../../services/destroy.service";
import { FormControlComponent } from "../form-control.component";
import { GetAsFormControlPipe } from "../../../pipes/get-as-form-control.pipe";

@Component({
  selector: 'shared-form-search',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GetAsFormControlPipe
  ],
  templateUrl: './form-input-search.component.html',
  styleUrls: ['./form-input-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormInputSearchComponent extends FormControlComponent implements OnInit {

  private destroyed = inject(DestroyService);

  @Input() showSearchIconInInput: boolean = false;
  @Output() inputControlSearch = new EventEmitter<string>();
  @Output() inputControlClearSearch = new EventEmitter<Event>();

  @ViewChild('entitySearchElement') entitySearchElement!: ElementRef;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.control.addValidators([
      Validators.pattern(/^[a-zA-Z0-9@ _.-]+$/i),
    ]);
  }

  override getErrorMessage(): string {
    if (this.control.hasError('pattern')) {
      return 'Only alpha-numeric and the following characters are allowed: [@ _.-]';
    }
    return super.getErrorMessage();
  }

  ngAfterViewInit() {
    if (this.entitySearchElement && this.entitySearchElement.nativeElement) {
      fromEvent(this.entitySearchElement.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(150),
          distinctUntilChanged(),
          tap((v) => {
            const newSearch = this.entitySearchElement.nativeElement
              .value as string;
            this.inputControlSearch.emit(newSearch);
          }),
          takeUntil(this.destroyed)
        )
        .subscribe();
    }
  }

  clearSearchInput(e: Event): void {
    this.control.markAsPristine();
    this.control.patchValue('');
    this.inputControlClearSearch.emit(e);
  }
}
