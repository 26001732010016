<shared-modal
  *ngIf="createOpen"
  [withCloseButton]="false"
  (closeModal)="cookieDisclaimerClosed()">
  <section class="q90-section p-0">
    <header class="">
      <div class="grid-layout grid-cols-2 align-center spread q90-divider">
        <h4>Cookie disclaimer</h4>
        <div class="button-bar">
          <button
            type="button"
            (click)="cookieDisclaimerClosed()">
            deny
          </button>
          <button
            class="action"
            type="button"
            (click)="cookieDisclaimerAgreed($event)">
            accept
          </button>
        </div>
      </div>
    </header>
    <p class="p-1">We use cookies disclaimer text goes here. Do you accept?</p>
  </section>
</shared-modal>
