import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, filter, map, take, takeUntil } from 'rxjs';
import { DestroyService } from '../../shared/services/destroy.service';
import { environment } from '../../../environments/environment';

export enum ApplicationEvent {
  Prime = 'PRIME',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  RefreshToken = 'REFRESHTOKEN',
  UserAccountUpdate = 'USERACCOUNTUPDATE',
  Idle = 'IDLE',
}

export enum LocalStorageKeys {
  CookieDisclaimer = 'cookieDisclaimer',
  Theme = 'theme',
  NavPos = 'navPos',
  CurrentTenant = 'currentTenant',
  CurrentXperienceEnvironment = 'currentXperienceEnvironment',
  CurrentXperienceBrand = 'currentXperienceBrand',
}

@Injectable({
  providedIn: 'root',
})
export class AppService {

  private destroyed = inject(DestroyService);

  // Store for (last) Application Event
  private applicationEventStore = new BehaviorSubject<string>(ApplicationEvent.Idle);
  applicationEvent$: Observable<string> = this.applicationEventStore.asObservable();

  // Is the application refreshing the user token?
  private refreshingTokenStore = new BehaviorSubject<boolean>(false);
  refreshingToken$: Observable<boolean> = this.refreshingTokenStore.asObservable();

  // Is a primeable service somewhere priming?
  private primeablePrimingStore = new BehaviorSubject<boolean>(false);
  primeablePriming$: Observable<boolean> = this.primeablePrimingStore.asObservable();

  private unsavedFormsStore = new BehaviorSubject<string[]>([]);
  unsavedForms$: Observable<string[]> = this.unsavedFormsStore.asObservable();

  private unsavedFormsWarningStore = new BehaviorSubject<boolean>(false);
  unsavedFormsWarning$: Observable<boolean> = this.unsavedFormsWarningStore.asObservable();

  // The last page url in the router
  private lastPageUrlStore = new BehaviorSubject<string>('');
  lastPageUrl$: Observable<string> = this.lastPageUrlStore.asObservable();

  constructor() {
    // Listen for Application Events...
    this.applicationEvent$
      .pipe(
        filter((event) => event !== ApplicationEvent.Idle),
        takeUntil(this.destroyed)
      )
      .subscribe((setting) => {
        this.debug(`${this.constructor.name}: application event: ${setting}`);
        if (setting === ApplicationEvent.Logout) {
          // this.closeAllMenuGroups();
        }
        if (setting === ApplicationEvent.Login) {
          // this.closeAllMenuGroups();
        }
      });
  }

  /**
   * Tests an array of boolean Observables for at least one true value.
   * 
   * Method is named getAppLoadingStatus because it combines two observables from
   * this service which broadcast
   * 1) when token is being refreshed and 
   * 2) when a PrimeableService is priming.
   * 
   * You can add other boolean based Observables to array to be tested.
   * 
   * @param obs Observable<boolean>[]
   * @returns Observable<boolean>
   */
  getAppLoadingStatus(obs: Observable<boolean>[] = []): Observable<boolean> {
    return combineLatest([
      this.primeablePriming$,
      this.refreshingToken$,
      ...obs,
    ]).pipe(
      map(
        (values) => {
          const test = values.find(v => v === true);
          return ((test === true) ? true : false);
        }
      )
    );
  }

  setApplicationEvent(action: ApplicationEvent) {
    this.applicationEventStore.next(action);
  }

  getRefreshingTokenStore(): BehaviorSubject<boolean> {
    return this.refreshingTokenStore;
  }

  setRefreshingToken(setting: boolean): void {
    this.refreshingTokenStore.next(setting);
  }

  setPrimeablePriming(setting: boolean): void {
    this.primeablePrimingStore.next(setting);
  }

  setUnsavedForm(setting: string[]): void {
    this.unsavedFormsStore.next(setting);
  }

  setUnsavedFormsWarning(setting: boolean): void {
    this.unsavedFormsWarningStore.next(setting);
  }

  debug(msg: any, level = 1) {
    if (environment.debug) {
      console.log(msg);
    }
  }

  addUnsavedForm(formId: string): void {
    this.unsavedForms$.pipe(
      take(1)
    ).subscribe(
      (unsavedForms) => {
        const final: string[] = [...new Set([...unsavedForms, formId])];
        this.unsavedFormsStore.next(final);
      }
    );
  }

  removeUnsavedForm(formId: string): void {
    this.unsavedForms$.pipe(
      take(1)
    ).subscribe(
      (unsavedForms) => {
        const filtered = (unsavedForms as string[]).filter(fId => fId !== formId);
        const final: string[] = [...new Set(filtered)];
        this.unsavedFormsStore.next(final);
      }
    );
  }

  isFormUnsaved(formId: string): boolean {
    let result: boolean = false;
    this.unsavedForms$.pipe(
      take(1)
    ).subscribe(
      (unsavedForms) => {
        result = unsavedForms.includes(formId);
      }
    );
    return result;
  }


}
