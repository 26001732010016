<ng-container
  *ngIf="
    navigationSecondarySubnavData$ | async as navigationSecondarySubnavData
  "
>
  <core-navigation-sidebar-content [snapshot]="snapshot">
    <nav>
      <ul [ngClass]="navigationSecondarySubnavData.theme.class">
        <li
          *ngIf="navigationSecondarySubnavData.userAccountLoggedIn"
          class="breadcrumb"
        >
          <a [routerLink]="'/my-groups'">
            <span class="mci-arrowhead-left"></span>
            <span class="text">My Groups</span>
          </a>
        </li>
        <li class="entity-label">
          <span> {{ currentTeamIdStore.getTeamName() }} </span>(team)
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button type="button" (click)="toggle(appMenus.TeamsGeneralMenu)">
            <span class="nav-icon mci-setting-solid"></span>
            <span class="feature-label">General</span>
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.TeamsGeneralMenu
                ]
              "
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.TeamsGeneralMenu
                ]
              "
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul
            *ngIf="
              navigationSecondarySubnavData.appMenusState[
                appMenus.TeamsGeneralMenu
              ]
            "
          >
            <li>
              <a
                [routerLink]="[baseRoute, 'members']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Members
              </a>
            </li>
            <!-- <li>
              <a
                [routerLink]="[baseRoute, 'invites']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Invites
              </a>
            </li> -->
          </ul>
        </li>
      </ul>
    </nav>
  </core-navigation-sidebar-content>
</ng-container>
