<ng-container
  *ngIf="
    navigationSecondarySubnavData$ | async as navigationSecondarySubnavData
  "
>
  <core-navigation-sidebar-content [snapshot]="snapshot">
    <nav>
      <ul [ngClass]="navigationSecondarySubnavData.theme.class">
        <li
          *ngIf="navigationSecondarySubnavData.userAccountLoggedIn"
          class="breadcrumb"
        >
          <a [routerLink]="'/my-groups'">
            <span class="mci-arrowhead-left"></span>
            <span class="text">My Groups</span>
          </a>
        </li>
        <li class="entity-label">
          <span>
            {{ currentContentChannelIdStore.getContentChannelName() }} </span
          ><br />(content channel)
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button
            type="button"
            (click)="toggle(appMenus.ContentChannelGeneralMenu)"
          >
            <span class="nav-icon mci-setting-solid"></span>
            <span class="feature-label">General</span>
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.ContentChannelGeneralMenu
                ]
              "
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.ContentChannelGeneralMenu
                ]
              "
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul
            *ngIf="
              navigationSecondarySubnavData.appMenusState[
                appMenus.ContentChannelGeneralMenu
              ]
            "
          >
            <li>
              <a
                [routerLink]="[baseRoute, 'information']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Information
              </a>
            </li>
            <li>
              <a
                [routerLink]="[baseRoute, 'style']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Colors
              </a>
            </li>
            <li>
              <a
                [routerLink]="[baseRoute, 'contact']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Contact
              </a>
            </li>
            <!-- <li>
              <a
                [routerLink]="[baseRoute, 'social']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Social media
              </a>
            </li> -->
            <!-- <li>
              <a
                [routerLink]="[baseRoute, 'teams']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Teams
              </a>
            </li> -->
            <li>
              <a
                [routerLink]="[baseRoute, 'languages']"
                [routerLinkActive]="'is-active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                Languages
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </core-navigation-sidebar-content>
</ng-container>
