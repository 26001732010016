<div class="grid-layout grid-cols-1 fill-screen-height">
  <section class="q90-section p-0">
    <header
      class="grid-layout grid-cols-2 spread left-heavy align-center q90-divider"
    >
      <h4>Ad Monitor</h4>

      <app-server-clock />
    </header>
    <div class="scrollable-content p-1">
      <div class="card-container">
        <a
          *ngFor="let channel of channels()"
          [routerLink]="['/admonitor/', channel]"
        >
          <app-channel-card [channelName]="channel" />
        </a>
      </div>
    </div>
  </section>
</div>
