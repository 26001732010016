import { Deserializable } from "../../../shared/interfaces/deserializable";
import { OptionCollection } from "../../../shared/interfaces/option-collection";

export interface TenantTypeInterface {
  id: string;
  name: string;
}

export type TenantTypeKeys = keyof TenantTypeInterface;

export class TenantType implements Deserializable, OptionCollection {
  constructor(private _id: string = '', private _name: string = '') { }

  get optionName() {
    return this.name;
  }
  get optionValue() {
    return this.id;
  }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }

  deserialize(input: TenantTypeInterface): this {
    Object.assign(this, input);
    return this;
  }
}
