import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, catchError } from 'rxjs';
import { Q90ResponseData } from '../shared/interfaces/q90-response';
import { AuthenticatedUserInterface } from './models/authenticated-user';
import { inject } from '@angular/core';
import { AppService } from '../core/services/app.service';
import { environment } from '../../environments/environment';

export function refresh(
  token: string,
  refreshToken: string
): Observable<Q90ResponseData<AuthenticatedUserInterface>> {
  const appService = inject(AppService);
  const http = inject(HttpClient);
  appService.setRefreshingToken(true);
  return http
    .post<Q90ResponseData<AuthenticatedUserInterface>>(
      environment.apiEndpointRoot +
      environment.apiEndpointVersion +
      '/authentication/refreshtoken',
      {
        token: token,
        refreshToken: refreshToken,
      },
      {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + token),
        responseType: 'json',
      }
    )
    .pipe(
      map((res) => {
        appService.setRefreshingToken(false);
        return res;
      }),
      catchError((err) => {
        appService.setRefreshingToken(false);
        throw err;
      })
    );
}
