import { Directive, Input, inject, ViewContainerRef, ComponentRef, OnChanges } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { ErrorService } from '../../shared/services/error.service';
import { HomeNavigationComponent } from '../components/navigation/navigation-sidebar/home-navigation/home-navigation.component';
import { NavigationSidebarContentComponent } from '../components/navigation/navigation-sidebar/navigation-sidebar-content/navigation-sidebar-content.component';
import { TenantOverviewSubnavComponent } from '../components/navigation/navigation-sidebar/tenant-overview-subnav/tenant-overview-subnav.component';
import { XperienceModulesSubnavComponent } from '../components/navigation/navigation-sidebar/xperience-modules-subnav/xperience-modules-subnav.component';
import { ContentChannelOverviewSubnavComponent } from '../components/navigation/navigation-sidebar/content-channel-overview-subnav/content-channel-overview-subnav.component';
import { TeamOverviewSubnavComponent } from '../components/navigation/navigation-sidebar/team-overview-subnav/team-overview-subnav.component';

@Directive({
  selector: '[navigationSidebar]',
  standalone: true,
})
export class NavigationSidebarDirective implements OnChanges {
  @Input() snapshot!: RouterStateSnapshot | null;

  private errorService = inject(ErrorService);
  public viewContainerRef = inject(ViewContainerRef);

  ngOnChanges(): void {
    if (this.snapshot) this.loadComponent();
  }

  loadComponent() {
    const viewContainerRef = this.viewContainerRef;
    viewContainerRef.clear();

    let componentRef!: ComponentRef<NavigationSidebarContentComponent>;

    if (this.snapshot && this.snapshot.url.search(new RegExp(/^\/xperience\//)) > -1) {
      componentRef = viewContainerRef.createComponent<NavigationSidebarContentComponent>(
        XperienceModulesSubnavComponent,
      );
    } else if (this.snapshot && this.snapshot.url.search(new RegExp(/^\/organization/)) > -1) {
      componentRef = viewContainerRef.createComponent<NavigationSidebarContentComponent>(
        TenantOverviewSubnavComponent,
      );
    } else if (this.snapshot && this.snapshot.url.search(new RegExp(/^\/contentchannel/)) > -1) {
      componentRef = viewContainerRef.createComponent<NavigationSidebarContentComponent>(
        ContentChannelOverviewSubnavComponent,
      );
    } else if (this.snapshot && this.snapshot.url.search(new RegExp(/^\/team/)) > -1) {
      componentRef = viewContainerRef.createComponent<NavigationSidebarContentComponent>(
        TeamOverviewSubnavComponent,
      );
    } else {
      componentRef =
        viewContainerRef.createComponent<NavigationSidebarContentComponent>(
          HomeNavigationComponent,
        );
    }

    if (componentRef instanceof ComponentRef) {
      componentRef.setInput('snapshot', this.snapshot as RouterStateSnapshot | null);
    } else {
      this.errorService.setError(
        new Error('Unable to create navigation for the current route: ' + this.snapshot?.url),
      );
    }
  }
}
