export const EntitiesList = [
  'TenantUsers',
  'TeamUsers',
  'ChannelTeamsUsers',
  'ProjectTeamsUsers',
  'RemoteEnvironmentUsers',
  'SecurityRole'
]

export type EntitiesListKeys = keyof typeof EntitiesList;
// export type EntitiesList = [key: UserLevelsKeys];

export const RightsListInterface = {
  Owner: 1,
  Developer: 2,
  Reporter: 3,
  LearningManager: 4
}

export type RightsListKeys = keyof typeof RightsListInterface;
export type RightsList = Record<RightsListKeys, string>

export const UserLevelsList = [
  'None',
  'User',
  'Admin',
  'SuperAdmin',
  'Maintainer'
]

export type UserLevelsKeys = keyof typeof UserLevelsList;
// export type UserLevel = [key: UserLevelsKeys];

export class Authorization {

}

