import { Deserializable } from 'src/app/shared/interfaces/deserializable';
import {
  ContentChannel,
  ContentChannelInterface,
} from '../../content-channel/models/content-channel';
import { Project, ProjectInterface } from '../../project/models/project';
import { OptionCollection } from '../../../shared/interfaces/option-collection';
import {
  XperienceItemColumnModel,
  XperienceItemColumnType,
} from '../../../xperience/models/xperience-models';
import { Q90EntityBase } from '../../models/q90-entity-base';

export interface TeamInterface {
  id: string;
  name: string;
  teamContentChannels: ContentChannelInterface[];
  teamProjects: ProjectInterface[];
  tenantId: string;
}

export type TeamKeys = keyof TeamInterface;

export const TeamAdminColumns: XperienceItemColumnModel<TeamKeys>[] = [
  {
    column: 'name',
    orderBy: true,
    searchBy: true,
    friendly: 'Name',
    columnClass: XperienceItemColumnType.TextColumn,
  },
];

export class Team
  implements Deserializable, OptionCollection {
  constructor(
    private _id: string = '',
    private _name: string = '',
    private _tenantId: string = '',
    private _teamContentChannels: ContentChannel[] = [],
    private _teamProjects: Project[] = []
  ) {
  }

  get optionName() {
    return this.name;
  }
  get optionValue() {
    return this.id;
  }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }
  get tenantId() {
    return this._tenantId;
  }
  set tenantId(tenantId) {
    this._tenantId = tenantId;
  }
  get teamContentChannels() {
    return this._teamContentChannels;
  }
  set teamContentChannels(teamContentChannels) {
    this._teamContentChannels = teamContentChannels;
  }
  get teamProjects() {
    return this._teamProjects;
  }
  set teamProjects(teamProjects) {
    this._teamProjects = teamProjects;
  }

  deserialize(input: TeamInterface): this {
    Object.assign(this, input);
    return this;
  }
}
