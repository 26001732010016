<shared-modal
  [customWidth]="'1500'"
  (closeModal)="modalContent = []"
  *ngIf="modalContent.length > 0"
>
  <section class="q90-section">
    <div
      *ngIf="modalContent && modalContent.length > 0; else emptyTextfile"
      class="textfile-entries"
    >
      <div class="text-output">
        <span
          *ngFor="let elm of setElements(modalContent)"
          class="elm"
          [innerHTML]="elm"
        >
        </span>
      </div>
    </div>
    <ng-template #emptyTextfile>
      <tbody>
        <tr>
          <td>No content found in file.</td>
        </tr>
      </tbody>
    </ng-template>
  </section>
</shared-modal>
