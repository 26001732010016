import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { UserAccountStoreService } from '../../../../account/stores/user-account-store.service';
import {
  AppMenusService,
  MasterMenus,
} from '../../../services/app-menus.service';
import { AppRoutingService } from '../../../services/app-routing.service';
import { AppThemesService } from '../../../services/app-themes.service';
import { NavigationSidebarDirective } from '../../../directives/navigation-sidebar.directive';

interface NavigationHeaderData {
  currentRoute: RouterStateSnapshot | null;
  userAccountTenantMenuState: boolean;
}

@Component({
  selector: 'core-navigation-sidebar',
  imports: [CommonModule, NavigationSidebarDirective],
  templateUrl: './navigation-sidebar.component.html',
  styleUrls: ['./navigation-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationSidebarComponent {
  navigationHeaderData$!: Observable<NavigationHeaderData>;

  protected userAccountStore = inject(UserAccountStoreService);
  public appRoutingService = inject(AppRoutingService);
  protected applicationThemesService = inject(AppThemesService);
  protected appMenusService = inject(AppMenusService);

  ngOnInit(): void {
    this.navigationHeaderData$ = combineLatest([
      this.appRoutingService.currentRoute$,
      this.appMenusService.appMenusState$.pipe(
        map((menusState) => menusState[MasterMenus.UserAccountTenantsDropdown]),
      ),
    ]).pipe(
      map(([currentRoute, userAccountTenantMenuState]) => {
        return {
          currentRoute,
          userAccountTenantMenuState,
        };
      }),
    );
  }
}
