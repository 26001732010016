import { LanguageInterface, Language } from '../../../core/models/language';
import {
  SocialMediaTypeInterface,
  SocialMediaType,
} from '../../../entity-secondary/entity-social-media/models/social-media-type';
import { PrimeNgDate } from '../../../shared/forms/decorators/prime-ng-date.decorator';
import { Deserializable } from '../../../shared/interfaces/deserializable';
import { OptionCollection } from '../../../shared/interfaces/option-collection';
import { Q90EntityBase } from '../../models/q90-entity-base';

export interface PersonInterface {
  id: string;
  personId: string;
  avatar: string;
  avatarThumbnail: string;
  phoneNumbers: any[];
  socialMedia: SocialMediaTypeInterface[];
  businessDepartments: any[];
  addresses: any[];
  languages: LanguageInterface[];
  teamLeadId: string;
  genderId: string;
  tenantId: string;
  contentChannelId: string;
  name: string;
  middleName: string;
  lastName: string;
  familyNamePrePosition: string;
  dateOfBirth: string;
  pronounsId: string;
  about: string;
  newsletter: number;
  createdByTenantId: string;
  createdByContentChannelId: string;
}

export type PersonKeys = keyof PersonInterface;

export class Person
  implements Deserializable, OptionCollection {
  @PrimeNgDate()
  private _dateOfBirth: string = '';

  constructor(
    private _id: string = '',
    private _personId: string = '',
    private _avatar: string = '',
    private _avatarThumbnail: string = '',
    private _phoneNumbers: any[] = [],
    private _socialMedia: SocialMediaType[] = [],
    private _businessDepartments: any[] = [],
    private _addresses: any[] = [],
    private _languages: Language[] = [],
    private _teamLeadId: string = '',
    private _genderId: string = '',
    private _tenantId: string = '',
    private _contentChannelId: string = '',
    private _name: string = '',
    private _middleName: string = '',
    private _lastName: string = '',
    private _familyNamePrePosition: string = '',
    // private _dateOfBirth: string = '',
    private _pronounsId: string = '',
    private _about: string = '',
    private _newsletter: number = 0,
    private _createdByTenantId: string = '',
    private _createdByContentChannelId: string = ''
  ) {
  }

  get optionName() {
    return this.name;
  }
  get optionValue() {
    return this.id;
  }

  get id() {
    return this._personId;
  }
  set id(id) {
    this._personId = id;
  }
  get personId() {
    return this._personId;
  }
  set personId(personId) {
    this._personId = personId;
  }
  get avatar() {
    return this._avatar;
  }
  set avatar(avatar) {
    this._avatar = avatar;
  }
  get avatarThumbnail() {
    return this._avatarThumbnail;
  }
  set avatarThumbnail(avatarThumbnail) {
    this._avatarThumbnail = avatarThumbnail;
  }
  get phoneNumbers() {
    return this._phoneNumbers;
  }
  set phoneNumbers(phoneNumbers) {
    this._phoneNumbers = phoneNumbers;
  }
  get socialMedia() {
    return this._socialMedia;
  }
  set socialMedia(socialMedia) {
    this._socialMedia = socialMedia;
  }
  get businessDepartments() {
    return this._businessDepartments;
  }
  set businessDepartments(businessDepartments) {
    this._businessDepartments = businessDepartments;
  }
  get addresses() {
    return this._addresses;
  }
  set addresses(addresses) {
    this._addresses = addresses;
  }
  get languages() {
    return this._languages;
  }
  set languages(languages) {
    this._languages = languages;
  }
  get tenantId() {
    return this._tenantId;
  }
  set tenantId(tenantId) {
    this._tenantId = tenantId;
  }
  get contentChannelId() {
    return this._contentChannelId;
  }
  set contentChannelId(contentChannelId) {
    this._contentChannelId = contentChannelId;
  }
  get teamLeadId() {
    return this._teamLeadId;
  }
  set teamLeadId(teamLeadId) {
    this._teamLeadId = teamLeadId;
  }
  get genderId() {
    return this._genderId;
  }
  set genderId(genderId) {
    this._genderId = genderId;
  }
  get pronounsId() {
    return this._pronounsId;
  }
  set pronounsId(pronounsId) {
    this._pronounsId = pronounsId;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }
  get middleName() {
    return this._middleName;
  }
  set middleName(middleName) {
    this._middleName = middleName;
  }
  get lastName() {
    return this._lastName;
  }
  set lastName(lastName) {
    this._lastName = lastName;
  }
  get familyNamePrePosition() {
    return this._familyNamePrePosition;
  }
  set familyNamePrePosition(familyNamePrePosition) {
    this._familyNamePrePosition = familyNamePrePosition;
  }
  get dateOfBirth() {
    return this._dateOfBirth;
  }
  set dateOfBirth(dateOfBirth) {
    this._dateOfBirth = dateOfBirth;
  }
  get about() {
    return this._about;
  }
  set about(about) {
    this._about = about;
  }
  get newsletter() {
    return this._newsletter;
  }
  set newsletter(newsletter) {
    this._newsletter = newsletter;
  }
  get createdByTenantId() {
    return this._createdByTenantId;
  }
  set createdByTenantId(createdByTenantId) {
    this._createdByTenantId = createdByTenantId;
  }
  get createdByContentChannelId() {
    return this._createdByContentChannelId;
  }
  set createdByContentChannelId(createdByContentChannelId) {
    this._createdByContentChannelId = createdByContentChannelId;
  }

  deserialize(input: PersonInterface): this {
    Object.assign(this, input);
    return this;
  }
}
