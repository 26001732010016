import { OptionCollection } from '../../shared/interfaces/option-collection';
import { Deserializable } from '../../shared/interfaces/deserializable';

export interface GenderInterface {
  id: string;
  name: string;
}

export type GenderKeys = keyof GenderInterface;

export class Gender implements Deserializable, OptionCollection {
  constructor(private _id: string = '', private _name: string = '') {}

  get optionName(): string {
    return this.name;
  }
  get optionValue(): string {
    return this.id;
  }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }

  deserialize(input: GenderInterface): this {
    Object.assign(this, input);
    return this;
  }
}
