import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Q90ErrorResponseTypes } from '../interfaces/q90-response';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private router = inject(Router);

  private errorStore = new BehaviorSubject<Q90ErrorResponseTypes | null>(null);
  error$: Observable<Q90ErrorResponseTypes | null> = this.errorStore.asObservable();

  setError(err: Q90ErrorResponseTypes) {
    this.errorStore.next(err);
    this.router.navigate(['error']);
  }
}
