import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  inject,
  WritableSignal,
  signal,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import {
  AddInsertionMonitorService,
  MediaCheckResult,
  ScheduleCheckResult,
} from '../../services/ad-insertion-monitor.service';
import { AiMonitorService } from '../../../../../api/services';
import { CheckResults } from '../../../../../api/models';
import { takeUntil } from 'rxjs';
import { DestroyService } from '../../../../shared/services/destroy.service';

@Component({
  selector: 'app-channel-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './channel-card.component.html',
  styleUrls: ['./channel-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelCardComponent implements OnInit, OnChanges, OnDestroy {
  private addInsertionMonitorService = inject(AddInsertionMonitorService);
  aiMonitorService = inject(AiMonitorService);

  @Input() channelName: string = '';
  @Input() shouldUpdate: boolean = false;
  @Output() updated = new EventEmitter<CheckResults>();
  private interval: number = 10;
  errorCount: WritableSignal<number> = signal(0);
  mediaErrors: WritableSignal<string[]> = signal([]);
  scheduleErrors: WritableSignal<string[]> = signal([]);
  channelCardData: WritableSignal<CheckResults | null> = signal(null);
  protected destroyed = inject(DestroyService);

  polInterval = setInterval(() => {}, this.interval * 1000);

  constructor() {}

  ngOnInit(): void {
    this.getStatus();
    this.polInterval = setInterval(() => {
      this.getStatus();
    }, this.interval * 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['shouldUpdate'] &&
      changes['shouldUpdate'].currentValue === true
    )
      this.getStatus();
  }

  ngOnDestroy(): void {
    clearInterval(this.polInterval);
  }

  getStatus() {
    this.aiMonitorService
      .aimonitorChannelsCheckresults$Json({
        body: { channel: this.channelName },
      })
      .pipe(takeUntil(this.destroyed))
      .subscribe((res) => {
        this.updated.emit(res.data);
        this.channelCardData.set(res.data);
        const errorCount = this.addInsertionMonitorService.getErrorsFromResults(
          res.data,
        ).length;
        this.errorCount.set(errorCount);
        this.setMediaErrors(res.data?.mediaCheckResults as MediaCheckResult);
        this.setScheduleErrors(res.data?.sheduleResults as ScheduleCheckResult);
      });
  }

  setMediaErrors(results: MediaCheckResult) {
    const errorList: string[] = [];
    const mediaErrors = this.addInsertionMonitorService.mediaValid(results);
    mediaErrors.forEach((elm, i) => {
      const day =
        (i !== 0 ? ', ' : '') +
        Object.keys(elm as object)[0].replace(/missingFiles/g, '');
      errorList.push(day);
    });
    this.mediaErrors.set(errorList);
  }
  setScheduleErrors(results: ScheduleCheckResult) {
    const errorList = this.addInsertionMonitorService.scheduleValid(results);
    this.scheduleErrors.set(errorList);
  }
}
