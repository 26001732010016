<ng-container
  *ngIf="
    navigationSecondarySubnavData$ | async as navigationSecondarySubnavData
  "
>
  <core-navigation-sidebar-content [snapshot]="snapshot">
    <nav [ngClass]="navigationSecondarySubnavData.theme.class">
      <ul>
        <li
          *ngIf="navigationSecondarySubnavData.userAccountLoggedIn"
          class="breadcrumb"
        >
          <a [routerLink]="'/'">
            <span class="mci-arrowhead-left"></span>
            <span class="text">Back</span>
          </a>
        </li>
        <!-- <li>username</li> -->
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button
            type="button"
            (click)="toggle(appMenus.UserAccountMyAccountMenu)"
          >
            <span class="nav-icon mci-user-single-solid"></span>
            <span class="feature-label">My Account</span>
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.UserAccountMyAccountMenu
                ]
              "
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.UserAccountMyAccountMenu
                ]
              "
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul
            *ngIf="
              navigationSecondarySubnavData.appMenusState[
                appMenus.UserAccountMyAccountMenu
              ]
            "
          >
            <li>
              <a
                [routerLink]="['/account/profile']"
                [routerLinkActive]="'is-active'"
                >Profile</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/account/twofactor/enable']"
                [routerLinkActive]="'is-active'"
                >Two Factor Authentication</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/account/password/change']"
                [routerLinkActive]="'is-active'"
                >Change Password</a
              >
            </li>
          </ul>
        </li>
        <li *ngIf="navigationSecondarySubnavData.userAccountLoggedIn">
          <button
            type="button"
            (click)="toggle(appMenus.UserAccountSettingsMenu)"
          >
            <span class="nav-icon mci-setting-solid"></span>
            <span class="feature-label">Settings</span>
            <span
              *ngIf="
                navigationSecondarySubnavData.appMenusState[
                  appMenus.UserAccountSettingsMenu
                ]
              "
              class="mci-arrowhead-up-solid"
            ></span>
            <span
              *ngIf="
                !navigationSecondarySubnavData.appMenusState[
                  appMenus.UserAccountSettingsMenu
                ]
              "
              class="mci-arrowhead-down-solid"
            ></span>
          </button>
          <ul
            *ngIf="
              navigationSecondarySubnavData.appMenusState[
                appMenus.UserAccountSettingsMenu
              ]
            "
          >
            <li>
              <a
                [routerLink]="['/account/settings/preferences']"
                [routerLinkActive]="'is-active'"
                >Preferences</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </core-navigation-sidebar-content>
</ng-container>
