import { CommonModule } from "@angular/common";
import { Component, ChangeDetectionStrategy, inject } from "@angular/core";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { Observable, combineLatest, map } from "rxjs";
import { ThemeSwitchComponent } from "../../../core/components/theme-switch/theme-switch.component";
import { UserAccountComponent } from "../../../core/components/user-account/user-account.component";
import { MasterMenus, AppMenusService } from "../../../core/services/app-menus.service";
import { AppThemesService } from "../../../core/services/app-themes.service";
import { AppService } from "../../../core/services/app.service";
import { Person } from "../../../entity/person/models/person";
import { UserAccount } from "../../../entity/user/models/user-account";
import { UserAccountStoreService } from "../../stores/user-account-store.service";
import { Theme } from "../../../core/models/theme";
import { AvatarComponent } from "../../../core/components/avatar/avatar.component";
import { AccountAvatarComponent } from "../avatar/avatar.component";


interface UserAccountMenuData {
  userAccountLoggedIn: boolean;
  authenticatedUserAccount: UserAccount | null;
  isAdmin: boolean;
  authenticatedPerson: Person | null;
  refreshingToken: boolean;
  theme: Theme;
  userAccountMenuState: boolean;
}

@Component({
  selector: 'account-user-account-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    UserAccountComponent,
    ThemeSwitchComponent,
    AccountAvatarComponent
  ],
  templateUrl: './user-account-menu.component.html',
  styleUrls: ['./user-account-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAccountMenuComponent {

  appMenus = MasterMenus;

  userAccountMenuData$!: Observable<UserAccountMenuData>;

  private appService = inject(AppService);
  private appThemesService = inject(AppThemesService);
  private appMenusService = inject(AppMenusService);
  private userAccountStore = inject(UserAccountStoreService);

  ngOnInit(): void {
    this.userAccountMenuData$ = combineLatest([
      this.userAccountStore.userAccountLoggedIn$,
      this.userAccountStore.authenticatedUserAccountStore.entity$,
      this.userAccountStore.isAdmin(),
      this.userAccountStore.authenticatedPersonStore.entity$,
      this.appService.refreshingToken$,
      this.appThemesService.theme$,
      this.appMenusService.appMenusState$.pipe(map(menuState => menuState[MasterMenus.UserAccountDropdown])),
    ]).pipe(
      map(
        ([
          userAccountLoggedIn,
          authenticatedUserAccountEntity,
          isAdmin,
          authenticatedPersonEntity,
          refreshingToken,
          theme,
          userAccountMenuState,
        ]) => {
          const authenticatedUserAccount = authenticatedUserAccountEntity as UserAccount;
          const authenticatedPerson = authenticatedPersonEntity as Person;
          return {
            userAccountLoggedIn,
            authenticatedUserAccount,
            isAdmin,
            authenticatedPerson,
            refreshingToken,
            theme,
            userAccountMenuState,
          };
        }
      )
    );
  }

  toggle(appMenu: string): void {
    this.appMenusService.toggle(appMenu);
  }

  setUserAccountMenuState(): void {
    this.appMenusService.toggle(MasterMenus.UserAccountDropdown);
  }
}
