import { Deserializable } from '../../shared/interfaces/deserializable';
import { Q90ResponseMessageInterface } from '../../shared/interfaces/q90-response';

export interface AuthenticatedUserInterface {
  id: number;
  username: string;
  level: number;
  previousLogin: string;
  token: string;
  tokenExpiry: string;
  refreshToken: string;
  refreshTokenExpiry: string;
  state: number;
}

export interface TwofactorSetupInterface {
  token: string;
  tokenExpiry: string;
  refreshToken: string;
  refreshTokenExpiry: string;
  state: number;
  qrImage: string;
  manualEntryKey: string;
  recoveryCodes: string;
}

export type AuthenticatedUserKeys = keyof AuthenticatedUser;

export class AuthenticatedUser implements Deserializable {
  private _authenticated = false;
  private _awaitingTwoFactorAuthentication = false;
  private _twoFactorSetupRequired = false;
  private _newPasswordRequired = false;
  private _loginSuccessful = false;

  private messages: Q90ResponseMessageInterface[] = [];

  constructor(
    private _id: number = 0,
    private _username: string = '',
    private _level: number = 0,
    private _previousLogin: string = '',
    private _token: string = '',
    private _tokenExpiry: string = '',
    private _refreshToken: string = '',
    private _refreshTokenExpiry: string = '',
    private _state: number = 0
  ) { }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get username() {
    return this._username;
  }
  set username(username) {
    this._username = username;
  }
  get level() {
    return this._level;
  }
  set level(level) {
    this._level = level;
  }
  get previousLogin() {
    return this._previousLogin;
  }
  set previousLogin(previousLogin) {
    this._previousLogin = previousLogin;
  }
  get token() {
    return this._token;
  }
  set token(token) {
    this._token = token;
  }
  get tokenExpiry() {
    return this._tokenExpiry;
  }
  set tokenExpiry(tokenExpiry) {
    this._tokenExpiry = tokenExpiry;
  }
  get refreshToken() {
    return this._refreshToken;
  }
  set refreshToken(refreshToken) {
    this._refreshToken = refreshToken;
  }
  get refreshTokenExpiry() {
    return this._refreshTokenExpiry;
  }
  set refreshTokenExpiry(refreshTokenExpiry) {
    this._refreshTokenExpiry = refreshTokenExpiry;
  }
  get state() {
    return this._state;
  }
  set state(state) {
    this._state = state;
  }

  get isAuthenticated() {
    return this._authenticated;
  }

  get awaitingTwoFactorAuthentication() {
    return this._awaitingTwoFactorAuthentication;
  }

  get twoFactorSetupRequired() {
    return this._twoFactorSetupRequired;
  }

  get newPasswordRequired() {
    return this._newPasswordRequired;
  }

  get loginSuccessful() {
    return this._loginSuccessful;
  }

  setMessages(messages: Q90ResponseMessageInterface[]) {
    this.messages = messages;
    this._authenticated = this.setAuthenticated();
    this._awaitingTwoFactorAuthentication =
      this.isAwaitingTwoFactorAuthentication();
    this._newPasswordRequired = this.isNewPasswordRequired();
    this._twoFactorSetupRequired = this.isTwoFactorSetupRequired();
    this._loginSuccessful = this.isLoginStepSuccessful();
    // this.clearMessages();
  }

  // private clearMessages() {
  //   this.messages = [];
  // }

  private setAuthenticated(): boolean {
    return (
      this.isLoginStepSuccessful() &&
      !this.isAwaitingTwoFactorAuthentication() &&
      !this.isNewPasswordRequired() &&
      !this.isTwoFactorSetupRequired() &&
      !this.isTokenExpired()
    );
  }

  private isLoginStepSuccessful(): boolean {
    let result = false;
    this.messages.forEach((message) => {
      if (message.code === 200) {
        result = true;
      }
    });
    return result;
  }

  private isAwaitingTwoFactorAuthentication(): boolean {
    let result = false;
    this.messages.forEach((message) => {
      if (+message.code === 1300) {
        result = true;
      }
    });
    return result;
  }

  private isNewPasswordRequired(): boolean {
    let result = false;
    this.messages.forEach((message) => {
      if (+message.code === 1301) {
        result = true;
      }
    });
    return result;
  }

  private isTwoFactorSetupRequired(): boolean {
    let result = false;
    this.messages.forEach((message) => {
      if (+message.code === 1302) {
        result = true;
      }
    });
    return result;
  }

  private isTokenExpired(): boolean {
    let isTokenExpired = true;
    const now = new Date();
    const tokenDate = new Date(this.tokenExpiry);
    if (now.getTime() < tokenDate.getTime()) {
      isTokenExpired = false;
    }
    return isTokenExpired;
  }

  deserialize(input: AuthenticatedUserInterface): this {
    Object.assign(this, input);
    return this;
  }
}
