import {
  ChangeDetectionStrategy,
  Component,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { CrossComponent } from "../svg/cross/cross.component";

export enum EModalSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  full = 'full',
}

@Component({
  selector: 'shared-modal',
  imports: [CommonModule, CrossComponent],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms', style({opacity: 1})),
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('300ms', style({opacity: 0})),
      ]),
    ]),
  ],
  host: {
    '(window:keyup.escape)': 'closeModalOnClick($event)'
  }
})
export class ModalComponent {
  size: InputSignal<EModalSize> = input<EModalSize>(EModalSize.medium);
  title: InputSignal<string> = input.required<string>();
  withCloseButton: InputSignal<boolean> = input<boolean>(true);
  closeModal: OutputEmitterRef<boolean> = output<boolean>();

  closing: boolean = false;

  closeModalOnClick(event: Event) {
    this.closing = true;
    setTimeout(() => {
      event.preventDefault();
      this.closeModal.emit(this.closing);
    }, 200);
  }
}
