import { Routes } from '@angular/router';
import { ErrorComponent } from './shared/components/error/error.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { authenticatedGuard } from './authentication/guards/authenticated.guard';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { NoSidebarLayoutComponent } from './layouts/no-sidebar-layout/no-sidebar-layout.component';
import { SignOutComponent } from './account/components/sign-out/sign-out.component';
import { environment } from '../environments/environment';
import { moduleGuard } from './guards/module.guard';

export const MainRoutes: Routes = [
  {
    path: environment.use_mygroups ? 'team' : 'page-not-found',
    loadChildren: () =>
      environment.use_mygroups
        ? import('./pages/team/team-routing').then((mod) => mod.TeamRoutes)
        : [],
  },
  {
    path: environment.use_mygroups ? 'organization' : 'page-not-found',
    loadChildren: () =>
      environment.use_mygroups
        ? import('./pages/tenant/tenant-routing').then((mod) => mod.TenantRoutes)
        : [],
  },
  // {
  //   path: '',
  //   component: MainLayoutComponent,
  //   children: [
  //     {
  //       path: environment.use_mygroups ? 'member' : 'page-not-found',
  //       loadChildren: () =>
  //         import('./entity/user/user.routing').then(
  //           (mod) => mod.UserAccountRoutes,
  //         ),
  //     },
  //     {
  //       path: environment.use_mygroups ? 'person' : 'page-not-found',
  //       loadChildren: () =>
  //         import('./entity/person/person.routing').then(
  //           (mod) => mod.PersonRoutes,
  //         ),
  //     },
  //     {
  //       path: environment.use_mygroups ? 'contentchannel' : 'page-not-found',
  //       loadChildren: () =>
  //         import('./pages/content-channel/content-channel-routing').then(
  //           (mod) => mod.ContentChannelRoutes,
  //         ),
  //     },
  //     {
  //       path: 'project',
  //       loadChildren: () =>
  //         import('./entity/project/project.routing').then(
  //           (mod) => mod.ProjectRoutes,
  //         ),
  //     },
  //     // {
  //     //   path: 'admin',
  //     //   loadChildren: () =>
  //     //     import('./admin/admin-routing').then((mod) => mod.AdminRoutes),
  //     // },
  //   ],
  // },

  // in use routes
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [authenticatedGuard],
    children: [
      { path: '', component: HomeComponent },
      {
        path: 'dashboard',
        canActivate: [moduleGuard],
        loadChildren: () =>
          environment.use_dashboard
            ? import('./pages/dashboard/dashboard-routing').then((mod) => mod.DashboardRoutes)
            : [],
      },
      {
        path: 'monitor',
        canActivate: [moduleGuard],
        loadChildren: () =>
          environment.use_monitor
            ? import('./pages/monitor/monitor-routing').then((mod) => mod.MonitorRoutes)
            : [],
      },
      {
        path: 'my-groups',
        canActivate: [moduleGuard],
        loadChildren: () =>
          environment.use_mygroups
            ? import('./pages/my-groups/my-groups-routing').then((mod) => mod.MyGroupsRoutes)
            : [],
      },
      {
        path: 'xperience',
        canActivate: [moduleGuard],
        loadChildren: () =>
          import('./xperience/xperience.routing').then((mod) => mod.XperienceRoutes),
      },
      {
        path: 'help',
        loadChildren: () => import('./pages/help/help-routing').then((mod) => mod.HelpRoutes),
      },
      {
        path: 'media',
        loadChildren: () => import('./pages/mam/mam-routing').then((mod) => mod.MamRoutes),
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'sign-in',
        loadChildren: () =>
          import('./authentication/authentication-routing').then((mod) => mod.AuthenticationRoutes),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./pages/account/account-routing').then((mod) => mod.AccountRoutes),
      },
    ],
  },
  {
    path: '',
    component: NoSidebarLayoutComponent,
    children: [
      { path: 'sign-out', pathMatch: 'full', component: SignOutComponent },
      { path: 'page-not-found', component: PageNotFoundComponent },
      { path: 'error', component: ErrorComponent },
      { path: '**', redirectTo: 'page-not-found' },
    ],
  },
];
