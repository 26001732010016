import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, map, catchError, take } from 'rxjs';
import { ApplicationEvent } from '../../core/services/app.service';
import { Q90ResponseData } from '../../shared/interfaces/q90-response';
import {
  AuthenticatedUser,
  AuthenticatedUserInterface,
} from '../models/authenticated-user';
import { refresh } from '../authentication-refresh';
import { PrimeableService } from '../../core/services/primeable.service';
import { FrontendCookieStorageService } from '../../core/services/storage/frontend-cookie-storage.service';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends PrimeableService {
  cookieStorageService = inject(FrontendCookieStorageService);

  private authenticatingStore = new BehaviorSubject<boolean>(false);
  authenticating$: Observable<boolean> =
    this.authenticatingStore.asObservable();

  private isRefreshing = false;

  constructor() {
    super();
    this.userAccountStore.userAccountLoggedIn$
      .pipe(
        take(1)
        // filter(authenticatedUser => !(authenticatedUser instanceof AuthenticatedUser) || !(authenticatedUser.isAuthenticated))
      )
      .subscribe((userAccountLoggedIn) => {
        if (!userAccountLoggedIn) this.loginFromFrontendStorage();
      });
  }

  setAuthenticating(setting: boolean): void {
    this.authenticatingStore.next(setting);
  }

  protected prime(): void { }

  protected authenticated(): void { }

  protected refresh(): void { }

  protected logout() {
    this.authenticationStore.clearStore();
    this.authenticationStore.setAuthenticatedUser(null);
  }

  login(
    username: string,
    password: string
  ): Observable<Q90ResponseData<AuthenticatedUserInterface>> {
    return this.http
      .post<Q90ResponseData<AuthenticatedUserInterface>>(
        environment.apiEndpointRoot +
        environment.apiEndpointVersion +
        '/authentication/authenticate',
        {
          username: username,
          password: password,
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  twoFactor(
    pinCode: string
  ): Observable<Q90ResponseData<AuthenticatedUserInterface>> {
    return this.http
      .post<Q90ResponseData<AuthenticatedUserInterface>>(
        environment.apiEndpointRoot +
        environment.apiEndpointVersion +
        '/authentication/twofactor/authenticate',
        {
          pinCode: pinCode,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
          // withCredentials: true
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  twoFactorRecovery(
    pinCode: string
  ): Observable<Q90ResponseData<AuthenticatedUserInterface>> {
    return this.http
      .post<Q90ResponseData<AuthenticatedUserInterface>>(
        environment.apiEndpointRoot +
        environment.apiEndpointVersion +
        '/authentication/twofactor/authenticate/recovery',
        {
          pinCode: pinCode,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  private loginFromFrontendStorage() {
    this.appService.debug('Looking in frontend storage...');
    const token = this.cookieStorageService.getItem('token');
    const refreshToken = this.cookieStorageService.getItem('refreshToken');
    // const tokenExpiry = localStorage.getItem('tokenExpiry');
    // const refreshTokenExpiry = localStorage.getItem('refreshTokenExpiry');
    // if (token && refreshToken && tokenExpiry && refreshTokenExpiry) {
    if (token && refreshToken && !this.isRefreshing) {
      refresh(token, refreshToken)
        .pipe(take(1))
        .subscribe({
          next: (response: Q90ResponseData<AuthenticatedUserInterface>) => {
            this.isRefreshing = false;
            this.appService.setRefreshingToken(false);
            const authenticatedUser = new AuthenticatedUser().deserialize(
              response.data
            );
            authenticatedUser.setMessages(response.resultMessages);
            this.authenticationStore.setAuthenticatedUser(authenticatedUser);
            this.appService.setApplicationEvent(ApplicationEvent.RefreshToken);
          },
          error: (error) => {
            this.isRefreshing = false;
            this.errorService.setError(error);
          },
        });
    }
  }
}
