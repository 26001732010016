import { Injectable } from '@angular/core';
import { EntityDetailStoreService } from './entity-detail-store.service';
import { take, filter } from 'rxjs';
import { UserAccountInterface, UserAccount } from '../user/models/user-account';
import { Q90Response } from '../../shared/models/q90-response';
import { Q90ResponseData } from '../../shared/interfaces/q90-response';
import { Q90EntityInterface } from '../models/q90-entity-base';

@Injectable({
  providedIn: null,
})
export class UserAccountDetailStoreService extends EntityDetailStoreService {
  protected entitySearchEndpoint = '';

  constructor() {
    super();
  }

  updateStore(data: Partial<UserAccountInterface>): void {
    this.entity$
      .pipe(
        take(1),
        filter((entity) => entity !== null)
      )
      .subscribe((entity) => {
        const userAccount = entity as UserAccount;
        const newUserAccount = this.updateEntity<UserAccount, UserAccountInterface>(userAccount, data);
        this.setStore(newUserAccount);
      });
  }

  override loadEntity(id: string): void {
    this.loadingStore.next(true);
    this.getById(id)
      .pipe(take(1))
      .subscribe({
        next: (response: Q90ResponseData<Q90EntityInterface>) => {
          this.loadingStore.next(false);
          if (response.statusCode === 200) {
            // const tenantData = response as Q90ResponseData<Q90EntitySearchResult>;
            const tenantObj: UserAccount = new UserAccount(id).deserialize(
              <UserAccountInterface>response.data
            );
            this.entityStore.next(tenantObj);
          } else {
            this.errorMessagesStore.setErrorMessage(
              new Q90Response().deserialize(response)
            );
          }
        },
        error: (error) => {
          this.loadingStore.next(false);
          this.errorService.setError(new Error('Unable to get user account.'));
        },
        complete: () => {
          this.loadingStore.next(false);
        },
      });
  }
}
