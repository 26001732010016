import {
  ChangeDetectionStrategy,
  Component,
  input,
  InputSignal,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../../components/spinner/spinner.component';

@Component({
  selector: 'shared-form-submit',
  imports: [
    CommonModule,
    SpinnerComponent
  ],
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitComponent implements OnChanges {

  pristine: InputSignal<boolean> = input<boolean>(true);
  submitting: InputSignal<boolean> = input<boolean>(false);
  buttonLabel: InputSignal<string> = input<string>('save');
  buttonClass: InputSignal<string> = input<string>('');

  buttonClassFinal: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pristine']?.currentValue === false || this.submitting()) {
      this.buttonClassFinal = 'action';
    } else {
      this.buttonClassFinal = this.buttonClass();
    }
  }
}
