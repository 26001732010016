import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AppService,
  ApplicationEvent,
} from '../../../core/services/app.service';

@Component({
  selector: 'account-sign-out',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignOutComponent {
  private appService = inject(AppService);

  ngOnInit(): void {
    this.appService.setApplicationEvent(ApplicationEvent.Logout);
  }
}
