import { PipeTransform } from "@angular/core";
import { OptionCollection } from "../../shared/interfaces/option-collection";
import { XperienceApikey, XperienceApikeyInterface } from "../modules/apikeys/models/xperience-apikey";
import { XperienceApikeysService } from "../modules/apikeys/services/xperience-apikeys.service";
import { XperienceBanner, XperienceBannerInterface } from "../modules/banners/models/xperience-banner";
import { XperienceBannerService } from "../modules/banners/services/xperience-banner.service";
import { XperienceBrand, XperienceBrandInterface } from "../modules/brands/models/xperience-brand";
import { XperienceBrandsService } from "../modules/brands/services/xperience-brands.service";
import { XperienceContenttemplate, XperienceContenttemplateInterface } from "../modules/contenttemplates/models/xperience-contenttemplate";
import { XperienceContenttemplatesService } from "../modules/contenttemplates/services/xperience-contenttemplates.service";
import { XperienceCountry, XperienceCountryInterface } from "../modules/countries/models/xperience-country";
import { XperienceCountriesService } from "../modules/countries/services/xperience-countries.service";
import { XperienceEnvironment, XperienceEnvironmentInterface } from "../modules/environments/models/xperience-environment";
import { EnvironmentService } from "../modules/environments/services/environment.service";
import { XperienceFaq, XperienceFaqInterface } from "../modules/faqs/models/xperience-faq";
import { XperienceFaqCategory, XperienceFaqCategoryInterface } from "../modules/faqs/models/xperience-faq-category";
import { XperienceFaqsService } from "../modules/faqs/services/xperience-faqs.service";
import { XperienceLanguage, XperienceLanguageInterface } from "../modules/languages/models/xperience-language";
import { XperienceLanguagesService } from "../modules/languages/services/xperience-languages.service";
import { XperiencePaymentmethod, XperiencePaymentmethodInterface } from "../modules/paymentmethods/models/xperience-paymentmethod";
import { XperiencePaymentmethodsService } from "../modules/paymentmethods/services/xperience-paymentmethods.service";
import { XperienceSetting, XperienceSettingInterface } from "../modules/settings/models/xperience-setting";
import { XperienceSettingsService } from "../modules/settings/services/xperience-settings.service";
import { XperienceSpecialOffer, XperienceSpecialOfferInterface } from "../modules/subscriptions-offers/models/xperience-special-offer";
import { XperienceSubscription, XperienceSubscriptionInterface } from "../modules/subscriptions-offers/models/xperience-subscription";
import { XperienceSubscriptionUsp, XperienceSubscriptionUspInterface } from "../modules/subscriptions-offers/models/xperience-subscription-usp";
import { XperienceSpecialOffersService } from "../modules/subscriptions-offers/services/xperience-special-offers.service";
import { XperienceSubscriptionsService } from "../modules/subscriptions-offers/services/xperience-subscriptions.service";

export interface XperienceItemColumnModel<T> {
  column: T;
  columnClass: string;
  orderBy: boolean;
  searchBy: boolean;
  friendly: string;
  pipe?: PipeTransform;
  orderByColumns?: OptionCollection[]
}

export enum XperienceItemColumnType {
  TextColumn = 'text-column',
  NumberColumn = 'number-column',
  ColorColumn = 'color-column',
  BoolColumn = 'bool-column',
}

export type XperienceEntity =
  | XperienceEnvironment
  | XperienceFaq
  | XperienceBrand
  | XperienceFaqCategory
  | XperienceSetting
  | XperienceLanguage
  | XperienceContenttemplate
  | XperienceSubscription
  | XperienceSubscriptionUsp
  | XperienceSpecialOffer
  | XperiencePaymentmethod
  | XperienceApikey
  | XperienceCountry
  | XperienceBanner

export type XperienceEntityInterface =
  | XperienceEnvironmentInterface
  | XperienceFaqInterface
  | XperienceBrandInterface
  | XperienceFaqCategoryInterface
  | XperienceSettingInterface
  | XperienceLanguageInterface
  | XperienceContenttemplateInterface
  | XperienceSubscriptionInterface
  | XperienceSubscriptionUspInterface
  | XperienceSpecialOfferInterface
  | XperiencePaymentmethodInterface
  | XperienceApikeyInterface
  | XperienceCountryInterface
  | XperienceBannerInterface

export type XperienceEntityService =
  | EnvironmentService
  | XperienceFaqsService
  | XperienceBrandsService
  | XperienceSettingsService
  | XperienceLanguagesService
  | XperienceContenttemplatesService
  | XperienceSubscriptionsService
  | XperienceSpecialOffersService
  | XperiencePaymentmethodsService
  | XperienceApikeysService
  | XperienceCountriesService
  | XperienceBannerService
