import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { FrontendLocalStorageService } from '../../services/storage/frontend-local-storage.service';
import { LocalStorageKeys } from '../../services/app.service';

@Component({
  selector: 'core-cookie-disclaimer',
  standalone: true,
  imports: [CommonModule, ModalComponent],
  templateUrl: './cookie-disclaimer.component.html',
  styleUrls: ['./cookie-disclaimer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieDisclaimerComponent {
  localStorageService = inject(FrontendLocalStorageService);

  public createOpen = true;

  cookieDisclaimerClosed() {
    this.createOpen = false;
    this.localStorageService.setItem(LocalStorageKeys.CookieDisclaimer, '0');
  }

  cookieDisclaimerAgreed(e: Event) {
    this.createOpen = false;
    this.localStorageService.setItem(LocalStorageKeys.CookieDisclaimer, '1');
  }
}
