import { Injectable, inject } from '@angular/core';
import {
  AuthenticatedUser,
  TwofactorSetupInterface,
} from '../models/authenticated-user';
import { FrontendCookieStorageService } from '../../core/services/storage/frontend-cookie-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationStoreService {
  cookieStorageService = inject(FrontendCookieStorageService);

  constructor() {}

  setAuthenticatedUser(authenticatedUser: AuthenticatedUser | null): void {
    // Don't write auth info to local storage unless user is authenticated
    // if (authenticatedUser && authenticatedUser.isAuthenticated) {
    if (authenticatedUser) {
      this.setFrontendStorage(authenticatedUser);
    }
  }

  getToken(): string {
    const ls = localStorage.getItem('t');
    const co = this.cookieStorageService.getItem('token')!;
    return ls ? ls : co;
  }

  getTokenExpiry(): string {
    return this.cookieStorageService.getItem('tokenExpiry')!;
  }

  getRefreshToken(): string {
    const ls = localStorage.getItem('rt');
    const co = this.cookieStorageService.getItem('refreshToken')!;
    return ls ? ls : co;
  }

  updateAuthenticatedUserToken(data: TwofactorSetupInterface) {
    localStorage.setItem('t', data.token);
    localStorage.setItem('rt', data.refreshToken);
    this.cookieStorageService.setItem('token', data.token);
    this.cookieStorageService.setItem('tokenExpiry', data.tokenExpiry, true);
    this.cookieStorageService.setItem('refreshToken', data.refreshToken);
    this.cookieStorageService.setItem(
      'refreshTokenExpiry',
      data.refreshTokenExpiry,
      true,
    );
  }

  private setFrontendStorage(authenticatedUser: AuthenticatedUser) {
    if (authenticatedUser.token) {
      this.cookieStorageService.setItem('token', authenticatedUser.token);
      localStorage.setItem('t', authenticatedUser.token);
    }
    if (authenticatedUser.tokenExpiry) {
      this.cookieStorageService.setItem(
        'tokenExpiry',
        authenticatedUser.tokenExpiry,
        true,
      );
    }
    if (authenticatedUser.refreshToken) {
      this.cookieStorageService.setItem(
        'refreshToken',
        authenticatedUser.refreshToken,
      );
      localStorage.setItem('rt', authenticatedUser.refreshToken);
    }
    if (authenticatedUser.refreshTokenExpiry) {
      this.cookieStorageService.setItem(
        'refreshTokenExpiry',
        authenticatedUser.refreshTokenExpiry,
        true,
      );
    }
    if (authenticatedUser.level) {
      const level: string = authenticatedUser.level as any;
      this.cookieStorageService.setItem('level', level);
    }
  }

  clearStore(): void {
    this.cookieStorageService.removeItem('token');
    localStorage.setItem('t', '');
    this.cookieStorageService.removeItem('tokenExpiry');
    this.cookieStorageService.removeItem('refreshToken');
    localStorage.setItem('rt', '');
    this.cookieStorageService.removeItem('refreshTokenExpiry');
    this.cookieStorageService.removeItem('level');
  }
}
