import { ChangeDetectionStrategy, Component, inject, OnInit, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EModalSize, ModalComponent } from '../../../shared/components/modal/modal.component';
import { AppService } from '../../services/app.service';
import { combineLatest, map, Observable } from 'rxjs';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';

interface RefreshingTokenData {
  refreshingToken: boolean;
}

@Component({
    selector: 'core-refreshing-token',
    imports: [CommonModule, ModalComponent, SpinnerComponent],
    templateUrl: './refreshing-token.component.html',
    styleUrls: ['./refreshing-token.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefreshingTokenComponent implements OnInit {
  private appService = inject(AppService);

  refreshingTokenData$!: Observable<RefreshingTokenData>;

  public createOpen = true;

  ngOnInit(): void {
    this.refreshingTokenData$ = combineLatest([
      this.appService.refreshingToken$,
    ]).pipe(
      map(([refreshingToken]) => {
        return {
          refreshingToken
        };
      })
    );
  }

  cookieDisclaimerClosed() {
    this.createOpen = false;
  }

  cookieDisclaimerAgreed(e: Event) {
    this.createOpen = false;
  }

  protected readonly EModalSize = EModalSize;
}
