import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, map, takeUntil, take } from 'rxjs';
import { Tenant } from '../../entity/tenant/models/tenant';
import { LocalStorageKeys } from './app.service';
import { PrimeableService } from './primeable.service';
import { FrontendLocalStorageService } from './storage/frontend-local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentTenantService extends PrimeableService {
  private localStorageService = inject(FrontendLocalStorageService);

  private currentTenantStore = new BehaviorSubject<Tenant | null>(null);
  currentTenant$: Observable<Tenant | null> = this.currentTenantStore.asObservable();

  private lastCurrentTenantStore = new BehaviorSubject<Tenant | null>(null);
  lastCurrentTenant$: Observable<Tenant | null> = this.lastCurrentTenantStore.asObservable();

  constructor() {
    super();

    this.userAccountStore.userAccountMyTenants$
      .pipe(
        map((myTenants) => {
          const current = this.localStorageService.getItem(
            LocalStorageKeys.CurrentTenant
          );
          if (myTenants.some((e: Tenant) => e.id === current)) {
            const currentTenant = myTenants.find(
              (tenant) => tenant.id === current
            );
            this.setCurrentTenant(currentTenant as Tenant);
          }
          return {
            myTenants,
          };
        }),
        takeUntil(this.destroyed)
      )
      .subscribe();
  }

  protected override prime(): void { }
  protected override authenticated(): void { }
  protected override refresh(): void { }
  protected override logout(): void {
    this.clearCurrentTenant();
  }

  setCurrentTenant(tenant: Tenant | null): void {
    this.currentTenant$.pipe(
      take(1)
    ).subscribe((currentTenant) => {
      this.lastCurrentTenantStore.next(currentTenant);
      this.currentTenantStore.next(tenant);
      if (tenant instanceof Tenant) {
        this.setLocalStorage(tenant.id);
      }
    });
  }

  setCurrentTenantById(tenantId: string): void {
    this.userAccountStore.userAccountMyTenants$.pipe(
      take(1)
    ).subscribe(
      (tenants) => {
        const chosenTenant = tenants.find(tenant => tenant.id === tenantId);
        if (chosenTenant) {
          this.setCurrentTenant(chosenTenant);
        }
      }
    );
  }

  clearCurrentTenant(): void {
    this.currentTenant$.pipe(
      take(1)
    ).subscribe((currentTenant) => {
      this.lastCurrentTenantStore.next(currentTenant);
      this.localStorageService.removeItem(LocalStorageKeys.CurrentTenant);
      this.currentTenantStore.next(null);
    });
  }

  private setLocalStorage(val: string | null) {
    this.localStorageService.setItem(
      LocalStorageKeys.CurrentTenant,
      val as string
    );
  }
}
