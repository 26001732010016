import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, filter, map, take } from 'rxjs';
import { ContentChannel } from '../../entity/content-channel/models/content-channel';
import { PersonDetailStoreService } from '../../entity/stores/person-detail-store.service';
import { UserAccountDetailStoreService } from '../../entity/stores/user-account-detail-store.service';
import { Team } from '../../entity/team/models/team';
import { Tenant } from '../../entity/tenant/models/tenant';
import { UserAccount } from '../../entity/user/models/user-account';
import { GeneralStoreService } from '../../core/stores/general-store.service';

@Injectable({
  providedIn: 'root',
})
export class UserAccountStoreService {
  private generalStore = inject(GeneralStoreService);

  // Store for authenticated user account object. Note: this is not the same as the object
  // which tracks the user's authentication state, which is in the AuthenticationStoreService
  authenticatedUserAccountStore: UserAccountDetailStoreService;

  // Store for related Person object
  authenticatedPersonStore: PersonDetailStoreService;

  private userAccountMyTenantsStore = new BehaviorSubject<Tenant[]>([]);
  userAccountMyTenants$: Observable<Tenant[]> =
    this.userAccountMyTenantsStore.asObservable();

  private userAccountMyContentChannelsStore = new BehaviorSubject<
    ContentChannel[]
  >([]);
  userAccountMyContentChannels$: Observable<ContentChannel[]> =
    this.userAccountMyContentChannelsStore.asObservable();

  private userAccountMyTeamsStore = new BehaviorSubject<Team[]>([]);
  userAccountMyTeams$: Observable<Team[]> =
    this.userAccountMyTeamsStore.asObservable();

  private userAccountLoggedInStore = new BehaviorSubject<boolean>(false);
  userAccountLoggedIn$: Observable<boolean> =
    this.userAccountLoggedInStore.asObservable();

  constructor() {
    this.authenticatedUserAccountStore = new UserAccountDetailStoreService();
    this.authenticatedPersonStore = new PersonDetailStoreService();
  }

  isAuthenticated(): boolean {
    let result: boolean = false;
    this.userAccountLoggedIn$.pipe(take(1)).subscribe((isLoggedIn) => {
      result = isLoggedIn;
    });
    return result;
  }

  isAdmin(): Observable<boolean> {
    return this.authenticatedUserAccountStore.entity$.pipe(
      map(
        (userAccount) =>
          userAccount instanceof UserAccount && userAccount.level >= 4,
      ),
    );
  }

  getCurrentLanguageCode(): Observable<string> {
    return this.generalStore.currentLocale$.pipe(take(1));
  }

  hasTwoFactorEnabled(): boolean {
    let result: boolean = false;
    this.authenticatedUserAccountStore.entity$
      .pipe(
        filter((userAccount) => userAccount instanceof UserAccount),
        take(1),
      )
      .subscribe((userAccount) => {
        const boo = userAccount as UserAccount;
        result = boo.twoFactorAuthEnabled;
      });
    return result;
  }

  setUserAccountLoggedIn(setting: boolean): void {
    this.userAccountLoggedInStore.next(setting);
  }
  setUserAccountMyTenants(tenants: Tenant[]): void {
    this.userAccountMyTenantsStore.next(tenants);
  }
  setUserAccountMyContentChannels(contentChannels: ContentChannel[]): void {
    this.userAccountMyContentChannelsStore.next(contentChannels);
  }
  setUserAccountMyTeams(teams: Team[]): void {
    this.userAccountMyTeamsStore.next(teams);
  }
  clearStore(): void {
    this.authenticatedPersonStore.setStore(null);
    this.authenticatedUserAccountStore.setStore(null);
    this.setUserAccountLoggedIn(false);
    this.setUserAccountMyTenants([]);
    this.setUserAccountMyContentChannels([]);
    this.setUserAccountMyTeams([]);
  }
}
