<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="20" viewBox="0 0 48 20">
  <defs>
    <linearGradient id="linear-gradient" x2="1" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#84d11f" />
      <stop offset="1" stop-color="#467b00" />
    </linearGradient>
  </defs>
  <g id="Group_3192" data-name="Group 3192" transform="translate(7074.209 13648.395)">
    <path id="Path_465" data-name="Path 465" d="M23.939,19.925a7.421,7.421,0,0,1-5.264-2.166A7.321,7.321,0,0,1,16.5,12.53V7.392a7.33,7.33,0,0,1,2.18-5.229,7.485,7.485,0,0,1,10.528,0,7.329,7.329,0,0,1,2.178,5.229V12.53A7.311,7.311,0,0,1,29.2,17.759,7.407,7.407,0,0,1,23.939,19.925Zm0-15.416a2.9,2.9,0,0,0-2.9,2.884V12.53a2.9,2.9,0,0,0,5.806,0V7.392A2.9,2.9,0,0,0,23.939,4.508ZM7.441,19.925a2.261,2.261,0,0,1-1.967-3.383l1.044-1.793a7.384,7.384,0,0,1-4.339-2.11,7.36,7.36,0,0,1,0-10.457,7.475,7.475,0,0,1,10.525,0,7.362,7.362,0,0,1,.973,9.271L9.41,18.8A2.285,2.285,0,0,1,7.441,19.925Zm0-15.4a2.884,2.884,0,1,0,2.4,4.5L10,8.755a.673.673,0,0,1,.038-.065l.008-.012a2.831,2.831,0,0,0,.3-1.267A2.9,2.9,0,0,0,7.444,4.526Z" transform="translate(-7057.59 -13648.395)" fill="url(#linear-gradient)" />
    <path id="Path_468" data-name="Path 468" d="M11.043,19.346l-.224-.221a7.494,7.494,0,0,1-8.64-1.366A7.321,7.321,0,0,1,0,12.531V7.392A7.331,7.331,0,0,1,2.18,2.164a7.486,7.486,0,0,1,10.528,0,7.33,7.33,0,0,1,2.178,5.229v5.138a7.317,7.317,0,0,1-.855,3.449l.21.208a2.214,2.214,0,0,1,0,3.158,2.281,2.281,0,0,1-3.2,0ZM4.543,7.392v5.138a2.9,2.9,0,0,0,5.806,0V7.392a2.9,2.9,0,0,0-5.806,0Z" transform="translate(-7074.209 -13648.395)" fill="#fff" />
  </g>
</svg>
