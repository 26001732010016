import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { FrontendLocalStorageService } from '../../services/storage/frontend-local-storage.service';
import { LocalStorageKeys } from '../../services/app.service';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter } from 'rxjs';

@Component({
    selector: 'core-cookie-disclaimer',
    imports: [CommonModule, ModalComponent, RouterLink],
    templateUrl: './cookie-disclaimer.component.html',
    styleUrls: ['./cookie-disclaimer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieDisclaimerComponent implements OnInit {
  localStorageService = inject(FrontendLocalStorageService);
  showCookieModal: WritableSignal<boolean> = signal(false);
  router = inject(Router);

  ngOnInit() {
    this.checkCookies(this.router.url);

    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((val) => {
        this.checkCookies(val.url);
      });
  }

  cookieDisclaimerClosed() {
    this.showCookieModal.set(false);
    this.localStorageService.setItem(LocalStorageKeys.CookieDisclaimer, '0');
  }

  cookieDisclaimerAgreed() {
    this.showCookieModal.set(false);
    this.localStorageService.setItem(LocalStorageKeys.CookieDisclaimer, '1');
  }

  checkCookies(url: string) {
    setTimeout(() => {
      const isCookiePage = url === '/help/cookie-policy';
      const cookiesAccepted =
        this.localStorageService.getItem(LocalStorageKeys.CookieDisclaimer)! ===
        '1';
      this.showCookieModal.set(isCookiePage ? false : !cookiesAccepted);
    }, 100);
  }
}
