import { Validators } from "@angular/forms";
import { IRadioGroupCollection, OptionCollection } from "../../shared/interfaces/option-collection";

export enum EInputType {
  text = "text",
  select = "select",
  textarea = "textarea", /*@todo*/
  checkbox = "checkbox",
  radio = "radio", /*@todo*/
  date = "date",
  daterange = "daterange", /*@todo*/
  datetime = "datetime", /*@todo*/
  tag = "tag",
  container = "container",
  divider = "divider",
  title = "title",
}

export const validatorMap = {
  'required': Validators.required,
}

export enum ERules {
  required = "required",
}

export type TFormSchema = Record<string, IFormSchema>;

export interface IPreset {
  name: string;
  color: string;
  isFavorite: boolean;
  schema: TFormSchema
}
export interface IFormSchema {
  type: EInputType;
  label?: string;
  allowCreate?: boolean;
  content?: string;
  prefixIcon?: string;
  value?: string | string[] | boolean;
  className?: string;
  options?: string[] | OptionCollection[] | IRadioGroupCollection[];
  placeholder?: string;
  schema?: TFormSchema;
  canRemoveContainer?: boolean;
  rules?: ERules[]
}

export interface IFormData {
  class?: string
  schema: TFormSchema,
}
