import { OptionCollection } from 'src/app/shared/interfaces/option-collection';
import { Deserializable } from 'src/app/shared/interfaces/deserializable';

export interface SocialMediaTypeInterface {
  id: string;
  socialMediaTypeId: string;
  contentChannelId: string;
  name: string;
  url: string;
}

export interface SocialMediaTypeSearchInterface {
  search: string;
  pageSize: number;
  page: number;
  orderDirection: number;
}

export const defaultSocialmediaTypeSearchQuery: SocialMediaTypeSearchInterface =
  {
    search: '',
    pageSize: 9,
    page: 0,
    orderDirection: 0,
  };

export type SocialMediaTypeKeys = keyof SocialMediaTypeInterface;

export class SocialMediaType implements Deserializable, OptionCollection {
  constructor(
    private _id: string = '',
    private _socialMediaTypeId: string = '',
    private _contentChannelId: string = '',
    private _name: string = '',
    private _url: string = ''
  ) {}

  get optionName(): string {
    return this.name;
  }
  get optionValue(): string {
    return this.id;
  }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get socialMediaTypeId() {
    return this._socialMediaTypeId;
  }
  set socialMediaTypeId(socialMediaTypeId) {
    this._socialMediaTypeId = socialMediaTypeId;
  }
  get contentChannelId() {
    return this._contentChannelId;
  }
  set contentChannelId(contentChannelId) {
    this._contentChannelId = contentChannelId;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }
  get url() {
    return this._url;
  }
  set url(url) {
    this._url = url;
  }

  deserialize(input: SocialMediaTypeInterface): this {
    Object.assign(this, input);
    return this;
  }
}
