import { Directive, ElementRef, inject, output, OutputEmitterRef } from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class ClickOutsideDirective {
  appClickOutside: OutputEmitterRef<unknown> = output();
  private _elementRef: ElementRef = inject<ElementRef>(ElementRef);

  public onClick(event: MouseEvent) {
    const isClickedInside = this._elementRef.nativeElement.contains(event.target);
    if (!isClickedInside) {
      this.appClickOutside.emit(event);
    }
  }
}
