<ng-container *ngIf="refreshingTokenData$ | async as refreshingTokenData">
  <shared-modal *ngIf="refreshingTokenData.refreshingToken" [withCloseButton]="false">
    <div class="q90-section centered">
      <div class="text-center">
        <p class="mb-1">One moment, please. Checking credentials...</p>
        <shared-spinner [styleClass]="'spinner-green-lg'"></shared-spinner>
      </div>
    </div>
  </shared-modal>
</ng-container>
