import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  inject,
  WritableSignal,
  signal,
} from '@angular/core';
import {
  AddInsertionMonitorService,
  ErrorCheckResult,
  KeyStringObj,
  MediaCheckResult,
  MediaErrorListResult,
  ScheduleCheckResult,
} from '../../services/ad-insertion-monitor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AiMonitorService } from '../../../../../api/services';
import { CheckResults } from '../../../../../api/models';
import { ChannelCardComponent } from '../../components/channel-card/channel-card.component';
import { DataTableComponent } from '../../components/data-table/data-table.component';
import { ScheduleModalComponent } from '../../components/schedule-modal/schedule-modal.component';
import { ServerClockComponent } from '../../components/server-clock/server-clock.component';
import { UserAccountStoreService } from '../../../../account/stores/user-account-store.service';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-channel-detail-page',
  standalone: true,
  imports: [
    CommonModule,
    ChannelCardComponent,
    DataTableComponent,
    ScheduleModalComponent,
    ServerClockComponent,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './channel-detail-page.component.html',
  styleUrls: ['./channel-detail-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelDetailPageComponent implements OnInit {
  private addInsertionMonitorService = inject(AddInsertionMonitorService);
  private userAccountStore = inject(UserAccountStoreService);
  private router = inject(Router);
  aiMonitorService = inject(AiMonitorService);
  private route = inject(ActivatedRoute);

  selectedErrors: ErrorCheckResult[] = [];
  selectedMediaErrors: MediaErrorListResult[] = [];

  errorCount: WritableSignal<number> = signal(0);
  verificationErrors: WritableSignal<ErrorCheckResult[]> = signal([]);
  verificationErrorsKeyString: WritableSignal<KeyStringObj[]> = signal([]);
  channelData: WritableSignal<CheckResults | null> = signal(null);
  mediaErrors: WritableSignal<string[]> = signal([]);
  mediaErrorList: WritableSignal<MediaErrorListResult[]> = signal([]);
  mediaErrorListKeyString: WritableSignal<KeyStringObj[]> = signal([]);

  shouldUpdate: boolean = false;

  ccmsId: string = '';
  fileName: string = '';
  channelName: string = '';

  modalContent: string[] = [];

  verificationTableHeaders = [
    {
      name: 'StartTime',
      value: 'startTime',
    },
    {
      name: 'BreakNr.',
      value: 'breakNumber',
    },
    {
      name: 'SpotNr.',
      value: 'spotNumber',
    },
    {
      name: 'Status',
      value: 'statusCode',
    },
    {
      name: 'Media',
      value: 'spotFileName',
    },
  ];

  mediaTableHeaders = [
    {
      name: 'Media Error',
      value: 'name',
    },
    {
      name: 'File',
      value: 'errorList',
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.userAccountStore.userAccountLoggedIn$.subscribe((res) => {
      if (res === false) {
        this.router.navigate(['sign-in']);
      }
    });
    this.channelName = this.route.snapshot.paramMap.get('channelName') || '';
    this.loadData(null);
  }

  loadData(data: CheckResults | null) {
    if (data) {
      this.setData(data);
    } else {
      this.aiMonitorService
        .aimonitorChannelsCheckresults$Json({
          body: { channel: this.channelName },
        })
        .subscribe((res) => {
          this.setData(res.data);
          this.shouldUpdate = true;
        });
    }
  }

  setData(data: CheckResults) {
    this.selectedErrors = [];
    this.selectedMediaErrors = [];
    this.channelData.set(data);
    this.errorCount.set(
      this.addInsertionMonitorService.getErrorsFromResults(data).length,
    );
    this.verificationErrors.set(
      this.addInsertionMonitorService.getErrorsFromResults(
        data,
      ) as ErrorCheckResult[],
    );
    this.verificationErrorsKeyString.set(
      this.verificationErrors() as unknown as KeyStringObj[],
    );

    this.setCcmsId();
    const errors = this.addInsertionMonitorService.mediaValid(
      data?.mediaCheckResults as MediaCheckResult,
    );
    this.setReadableMediaErrors(errors as MediaCheckResult[]);
    const errorList: string[] = [];
    errors.forEach((elm) => {
      errorList.push(
        '</br>' + Object.keys(elm as object)[0].replace(/missingFiles/g, ''),
      );
    });
    this.mediaErrors.set(errorList);
    this.shouldUpdate = false;
  }

  setCcmsId() {
    this.ccmsId =
      ('00' + this.channelData()!.channelId).slice(-2) +
      ('000' + this.channelData()!.headEndId).slice(-3);
  }

  getScheduleFilename() {
    const d = new Date();
    const hexMonth = (d.getMonth() + 1).toString(16).toUpperCase();
    const day = ('00' + d.getDate()).slice(-2);
    return `${hexMonth}${day}${this.ccmsId}`;
  }

  getSchedule() {
    this.modalContent = [];
    const result: string[] = [];
    if (this.channelData() && this.channelData()?.sheduleResults) {
      const lines =
        this.channelData()!.sheduleResults!.scheduleFileToday!.split(
          /(?:\r\n|\r|\n)/g,
        );
      lines.forEach((line: string) => {
        result.push(line);
      });
    }
    this.modalContent = result;
  }

  getVerification() {
    this.modalContent = [];
    const result: string[] = [];
    if (this.channelData() && this.channelData()?.verificationResults) {
      const lines =
        this.channelData()!.verificationResults!.verificationFile!.split(
          /(?:\r\n|\r|\n)/g,
        );
      lines.forEach((line: string) => {
        result.push(line);
      });
    }
    this.modalContent = result;
  }

  mediaValid() {
    const errors = this.addInsertionMonitorService.mediaValid(
      this.channelData()?.mediaCheckResults as MediaCheckResult,
    );
    let errorString = '';
    errors.forEach((elm) => {
      const name = Object.keys(elm as never).toString();
      const vals = Object.values(elm as never)[0];
      const valString = Object.values(vals as never).toString();
      errorString += '<br> - ' + name + ': ' + valString.replaceAll(',', ', ');
    });
    return errors.length === 0 ? 'OK' : 'Errors found in: ' + errorString;
  }

  scheduleValid() {
    const errors = this.addInsertionMonitorService.scheduleValid(
      this.channelData()?.sheduleResults as ScheduleCheckResult,
    );
    let errorString = '';
    errors.forEach((elm) => {
      let objString = JSON.stringify(elm);
      objString = objString.substring(1, objString.length - 1);
      errorString += ' - ' + objString;
    });
    return errors.length === 0 ? 'OK' : errorString;
  }

  setReadableMediaErrors(errors: MediaCheckResult[]) {
    const readableErrorList: unknown[] = [];
    errors.forEach((error) => {
      const name = Object.keys(error)[0];
      const mediaErrorList = Object.values(error)[0];
      if (mediaErrorList) {
        const errorList = Object.values(mediaErrorList);
        readableErrorList.push({
          name: name,
          errorList: errorList.toString().replaceAll(',', ', '),
        });
      }
    });
    this.mediaErrorList.set(readableErrorList as MediaErrorListResult[]);

    this.mediaErrorListKeyString.set(
      this.mediaErrorList() as unknown as KeyStringObj[],
    );
  }

  copyMedia() {
    let errorString = '';
    this.selectedMediaErrors.forEach((el) => {
      errorString += Object.values(el).toString().replace(',', ': ') + '\n';
    });
    navigator.clipboard.writeText(errorString);
    alert('Copied the text: ' + errorString);
  }

  acknowledgeError(errorList?: ErrorCheckResult[]) {
    const listToAck: ErrorCheckResult[] = errorList
      ? errorList
      : this.verificationErrors();

    listToAck.forEach((elm) => {
      this.addInsertionMonitorService.acknowledge(
        this.channelName,
        elm.breakNumber,
        elm.spotNumber,
      );
    });
    this.loadData(null);
  }

  resetAcknowledge() {
    this.aiMonitorService
      .aimonitorChannelsUnacknowledgeAll({
        body: { channel: this.channelName },
      })
      .subscribe(() => {
        this.loadData(null);
      });
  }

  printVal(val: unknown) {
    console.log(val);
  }

  setVerificationErrors(errors: unknown) {
    this.selectedErrors = errors as unknown as ErrorCheckResult[];
  }
  setMediaErrors(errors: unknown) {
    this.selectedMediaErrors = errors as unknown as MediaErrorListResult[];
  }
}
