import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormGroup,
  FormControl,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';

@Component({
  selector: 'shared-form-control',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class FormControlComponent implements OnInit, OnDestroy {
  protected additionalValidators: ValidatorFn[] = [];

  @Input({ required: true }) formGroup!: FormGroup;
  @Input() control!: AbstractControl<any, any>;
  @Input({ required: true }) label!: string;
  @Input() id: string | null = null;
  @Input() controlName: string | null = null;
  @Input() showLabel: boolean = true;
  @Input() placeholder: string | null = null;
  @Input() showPlaceholder: boolean = true;
  @Input() showErrorHints: boolean = true;
  @Input() styleClass: string | null = null;
  @Input() additionalStyleClasses: string | null = null;
  @Input() isDisabled: boolean = false;
  @Input() submitted: boolean = false;
  @Input() info = '';

  ngOnInit(): void {
    if (!this.id) {
      this.id = this.label;
    }
    if (this.showPlaceholder && !this.placeholder) {
      this.placeholder = this.label;
    }
    if (this.control instanceof FormControl && this.isDisabled) {
      this.control.disable();
    }
    this.additionalValidators.forEach((f) => {
      this.control.addValidators(this.additionalValidators);
    });
  }

  ngOnDestroy(): void {
    this.removeAdditionalValidators();
  }

  getErrorMessage(): string {
    if (this.control.hasError('required')) {
      return 'This field is required.';
    }
    if (this.control.hasError('maxLength')) {
      return 'The value has too many characters.';
    }
    return 'This field has errors.';
  }

  protected removeAdditionalValidators(): void {
    this.additionalValidators.forEach((f) => {
      this.control.removeValidators(f);
    });
  }
}
