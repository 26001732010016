import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { NgClass } from "@angular/common";

@Component({
  selector: 'shared-svg-cross',
  imports: [
    NgClass
  ],
  templateUrl: './cross.component.html',
  styleUrl: './cross.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrossComponent {
  color: InputSignal<string> = input<string>('black');
  shouldAnimate: InputSignal<boolean> = input<boolean>(false);
}
