import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  inject,
} from '@angular/core';
import {
  RouterStateSnapshot,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { UserAccountStoreService } from '../../../../account/stores/user-account-store.service';
import { Q90Component } from '../../../../shared/components/svg/q90/q90.component';
import { Theme } from '../../../models/theme';
import {
  tenantUrlRegex,
  teamUrlRegex,
  AppRoutingService,
} from '../../../services/app-routing.service';
import { AppThemesService } from '../../../services/app-themes.service';

interface NavigationHeaderData {
  userAccountLoggedIn: boolean;
  theme: Theme;
  currentRoute: RouterStateSnapshot | null;
  currentRouteFiltered: RouterStateSnapshot | null;
}

@Component({
  selector: 'core-navigation-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterLink, RouterLinkActive, Q90Component],
  templateUrl: './navigation-header.component.html',
  styleUrls: ['./navigation-header.component.scss'],
})
export class NavigationHeaderComponent implements OnInit {
  tenantUrlRegex = tenantUrlRegex;
  teamUrlRegex = teamUrlRegex;

  private userAccountStore = inject(UserAccountStoreService);
  private appRoutingService = inject(AppRoutingService);
  private applicationThemesService = inject(AppThemesService);
  navigationHeaderData$!: Observable<NavigationHeaderData>;

  ngOnInit(): void {
    this.navigationHeaderData$ = combineLatest([
      this.userAccountStore.userAccountLoggedIn$,
      this.applicationThemesService.theme$,
      this.appRoutingService.currentRoute$,
      this.appRoutingService.currentRouteFiltered$,
    ]).pipe(
      map(
        ([userAccountLoggedIn, theme, currentRoute, currentRouteFiltered]) => {
          return {
            userAccountLoggedIn,
            theme,
            currentRoute,
            currentRouteFiltered,
          };
        },
      ),
    );
  }
}
