import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, takeUntil, map, catchError } from 'rxjs';
import { PrimeableService } from '../../core/services/primeable.service';
import { Q90ResponseData } from '../../shared/interfaces/q90-response';
import {
  EntitiesList,
  RightsList,
  UserLevelsList,
} from '../models/authorization';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService extends PrimeableService {
  constructor() {
    super();
  }

  protected prime() {
    this.setQueryingPrimeableService(true);
    const observablesArray: Observable<Q90ResponseData<any>>[] = [];
    observablesArray.push(this.getEntitiesList());
    observablesArray.push(this.getUserlevelsList());
    observablesArray.push(this.getRightsList());
    const combinedObservable$ = forkJoin([...observablesArray]);
    combinedObservable$.pipe(takeUntil(this.destroyed)).subscribe({
      next: (response) => {
        const entitiesListData = response[0] as Q90ResponseData<string[]>;
        // Check backend list with front-end model
        for (const [entry, index] of Object.entries(entitiesListData.data)) {
          if (index !== EntitiesList[entry as keyof typeof EntitiesList]) {
            this.appService.debug('ENTITIES LIST appears to have changed.');
          }
        }

        const userLevelsListData = response[1] as Q90ResponseData<string[]>;
        // Check backend list with front-end model
        for (const [entry, index] of Object.entries(userLevelsListData.data)) {
          if (index !== UserLevelsList[entry as keyof typeof UserLevelsList]) {
            this.appService.debug('USER LEVELS LIST appears to have changed.');
          }
        }

        // const rolesListData = response[1] as Q90ResponseData<{}>;
        // let objs: AuthorizationRole[] = [];
        // // Check backend list with front-end list
        // for (let [entry, index] of Object.entries(rolesListData.data)) {
        //   if (index !== RolesList[entry as keyof typeof RolesList]) {
        //     this.appService.debug("ROLES LIST appears to have changed.");
        //   }
        //   // Make list of user roles available for user interface...
        //   objs.push(new AuthorizationRole().deserialize({ value: index as number, key: entry as string }));
        //   this.rolesStore.next(objs);
        // }

        // // Check backend list with front-end list
        // const userLevelsData = response[2] as Q90ResponseData<{}>;
        // for (let [entry, index] of Object.entries(userLevelsData.data)) {
        //   if (index !== UserLevelsList[entry as keyof typeof UserLevelsList]) {
        //     this.appService.debug("USER LEVELS LIST appears to have changed.");
        //   }
        // }
      },
      error: (error) => {
        this.setQueryingPrimeableService(false);
        this.errorService.setError(error);
      },
      complete: () => {
        this.setQueryingPrimeableService(false);
      },
    });
  }

  protected authenticated(): void { }

  protected refresh(): void { }

  protected logout() { }

  private getEntitiesList(): Observable<Q90ResponseData<string[]>> {
    return this.http
      .get<Q90ResponseData<string[]>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/authorization/entities/list`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  private getRightsList(): Observable<Q90ResponseData<RightsList>> {
    return this.http
      .get<Q90ResponseData<RightsList>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/authorization/rights/list`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  private getUserlevelsList(): Observable<Q90ResponseData<string[]>> {
    return this.http
      .get<Q90ResponseData<string[]>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/authorization/userlevels/list`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}
