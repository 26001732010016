import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'shared-q90-logo',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './q90.component.html',
  styleUrls: ['./q90.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Q90Component {

}
