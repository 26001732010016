import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  inject,
  WritableSignal,
  signal,
} from '@angular/core';
import { Observable, combineLatest, map, take } from 'rxjs';
import { UserAccountStoreService } from '../../../../account/stores/user-account-store.service';
import { Tenant } from '../../../../entity/tenant/models/tenant';
import { CurrentTenantService } from '../../../services/current-tenant.service';
import { OptionCollection } from '../../../../shared/interfaces/option-collection';
import { TenantService } from '../../../../entity/tenant/services/tenant.service';

interface CurrentTenantData {
  myTenants: Tenant[];
  currentTenant: Tenant | null;
}

@Component({
  selector: 'core-navigation-tenant-switch',
  imports: [CommonModule],
  templateUrl: './navigation-tenant-switch.component.html',
  styleUrls: ['./navigation-tenant-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationTenantSwitchComponent implements OnInit {
  public currentTenantService = inject(CurrentTenantService);
  public tenantService = inject(TenantService);
  private userAccountStore = inject(UserAccountStoreService);
  tenantData$!: Observable<CurrentTenantData>;
  icon: WritableSignal<string> = signal('');

  isOpen: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.tenantData$ = combineLatest([
      this.userAccountStore.userAccountMyTenants$,
      this.currentTenantService.currentTenant$,
    ]).pipe(
      map(([myTenants, currentTenant]) => {
        this.icon.set('');
        myTenants = myTenants.sort((a, b) => a.name.localeCompare(b.name));
        if (currentTenant)
          this.tenantService
            .getProfile(currentTenant.id)
            .pipe(take(1))
            .subscribe((elm) => {
              this.icon.set(elm.data.icon);
            });
        else if (myTenants.length > 0)
          setTimeout(() => {
            this.currentTenantService.setCurrentTenant(myTenants[0] as Tenant);
          }, 1);
        return {
          myTenants,
          currentTenant,
        };
      }),
    );
  }

  myTenantsTracker(index: number, tenant: Tenant): string {
    return tenant.id;
  }

  updateCurrentTenant(val: OptionCollection) {
    this.isOpen = false;
    this.currentTenantService.setCurrentTenant(val as Tenant);
  }
}
