import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputSearchComponent } from '../../../shared/forms/components/control/form-input-search/form-input-search.component';
import { FormComponent } from '../../../shared/forms/components/form/form.component';
import { ErrorMessagesStoreService } from '../../../shared/stores/error-messages-store.service';
import { GetFormControlPipe } from '../../../shared/forms/pipes/get-form-control.pipe';

@Component({
  selector: 'core-site-search',
  standalone: true,
  imports: [
    CommonModule,
    FormInputSearchComponent,
    GetFormControlPipe
  ],
  providers: [
    ErrorMessagesStoreService
  ],
  templateUrl: './site-search.component.html',
  styleUrls: ['./site-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteSearchComponent extends FormComponent {

  override formGroup = this.formBuilder.group({
    search: this.formBuilder.control<string>(''),
  });

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  changeSearch(result: string): void {
  }

  clearFilters(): void {
    this.formGroup.controls['search'].patchValue('');
  }
}
