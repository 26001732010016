
import { Deserializable } from '../../shared/interfaces/deserializable';
import { OptionCollection } from '../../shared/interfaces/option-collection';

export interface ThemeInterface {
  id: string;
  name: string;
}

export type ThemeKeys = keyof ThemeInterface;

export class Theme implements Deserializable, OptionCollection {

  constructor(
    private _id: string = '',
    private _name: string = ''
  ) { }

  get optionName(): string {
    return this.name;
  }
  get optionValue(): string {
    return this.id;
  }

  get id() {
    return this._id;
  }
  set id(id) {
    this._id = id;
  }
  get class(): string {
    return this.id;
  }
  set class(className: string) {
    this.id = className;
  }
  get name() {
    return this._name;
  }
  set name(name) {
    this._name = name;
  }

  deserialize(input: ThemeInterface): this {
    Object.assign(this, input);
    return this;
  }
}
