import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrentTenantIdStore {
  private currentTenantIdStore = new BehaviorSubject<string>('');
  private currentTenantNameStore = new BehaviorSubject<string>('');
  currentTenantId$: Observable<string> =
    this.currentTenantIdStore.asObservable();
  currentTenantName$: Observable<string> =
    this.currentTenantNameStore.asObservable();

  setTenantId(id: string): void {
    this.currentTenantIdStore.next(id);
  }
  setTenantName(name: string): void {
    this.currentTenantNameStore.next(name);
  }

  getTenantId(): string {
    let result: string = '';
    this.currentTenantIdStore.subscribe((id) => {
      result = id;
    });
    return result;
  }
  getTenantName(): string {
    let result: string = '';
    this.currentTenantNameStore.subscribe((name) => {
      result = name;
    });
    return result;
  }
}
