<div class="modal" [ngClass]="{ closing: closing }" [@enterAnimation]>
  <div
    class="modal-backdrop"
    (click)="closeModalOnClick($event)"
    aria-label="Close modal"
    tabindex="0"
    role="button"
  ></div>
  <div class="modal-display" [ngClass]="'modal-display-' + size()">
    <div class="modal-header">
      <h5>{{ title() }}</h5>
      @if (withCloseButton()) {
        <div
          type="button"
          aria-label="Close modal"
          class="modal-close cursor-pointer"
          (click)="closeModalOnClick($event)"
        >
          <shared-svg-cross color="white"/>
        </div>
      }
    </div>
    <div class="modal-content p-1">
      <ng-content></ng-content>
    </div>
  </div>
</div>
