import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin, takeUntil, map, catchError } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  AddressTypeInterface,
  AddressType,
} from '../../entity-secondary/entity-address/models/address-type';
import { Q90ResponseDataCollection } from '../../shared/interfaces/q90-response';
import { AddressStoreService } from '../stores/address-store.service';
import { PrimeableService } from './primeable.service';
import { Address } from '../../entity-secondary/entity-address/models/address';
import { CurrentTenantIdStore } from '../../pages/tenant/tenant/tenant.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends PrimeableService {
  private addressStore = inject(AddressStoreService);
  currentTenantIdStore = inject(CurrentTenantIdStore);

  constructor() {
    super();
  }

  protected prime() {
    this.setQueryingPrimeableService(true);
    const observablesArray: Observable<
      Q90ResponseDataCollection<AddressTypeInterface>
    >[] = [];
    observablesArray.push(this.fetchAddressTypes());
    const combinedObservable$ = forkJoin([...observablesArray]);
    combinedObservable$.pipe(takeUntil(this.destroyed)).subscribe({
      next: (response) => {
        this.setQueryingPrimeableService(false);
        // Process address types response
        const addressTypesData =
          response[0] as Q90ResponseDataCollection<AddressTypeInterface>;
        const addressTypesObjs: AddressType[] = addressTypesData.data.map(
          (obj) => new AddressType().deserialize(<AddressTypeInterface>obj)
        );
        this.addressStore.setAddressTypes(addressTypesObjs);
      },
      error: (error) => {
        this.setQueryingPrimeableService(false);
        this.errorService.setError(error);
      },
    });
  }

  protected authenticated(): void { }
  protected refresh(): void { }
  protected logout(): void { }

  fetchAddressTypes(): Observable<
    Q90ResponseDataCollection<AddressTypeInterface>
  > {
    return this.http
      .get<Q90ResponseDataCollection<AddressTypeInterface>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/address/addresstypes`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}
