import { Injectable, NgModule, OnDestroy, inject } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  filter,
  takeUntil,
} from 'rxjs';
import { AppService, ApplicationEvent } from './app.service';
import { DestroyService } from '../../shared/services/destroy.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationStoreService } from '../../authentication/stores/authentication-store.service';
import { ErrorService } from '../../shared/services/error.service';
import { UserAccountStoreService } from '../../account/stores/user-account-store.service';

export enum PrimeableServiceState {
  Primed = 'PRIMED',
  Stale = 'STALE',
}
@Injectable({
  providedIn: 'root',
})
export abstract class PrimeableService implements OnDestroy {
  protected appService = inject(AppService);
  protected errorService = inject(ErrorService);
  protected authenticationStore = inject(AuthenticationStoreService);
  protected userAccountStore = inject(UserAccountStoreService);
  protected destroyed = inject(DestroyService);
  protected http = inject(HttpClient);

  private queryingPrimeableServiceStore = new BehaviorSubject<boolean>(false);
  queryingPrimeableService$: Observable<boolean> =
    this.queryingPrimeableServiceStore.asObservable();

  private primeableServiceStateStore =
    new BehaviorSubject<PrimeableServiceState>(PrimeableServiceState.Stale);
  primeableServiceState$: Observable<PrimeableServiceState> =
    this.primeableServiceStateStore.asObservable();

  private applicationEventSubscription!: Subscription;

  constructor() {
    // Listen for Application Events. Application events can trigger primeable service methods...
    this.applicationEventSubscription = this.appService.applicationEvent$
      .pipe(
        filter((event) => event !== ApplicationEvent.Idle),
        takeUntil(this.destroyed),
      )
      .subscribe((setting) => {
        this.appService.debug(
          `${this.constructor.name}: application event: ${setting}`,
        );
        if (setting === ApplicationEvent.Prime) {
          this.prime();
        }
        if (setting === ApplicationEvent.Login) {
          this.authenticated();
        }
        if (setting === ApplicationEvent.RefreshToken) {
          this.refresh();
        }
        if (setting === ApplicationEvent.Logout) {
          this.logout();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.applicationEventSubscription) {
      this.applicationEventSubscription.unsubscribe();
    }
  }

  setQueryingPrimeableService(setting: boolean) {
    this.queryingPrimeableServiceStore.next(setting);
    this.appService.setPrimeablePriming(setting);
  }

  setPrimeableServiceState(setting: PrimeableServiceState) {
    this.primeableServiceStateStore.next(setting);
  }

  getAppService(): AppService {
    return this.appService;
  }

  /**
   * The following abstract methods are called in all services that extend PrimeableService
   * when a corresponding ApplicationEvent is broadcasted via the applicationEvent$ store.
   * Generally, the code in these methods involve api calls to the backend at specific times
   * in the application; for instance, pulling in lists from the backend to 'prime' or configure
   * bits of the application in the front-end.
   *
   * prime: When the ApplicationEvent.Prime is broadcasted services that extend PrimeableService
   * will call this method. Generally, it configures general application-wide lists. These lists are generally
   * non-sensitive info and should be callable, in the case of apis calls; in other words, no authenticated
   * user needs to be available for the call to be called. Generally, called when application starts-up.
   */
  protected abstract prime(): void;

  /**
   * authenticated: When the ApplicationEvent.login is broadcasted services that extend PrimeableService
   * will call this method. Generally, it configures general application-wide lists. These lists are generally
   * behind apis calls which require an authenticated user for the call to be called. Generally, called when a
   * user logs in and is autghenticated.
   */
  protected abstract authenticated(): void;

  /**
   * refresh: When the ApplicationEvent.RefreshToken is broadcasted services that extend PrimeableService
   * will call this method. Generally, it (re)configures general application-wide lists. Generally, is called
   * when the browser refresh button is clicked.
   */
  protected abstract refresh(): void;

  /**
   * logout: When the ApplicationEvent.Logout is broadcasted services that extend PrimeableService
   * will call this method. Generally, it can be used to tear down application bits. Generally, is called
   * when a user logs out.
   */
  protected abstract logout(): void;
}
