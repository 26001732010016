import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin, takeUntil, map, catchError } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  SocialMediaTypeInterface,
  SocialMediaType,
  SocialMediaTypeSearchInterface,
  defaultSocialmediaTypeSearchQuery,
} from '../../entity-secondary/entity-social-media/models/social-media-type';
import {
  Q90ResponseData,
  Q90SearchResult,
} from '../../shared/interfaces/q90-response';
import { CountryInterface, Country } from '../models/country';
import { GenderInterface, Gender } from '../models/gender';
import { LanguageInterface, Language } from '../models/language';
import { PronounsInterface, Pronouns } from '../models/pronouns';
import { TimezoneInterface, Timezone } from '../models/timezone';
import { GeneralStoreService } from '../stores/general-store.service';
import { PrimeableService } from './primeable.service';
import { Q90Response } from '../../shared/models/q90-response';

@Injectable({
  providedIn: 'root',
})
export class GeneralService extends PrimeableService {
  private generalStore = inject(GeneralStoreService);

  constructor() {
    super();
  }

  protected prime() {
    this.setQueryingPrimeableService(true);
    const observablesArray: Observable<
      | Q90ResponseData<LanguageInterface[]>
      | Q90ResponseData<Q90SearchResult<SocialMediaTypeInterface[]>>
      | Q90ResponseData<PronounsInterface[]>
      | Q90ResponseData<GenderInterface[]>
      | Q90ResponseData<TimezoneInterface[]>
      | Q90ResponseData<LanguageInterface>
    >[] = [];
    observablesArray.push(this.fetchLanguages());
    observablesArray.push(this.fetchSocialmediaTypes());
    observablesArray.push(this.fetchPronouns());
    observablesArray.push(this.fetchGender());
    observablesArray.push(this.fetchTimezones());
    observablesArray.push(this.fetchCountries());
    observablesArray.push(this.fetchCurrentLanguage());
    const combinedObservable$ = forkJoin([...observablesArray]);
    combinedObservable$.pipe(takeUntil(this.destroyed)).subscribe({
      next: (response) => {
        this.setQueryingPrimeableService(false);

        // Process languages data response
        const languagesData = response[0] as Q90ResponseData<LanguageInterface[]>;
        const languagesObjs: Language[] = languagesData.data.map((obj) =>
          new Language().deserialize(<LanguageInterface>obj)
        );
        this.generalStore.setLanguages(languagesObjs);

        // Process social media types data response
        const socialmediaTypesData = response[1] as Q90ResponseData<Q90SearchResult<SocialMediaTypeInterface[]>>;
        const socialmediaTypesObjs: SocialMediaType[] =
          socialmediaTypesData.data.data.map((obj) =>
            new SocialMediaType().deserialize(<SocialMediaTypeInterface>obj)
          );
        this.generalStore.setSocialmediaTypes(socialmediaTypesObjs);

        // Process pronouns data response
        const pronounsData = response[2] as Q90ResponseData<PronounsInterface[]>;
        const pronounsObjs: Pronouns[] = pronounsData.data.map((obj) =>
          new Pronouns().deserialize(<PronounsInterface>obj)
        );
        this.generalStore.setPronouns(pronounsObjs);

        // Process genders data response
        const gendersData = response[3] as Q90ResponseData<GenderInterface[]>;
        const gendersObjs: Gender[] = gendersData.data.map((obj) =>
          new Gender().deserialize(<GenderInterface>obj)
        );
        this.generalStore.setGenders(gendersObjs);

        // Process timezones data response
        const timezonesData = response[4] as Q90ResponseData<TimezoneInterface[]>;
        if (timezonesData.data?.length > 0) {
          const timezonesObjs: Timezone[] = timezonesData.data.map((obj) =>
            new Timezone().deserialize(<TimezoneInterface>obj)
          );
          this.generalStore.setTimezones(timezonesObjs);
        }

        // Process countries data response
        const countriesData = response[5] as Q90ResponseData<CountryInterface[]>;
        const countriesObjs: Country[] = countriesData.data.map((obj) =>
          new Country().deserialize(<CountryInterface>obj)
        );
        this.generalStore.setCountries(countriesObjs);

        // Process current language & locale
        const currentLanguageData = response[6] as Q90ResponseData<LanguageInterface>;
        const currenLanguage: Language = new Language().deserialize(<LanguageInterface>currentLanguageData.data);
        this.generalStore.setCurrentLanguage(currenLanguage);
        this.generalStore.setCurrentLocale(currenLanguage.ietfCode);

      },
      error: (error) => {
        this.setQueryingPrimeableService(false);
        this.errorService.setError(error);
      },
    });
  }

  protected authenticated(): void {
    this.setQueryingPrimeableService(true);
    const observablesArray: Observable<
      Q90ResponseData<CountryInterface[]>
    >[] = [];
    const combinedObservable$ = forkJoin([...observablesArray]);
    combinedObservable$.pipe(takeUntil(this.destroyed)).subscribe({
      next: (response) => {
        this.setQueryingPrimeableService(false);
        // Process languages data response
        const countryData =
          response[0] as Q90ResponseData<CountryInterface[]>;
        const countryObjs: Country[] = countryData.data.map((obj) =>
          new Country().deserialize(<CountryInterface>obj)
        );
        this.generalStore.setCountries(countryObjs);
      },
      error: (error) => {
        this.setQueryingPrimeableService(false);
        this.errorService.setError(error);
      },
    });
  }

  protected refresh(): void { }
  protected logout(): void { }

  fetchLanguages(): Observable<Q90ResponseData<LanguageInterface[]>> {
    return this.http
      .get<Q90ResponseData<LanguageInterface[]>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/general/languages`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map(res => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  fetchCurrentLanguage(): Observable<Q90ResponseData<LanguageInterface>> {
    return this.http.get<Q90ResponseData<LanguageInterface>>(`${environment.apiEndpointRoot + environment.apiEndpointVersion
      }/general/languages/current`,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          'Bearer ' + this.authenticationStore.getToken()
        ),
        responseType: 'json',
      }
    )
      .pipe(
        map(res => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  fetchCountries(): Observable<Q90ResponseData<CountryInterface[]>> {
    return this.http
      .get<Q90ResponseData<CountryInterface[]>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/general/countries`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map(res => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  fetchSocialmediaTypes(
    query: SocialMediaTypeSearchInterface = defaultSocialmediaTypeSearchQuery
  ): Observable<Q90ResponseData<Q90SearchResult<SocialMediaTypeInterface[]>>> {
    return this.http
      .post<Q90ResponseData<Q90SearchResult<SocialMediaTypeInterface[]>>>(
        `${environment.apiEndpointRoot}${environment.apiEndpointVersion}/general/socialmediatypes/search`,
        {
          search: query.search,
          pageSize: query.pageSize,
          page: query.page,
          orderDirection: query.orderDirection,
        },
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map(res => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  fetchPronouns(): Observable<Q90ResponseData<PronounsInterface[]>> {
    return this.http
      .get<Q90ResponseData<PronounsInterface[]>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/general/pronouns`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map(res => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  fetchGender(): Observable<Q90ResponseData<GenderInterface[]>> {
    return this.http
      .get<Q90ResponseData<GenderInterface[]>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/general/genders`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map(res => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  fetchTimezones(): Observable<Q90ResponseData<TimezoneInterface[]>> {
    return this.http
      .get<Q90ResponseData<TimezoneInterface[]>>(
        `${environment.apiEndpointRoot + environment.apiEndpointVersion
        }/general/timezones`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map(res => {
          if (res.statusCode !== 200) {
            this.errorService.setError(new Q90Response().deserialize(res));
          }
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}
