<ng-container *ngIf="userAccountMenuData$ | async as userAccountMenuData">
  <div
    id="user-account-menu"
    *ngIf="userAccountMenuData.authenticatedUserAccount && userAccountMenuData.userAccountLoggedIn"
    [ngClass]="!(userAccountMenuData.userAccountMenuState)?'is-closed':'is-open'"
  >
    <nav [ngClass]="userAccountMenuData.theme">
      <header>
        <p class="h5">{{userAccountMenuData.authenticatedUserAccount.displayName}}</p>
      </header>
      <ul>
        <li>
          <a
            (click)="toggle(appMenus.UserAccountDropdown)"
            [routerLink]="['/account']"
            [routerLinkActive]="'is-active'"
          >Account</a>
        </li>
        <li>
          <a
            (click)="toggle(appMenus.UserAccountDropdown)"
            [routerLink]="['/sign-out']"
            [routerLinkActive]="'is-active'"
          >Sign-out</a>
        </li>
      </ul>
    </nav>
  </div>
</ng-container>
