import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideRouter } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { importProvidersFrom } from '@angular/core';
import { MainRoutes } from './app/app-routing';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthenticationInterceptor } from './app/authentication/authentication-interceptor';
import { DatePipe } from '@angular/common';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { ApiModule } from './api/api.module';
import { environment } from './environments/environment';
import { TokenInterceptor } from './app/core/interceptors/tokenInterceptor';

bootstrapApplication(AppComponent, {
  providers: [
    DatePipe,
    provideRouter(MainRoutes),
    provideHttpClient(withInterceptors([AuthenticationInterceptor])),
    importProvidersFrom(BrowserAnimationsModule),
    provideAngularSvgIcon(),
    importProvidersFrom(
      ApiModule.forRoot({ rootUrl: environment.tempAiApiEndpointRoot }),
    ),
    provideHttpClient(
      withInterceptors([AuthenticationInterceptor, TokenInterceptor]),
    ),
  ],
});
