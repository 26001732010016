import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  VERSION,
  inject,
  HostListener,
  Renderer2,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Observable, combineLatest, filter, map, take } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserAccountNavigationComponent } from '../../account/components/user-account-navigation/user-account-navigation.component';
import { UserAccountStoreService } from '../../account/stores/user-account-store.service';
import { CookieDisclaimerComponent } from '../../core/components/cookie-disclaimer/cookie-disclaimer.component';
import { NavigationFooterComponent } from '../../core/components/navigation/navigation-footer/navigation-footer.component';
import { NavigationHeaderComponent } from '../../core/components/navigation/navigation-header/navigation-header.component';
import { NavigationSidebarComponent } from '../../core/components/navigation/navigation-sidebar/navigation-sidebar.component';
import { RefreshingTokenComponent } from '../../core/components/refreshing-token/refreshing-token.component';
import { AppMenusService, MasterMenus } from '../../core/services/app-menus.service';
import { AppService } from '../../core/services/app.service';
import { FrontendLocalStorageService } from '../../core/services/storage/frontend-local-storage.service';
import { Person } from '../../entity/person/models/person';
import { ModalComponent } from '../../shared/components/modal/modal.component';
import { Theme } from '../../core/models/theme';
import { AppThemesService } from '../../core/services/app-themes.service';
import { ContextMenu } from 'primeng/contextmenu';
import { Ripple } from 'primeng/ripple';
import { BadgeModule } from 'primeng/badge';
import { ContextMenuService } from '../services/context-menu.service';
import { NavigationTenantSwitchComponent } from '../../core/components/navigation/navigation-tenant-switch/navigation-tenant-switch.component';

interface ApplicationData {
  theme: Theme;
  userAccountLoggedIn: boolean;
  authenticatedPerson: Person | null;
  appMenuCollapsedState: boolean;
  appMenuState: boolean;
  userAccountTenantMenu: boolean;
  unsavedFormsWarning: boolean;
}

@Component({
  selector: 'layout-main-layout',
  imports: [
    CommonModule,
    RouterOutlet,
    NavigationHeaderComponent,
    CookieDisclaimerComponent,
    RefreshingTokenComponent,
    ModalComponent,
    NavigationSidebarComponent,
    UserAccountNavigationComponent,
    NavigationFooterComponent,
    ContextMenu,
    BadgeModule,
    Ripple,
    NavigationTenantSwitchComponent,
  ],
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnInit {
  applicationData$!: Observable<ApplicationData>;

  angularVersion = VERSION.full;

  private appService = inject(AppService);
  private appMenusService = inject(AppMenusService);
  private appThemesService = inject(AppThemesService);
  private renderer = inject(Renderer2);
  private userAccountStore = inject(UserAccountStoreService);
  public contextMenuService = inject(ContextMenuService);
  localStorageService = inject(FrontendLocalStorageService);

  env = environment;
  cookieDisclaimerAgreed = false;
  tenantSelectorOpen = false;

  constructor() {}

  @HostListener('document:click', ['$event'])
  checkForUnsavedForms(event: Event): boolean {
    let result: boolean = true;
    // Is the click inside a form and is it unsaved?
    // https://stackblitz.com/edit/hostlistener-dom-traveral-js61mb
    // const formElement = event.currentTarget as HTMLFormElement;
    const targetElement = event.target as HTMLElement;
    // const currentElement = event.currentTarget as HTMLElement;

    let controlElement = targetElement;
    while (controlElement && controlElement.tagName !== 'FORM') {
      controlElement = this.renderer.parentNode(controlElement);
    }

    this.appService.unsavedForms$
      .pipe(
        filter((unsavedForms) => unsavedForms.length > 0),
        // distinctUntilChanged(),
        // shareReplay(),
        take(1),
      )
      .subscribe((unsavedForms) => {
        // console.log(unsavedForms, controlElement?.tagName);
        if (unsavedForms.includes(controlElement?.id)) {
          this.appService.setUnsavedFormsWarning(false);
        } else {
          // console.log('Unsaved form warning.', targetElement, currentElement, event, event.cancelable);
          event.stopPropagation();
          event.preventDefault();
          result = false;
          // this.appService.setUnsavedFormsWarning(true);
        }
      });
    // console.log(result);
    return result;
  }

  ngOnInit(): void {
    this.applicationData$ = combineLatest([
      this.appThemesService.theme$,
      this.userAccountStore.userAccountLoggedIn$,
      this.userAccountStore.authenticatedPersonStore.entity$,
      this.appMenusService.appMenuCollapsedState$,
      this.appMenusService.appMenuState$,
      this.appMenusService.appMenusState$.pipe(
        map((menusState) => menusState[MasterMenus.UserAccountTenantsDropdown]),
      ),
      this.appService.unsavedFormsWarning$,
    ]).pipe(
      map(
        ([
          theme,
          userAccountLoggedIn,
          personEntity,
          appMenuCollapsedState,
          appMenuState,
          userAccountTenantMenu,
          unsavedFormsWarning,
        ]) => {
          const authenticatedPerson = personEntity as Person;
          return {
            theme,
            userAccountLoggedIn,
            authenticatedPerson,
            appMenuCollapsedState,
            appMenuState,
            userAccountTenantMenu,
            unsavedFormsWarning,
          };
        },
      ),
    );
  }

  toggleCollapse() {
    this.applicationData$.pipe(take(1)).subscribe((elm) => {
      this.appMenusService.setMenuCollapsedState(!elm.appMenuCollapsedState);
    });
  }

  onHide() {
    this.contextMenuService.cmContext.set(undefined);
  }
}
