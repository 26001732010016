import { ChangeDetectionStrategy, Component, inject, signal, WritableSignal, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AppService,
  ApplicationEvent,
} from '../../../core/services/app.service';
import { Router } from "@angular/router";

@Component({
    selector: 'account-sign-out',
    imports: [CommonModule],
    templateUrl: './sign-out.component.html',
    styleUrls: ['./sign-out.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignOutComponent implements OnInit  {
  private router : Router = inject(Router);
  private appService : AppService = inject(AppService);
  private redirectTimer = setInterval(() => {}, 1000);
  count : WritableSignal<number> = signal<number>(3);

  ngOnInit(): void {
    this.appService.setApplicationEvent(ApplicationEvent.Logout);
    this.redirectTimer = setInterval(() => {
      this.count.update(value => value - 1);
      if (this.count() <= 0) {
        this.homeRedirect();
      }
    }, 1000);
  }

  homeRedirect(): void {
    clearInterval(this.redirectTimer);
    this.router.navigate(['/']);
  }
}
