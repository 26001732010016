import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin, takeUntil, map, catchError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ContentChannelInterface } from '../../entity/content-channel/models/content-channel';
import {
  ContentChannelTypeInterface,
  ContentChannelType,
} from '../../entity/content-channel/models/content-channel-type';
import {
  Q90ResponseDataCollection,
  Q90ResponseData,
} from '../../shared/interfaces/q90-response';
import { CoreContentChannelStoreService } from '../stores/core-content-channel-store.service';
import { PrimeableService } from './primeable.service';

@Injectable({
  providedIn: 'root',
})
export class CoreContentChannelService extends PrimeableService {
  private contentChannelStore = inject(CoreContentChannelStoreService);

  constructor() {
    super();
  }

  protected prime(): void {}

  protected override authenticated(): void {
    this.setQueryingPrimeableService(true);
    const observablesArray: Observable<
      Q90ResponseDataCollection<ContentChannelTypeInterface>
    >[] = [];
    observablesArray.push(this.fetchTypes());
    const combinedObservable$ = forkJoin([...observablesArray]);
    combinedObservable$.pipe(takeUntil(this.destroyed)).subscribe({
      next: (response) => {
        this.setQueryingPrimeableService(false);
        const addressTypesData =
          response[0] as Q90ResponseDataCollection<ContentChannelTypeInterface>;
        const objs: ContentChannelType[] = addressTypesData.data.map((obj) =>
          new ContentChannelType().deserialize(<ContentChannelTypeInterface>obj)
        );
        this.contentChannelStore.setContentChannelTypes(objs);
      },
      error: (error) => {
        this.setQueryingPrimeableService(false);
        this.errorService.setError(error);
      },
    });
  }

  protected override refresh(): void {
    this.authenticated();
  }
  protected override logout(): void {}

  private fetchTypes() {
    return this.http
      .get<Q90ResponseData<ContentChannelInterface[]>>(
        `${
          environment.apiEndpointRoot + environment.apiEndpointVersion
        }/contentchannel/types`,
        {
          headers: new HttpHeaders().set(
            'Authorization',
            'Bearer ' + this.authenticationStore.getToken()
          ),
          responseType: 'json',
        }
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }
}
