import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { UserAccountStoreService } from '../../../account/stores/user-account-store.service';
import { AppService } from '../../services/app.service';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';

@Component({
    selector: 'core-avatar',
    imports: [CommonModule, SpinnerComponent],
    templateUrl: './avatar.component.html',
    styleUrl: './avatar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {

  @Input() src: string | null = null;
  @Input() size: 'sm' | 'md' | 'lg' = 'sm';
  @Input() negotiateFormat: boolean = true;
  @Input() withLink: boolean = true;
  @Input() loading: boolean = false;

  public appService = inject(AppService);
  public router = inject(Router);
  public userAccountStore = inject(UserAccountStoreService);

  spinnerClass!: string;

  constructor() { }

  ngOnInit(): void {
    this.spinnerClass = 'spinner-white-' + this.size;
  }
}
