import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import { AppThemesService } from '../../../core/services/app-themes.service';
import { AppService } from '../../../core/services/app.service';
import { Person } from '../../../entity/person/models/person';
import { UserAccount } from '../../../entity/user/models/user-account';
import { UserAccountStoreService } from '../../stores/user-account-store.service';
import { UserAccountMenuComponent } from '../user-account-menu/user-account-menu.component';
import {
  AppMenusService,
  MasterMenus,
} from '../../../core/services/app-menus.service';
import { Theme } from '../../../core/models/theme';
import { AccountAvatarComponent } from '../avatar/avatar.component';
import { ClickOutsideDirective } from "../../../core/directives/click-outside.directive";

interface UserAccountNavigationData {
  userAccountLoggedIn: boolean;
  userAccount: UserAccount | null;
  isAdmin: boolean;
  person: Person | null;
  refreshingToken: boolean;
  theme: Theme;
  userAccountMenuState: boolean;
}

@Component({
  selector: 'account-user-account-navigation',
  imports: [CommonModule, AccountAvatarComponent, UserAccountMenuComponent, ClickOutsideDirective],
  templateUrl: './user-account-navigation.component.html',
  styleUrls: ['./user-account-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAccountNavigationComponent {
  userAccountNavigationData$!: Observable<UserAccountNavigationData>;

  private appService = inject(AppService);
  private appThemesService = inject(AppThemesService);
  private appMenusService = inject(AppMenusService);
  private userAccountStore = inject(UserAccountStoreService);

  ngOnInit(): void {
    this.userAccountNavigationData$ = combineLatest([
      this.userAccountStore.userAccountLoggedIn$,
      this.userAccountStore.authenticatedUserAccountStore.entity$,
      this.userAccountStore.isAdmin(),
      this.userAccountStore.authenticatedPersonStore.entity$,
      this.appService.refreshingToken$,
      this.appThemesService.theme$,
      this.appMenusService.appMenusState$.pipe(
        map((menuState) => menuState[MasterMenus.UserAccountDropdown]),
      ),
    ]).pipe(
      map(
        ([
          userAccountLoggedIn,
          userAccountEntity,
          isAdmin,
          personEntity,
          refreshingToken,
          theme,
          userAccountMenuState,
        ]) => {
          const userAccount = userAccountEntity as UserAccount;
          const person = personEntity as Person;
          return {
            userAccountLoggedIn,
            userAccount,
            isAdmin,
            person,
            refreshingToken,
            theme,
            userAccountMenuState,
          };
        },
      ),
    );
  }

  toggleUserAccountMenuState(): void {
    this.appMenusService.toggle(MasterMenus.UserAccountDropdown);
  }
  setUserAccountMenuState(): void {
    this.appMenusService.set(MasterMenus.UserAccountDropdown, false);
  }
}
