import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NavigationSidebarContentComponent } from '../navigation-sidebar-content/navigation-sidebar-content.component';

@Component({
  selector: 'core-home-navigation',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    NavigationSidebarContentComponent
  ],
  templateUrl: './home-navigation.component.html',
  styleUrls: ['./home-navigation.component.scss', '../navigation-sidebar-content/navigation-sidebar-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeNavigationComponent extends NavigationSidebarContentComponent {

  constructor() {
    super();
  }
}
