import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationSidebarContentComponent } from '../navigation-sidebar-content/navigation-sidebar-content.component';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'core-account-navigation',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    NavigationSidebarContentComponent
  ],
  templateUrl: './account-navigation.component.html',
  styleUrls: ['./account-navigation.component.scss', '../navigation-sidebar-content/navigation-sidebar-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountNavigationComponent extends NavigationSidebarContentComponent {

  constructor() {
    super();
  }

}
