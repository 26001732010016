<div
  *ngIf="channelData() as channelData"
  class="grid-layout grid-cols-1 fill-screen-height"
>
  <section class="q90-section p-0">
    <header
      class="grid-layout grid-cols-2 spread left-heavy align-center q90-divider"
    >
      <h4>Ad insertion - {{ channelData.friendlyName }}</h4>
      <div class="button-bar">
        <a [routerLink]="['/admonitor/']">Back</a>
      </div>
    </header>
    <div class="scrollable-content">
      <div class="p-1">
        <div class="fixed-container">
          <app-server-clock />
        </div>
        <app-channel-card
          (updated)="loadData($event)"
          [channelName]="channelData.name || ''"
          [shouldUpdate]="shouldUpdate"
        >
          <section>
            <div class="channel-header-info">
              <div class="header-top-layer">
                <h5>
                  Schedule:
                  <span [innerHTML]="scheduleValid()"></span>
                </h5>
                <h5>
                  Verification:
                  <span>{{
                    errorCount() > 0 ? errorCount() + " unacknowledged" : "OK"
                  }}</span>
                </h5>
                <h5>Media: <span [innerHTML]="mediaValid()"></span></h5>
              </div>
              <div>
                <h5>
                  CCMSID: <span>{{ ccmsId }}</span>
                </h5>
              </div>
              <section class="schedule-buttons grid-layout grid-cols-2">
                <div>
                  <h5>Schedule File:</h5>
                  <button (click)="getSchedule()">
                    {{ getScheduleFilename() }}.SCH
                  </button>
                </div>
                <div>
                  <h5>Verification File:</h5>
                  <button (click)="getVerification()">
                    {{ getScheduleFilename() }}.VER
                  </button>
                </div>
              </section>
            </div>
          </section>
        </app-channel-card>
      </div>
      <div class="p-1 grid-layout grid-cols-2">
        <section>
          <div class="error-container">
            <h4>Verification Errors({{ verificationErrors().length }})</h4>
            <div class="button-bar align-left">
              <button
                (click)="acknowledgeError(selectedErrors)"
                [disabled]="selectedErrors.length === 0"
                class="action"
              >
                Ack
              </button>
              <button
                (click)="acknowledgeError()"
                [disabled]="verificationErrors().length === 0"
                class="action"
              >
                Ack all
              </button>
              <button (click)="resetAcknowledge()" class="action">Reset</button>
            </div>
            <app-data-table
              [data]="verificationErrorsKeyString()"
              [headers]="verificationTableHeaders"
              (updated)="setVerificationErrors($event)"
            />
          </div>
        </section>
        <section>
          <h4>Media Errors({{ mediaErrorList().length }})</h4>
          <div class="button-bar align-left">
            <button
              (click)="copyMedia()"
              [disabled]="
                mediaErrorList().length === 0 ||
                selectedMediaErrors.length === 0
              "
              class="action"
            >
              Copy
            </button>
          </div>
          <app-data-table
            [data]="mediaErrorListKeyString()"
            [headers]="mediaTableHeaders"
            (updated)="setMediaErrors($event)"
          />
        </section>
      </div>
    </div>
  </section>
</div>

<app-schedule-modal [modalContent]="modalContent" />
