<ng-container *ngIf="footerData$ | async as footerData">
  <footer
    *ngIf="!footerData.isCollapsed"
    id="q90-footer"
    [ngClass]="footerData.theme.class"
  >
    <p
      class="my-0"
      *ngIf="projectVersion"
    >
      Version <span class="text-color-max">{{ projectVersion }}</span>
    </p>
    <p class="my-0">&copy; 2025 Quatro90 | Product of <a
        target="_blank"
        href="https://www.mediachoice.eu/"
      >Media Choice</a></p>
    <p class="my-0">{{ angularVersion }} ({{ env.name }})</p>
  </footer>
</ng-container>
