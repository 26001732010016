import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Q90Message } from '../models/q90-message';
import { Q90ErrorResponseTypes } from '../interfaces/q90-response';

@Injectable({
  providedIn: null
})
export class ErrorMessagesStoreService {

  protected formMessagesStore = new BehaviorSubject<Q90ErrorResponseTypes | Q90Message | null>(null);
  formMessages$: Observable<Q90ErrorResponseTypes | Q90Message | null> = this.formMessagesStore.asObservable();

  setErrorMessage(errorMessage: Q90ErrorResponseTypes): void {
    this.formMessagesStore.next(errorMessage);
  }

  setMessage(errorMessage: Q90Message | null): void {
    this.formMessagesStore.next(errorMessage);
  }

}
