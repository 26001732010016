import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChannelCardComponent } from './components/channel-card/channel-card.component';
import { AiMonitorService } from '../../../api/services';
import { ServerClockComponent } from './components/server-clock/server-clock.component';
import { UserAccountStoreService } from '../../account/stores/user-account-store.service';
import { Router } from '@angular/router';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-ad-monitor',
  standalone: true,
  imports: [
    CommonModule,
    ChannelCardComponent,
    ServerClockComponent,
    RouterLink,
    RouterLinkActive,
  ],
  templateUrl: './ad-monitor.component.html',
  styleUrls: ['./ad-monitor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdMonitorComponent implements OnInit {
  aiMonitorService = inject(AiMonitorService);
  channels: WritableSignal<string[]> = signal([]);
  private userAccountStore = inject(UserAccountStoreService);
  private router = inject(Router);

  ngOnInit(): void {
    this.userAccountStore.userAccountLoggedIn$.subscribe((res) => {
      if (res === false) {
        this.router.navigate(['sign-in']);
      }
    });
    this.aiMonitorService.aimonitorChannels$Json().subscribe((res) => {
      this.channels.set(res.data);
    });
  }
}
