import { Pipe, PipeTransform } from '@angular/core';
import { TenantType } from '../../../entity/tenant/models/tenant-type';

@Pipe({
  name: 'tenantTypes',
  standalone: true
})
export class TenantTypesPipe implements PipeTransform {

  transform(value: TenantType[], ...args: any[]): string {
    let result: string[] = [];
    if (value) {
      result = value.map(item => item.optionName);
    }
    return result.join(', ');
  }

}
