<div class="grid-layout grid-cols-1 fill-screen-height">
  <section class="q90-section p-0">
    <header
      class="grid-layout grid-cols-2 spread left-heavy align-center q90-divider"
    >
      <h4>Quatro Monitor</h4>
      <app-server-clock />
    </header>
  </section>
</div>
