import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { Observable, take } from "rxjs";
import { AuthenticatedUser, AuthenticatedUserInterface } from "./models/authenticated-user";
import { AppService } from "../core/services/app.service";
import { inject } from "@angular/core";
import { AuthenticationStoreService } from "./stores/authentication-store.service";
import { ErrorService } from "../shared/services/error.service";
import { isTimeToRefreshToken } from "./authentication-is-time-to-refresh";
import { refresh } from "./authentication-refresh";
import { environment } from 'src/environments/environment';
import { Q90Response } from "../shared/models/q90-response";
import { Q90ResponseData } from "../shared/interfaces/q90-response";

export function AuthenticationInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<any>> {

  const appService = inject(AppService);
  const errorService = inject(ErrorService);
  const authenticationStore = inject(AuthenticationStoreService);

  let isRefreshing = false;

  // Let's not check for refresh when we're already refreshing...
  const requestIsRefresh = (request.url === environment.apiEndpointRoot + environment.apiEndpointVersion + '/authentication/refreshtoken');
  if (requestIsRefresh) {
    return next(request);
  }

  // Are we already refreshing?
  appService.refreshingToken$.pipe(
    take(1)
  ).subscribe(
    (isRefreshingToken) => {
      isRefreshing = isRefreshingToken;
    }
  )

  if (
    isTimeToRefreshToken()
    && !isRefreshing
  ) {
    appService.getRefreshingTokenStore().next(true);
    appService.debug("HttpInterceptor: calling refresh...");
    refresh(authenticationStore.getToken(), authenticationStore.getRefreshToken()).pipe(take(1)).subscribe({
      next: (response: Q90ResponseData<AuthenticatedUserInterface>) => {
        appService.getRefreshingTokenStore().next(false);
        if (Q90Response.isSuccess(response)) {
          const user = new AuthenticatedUser().deserialize(<AuthenticatedUserInterface>response.data);
          user.setMessages(response.resultMessages);
          authenticationStore.setAuthenticatedUser(user);
        } else {
          errorService.setError(new Q90Response().deserialize(response));
        }
      },
      error: (error) => {
        appService.getRefreshingTokenStore().next(false);
        errorService.setError(error);
      },
    });
  }
  if (
    isRefreshing
  ) {
    appService.debug("HttpInterceptor: (already) refreshing...");
  }
  return next(request);

}

