import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContentChannelType } from '../../entity/content-channel/models/content-channel-type';

@Injectable({
  providedIn: 'root',
})
export class CoreContentChannelStoreService {
  // Store for content channels types
  private contentChannelTypesStore = new BehaviorSubject<ContentChannelType[]>(
    []
  );
  contentChannelTypes$: Observable<ContentChannelType[]> =
    this.contentChannelTypesStore.asObservable();

  constructor() {}

  setContentChannelTypes(contentChannelTypes: ContentChannelType[]): void {
    this.contentChannelTypesStore.next(contentChannelTypes);
  }
}
