import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  inject,
  WritableSignal,
  signal,
} from '@angular/core';
import { ReactiveFormsModule, FormGroup } from '@angular/forms';
import { Observable, filter, takeUntil, combineLatest, map } from 'rxjs';
import { UserAccountStoreService } from '../../../account/stores/user-account-store.service';
import { MediaUploadComponent } from '../../../core/components/media-upload/media-upload.component';
import { Person, PersonKeys } from '../../../entity/person/models/person';
import { ErrorMessagesComponent } from '../../../shared/components/error/error-messages/error-messages.component';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { FormTextareaComponent } from '../../../shared/forms/components/control/form-textarea/form-textarea.component';
import { FormComponent } from '../../../shared/forms/components/form/form.component';
import { SubmitComponent } from '../../../shared/forms/components/submit/submit.component';
import { GetFormControlPipe } from '../../../shared/forms/pipes/get-form-control.pipe';
import { ErrorMessagesStoreService } from '../../../shared/stores/error-messages-store.service';
import { FormRangeSliderComponent } from '../../../shared/forms/components/control/form-range-slider/form-range-slider.component';
import { FeedbackQuery, FeedbackService } from './services/feedback.service';
import { Q90Response } from '../../../shared/models/q90-response';
import { Q90Message } from '../../../shared/models/q90-message';
import { gsap } from 'gsap';

interface FeedbackFormData {
  submitting: boolean;
  person: Person | null;
}

@Component({
  selector: 'app-feedback',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SubmitComponent,
    FormTextareaComponent,
    FormRangeSliderComponent,
    GetFormControlPipe,
    ErrorMessagesComponent,
    SpinnerComponent,
    MediaUploadComponent,
  ],
  providers: [{ provide: ErrorMessagesStoreService }],
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackComponent extends FormComponent implements OnInit {
  private userAccountStore = inject(UserAccountStoreService);
  private feedbackService = inject(FeedbackService);
  feedbackFormData$!: Observable<FeedbackFormData>;

  activeSection: WritableSignal<string> = signal('');

  sections = [
    { field: 'functionality', title: 'Functionaliteit' },
    { field: 'performance', title: 'Prestaties' },
    { field: 'userFriendlyness', title: 'Gebruikersvriendelijkheid' },
    { field: 'compatability', title: 'Compatibiliteit' },
    { field: 'design', title: 'Design en Esthetiek' },
    { field: 'bugs', title: 'Problemen en Bugs' },
    { field: 'general', title: 'Algemene Feedback' },
    { field: 'other', title: 'Overige' },
  ];

  override formGroup = this.formBuilder.group({
    person: this.formBuilder.group({
      id: this.formBuilder.control<string>(''),
    }),
    feedbackAreas: this.formBuilder.group({
      functionality_1: this.formBuilder.control<string | null>(null),
      functionality_2: this.formBuilder.control<string | null>(null),
      functionality_3: this.formBuilder.control<string | null>(null),

      performance_1: this.formBuilder.control<string | null>(null),
      performance_2: this.formBuilder.control<string | null>(null),
      performance_3: this.formBuilder.control<string | null>(null),

      userFriendlyness_1: this.formBuilder.control<string | null>(null),
      userFriendlyness_2: this.formBuilder.control<string | null>(null),
      userFriendlyness_3: this.formBuilder.control<string | null>(null),

      compatability_1: this.formBuilder.control<string | null>(null),
      compatability_2: this.formBuilder.control<string | null>(null),

      design_1: this.formBuilder.control<string | null>(null),
      design_2: this.formBuilder.control<string | null>(null),

      bugs_1: this.formBuilder.control<string | null>(null),
      bugs_2: this.formBuilder.control<string | null>(null),

      general_1: this.formBuilder.control<string | null>(null),
      general_2: this.formBuilder.control<string | null>(null),

      other: this.formBuilder.control<string | null>(null),
      attachments: this.formBuilder.array([
        this.formBuilder.group({
          id: this.formBuilder.control<string>(''),
          url: this.formBuilder.control<string>(''),
        }),
      ]),
    }),
  });
  filterGroup = this.formBuilder.group({
    functionality: this.formBuilder.control<boolean>(false),
    performance: this.formBuilder.control<boolean>(false),
    userFriendlyness: this.formBuilder.control<boolean>(false),
    compatability: this.formBuilder.control<boolean>(false),
    design: this.formBuilder.control<boolean>(false),
    bugs: this.formBuilder.control<boolean>(false),
    general: this.formBuilder.control<boolean>(false),
    other: this.formBuilder.control<boolean>(false),
  });

  get attachments() {
    return this.formGroup.controls['feedbackAreas'].controls['attachments'];
  }

  addAttachment() {
    const attachmentsForm = this.formBuilder.group({
      id: this.formBuilder.control<string>(''),
      url: this.formBuilder.control<string>(''),
    });
    this.attachments.push(attachmentsForm);
  }

  deleteAttachment(attachmentsIndex: number) {
    this.attachments.removeAt(attachmentsIndex);
  }

  override ngOnInit(): void {
    this.userAccountStore.authenticatedPersonStore.entity$
      .pipe(
        filter((person) => person instanceof Person),
        takeUntil(this.destroyed),
      )
      .subscribe((person) => {
        this.populateForm<Person, PersonKeys>(
          <Person>person,
          this.formGroup.get('person') as FormGroup,
        );
      });
    this.feedbackFormData$ = combineLatest([
      this.submitting$,
      this.userAccountStore.authenticatedPersonStore.entity$,
    ]).pipe(
      map(([submitting, personEntity]) => {
        const person = personEntity as Person;
        return {
          submitting,
          person,
        };
      }),
    );
  }

  sendFeedback() {
    const person = this.formGroup.controls['person'].value;
    const feedback = this.formGroup.controls['feedbackAreas'].value;

    if (feedback.attachments)
      feedback.attachments = feedback.attachments.filter(function (el) {
        return el.id != '' && el.url != '';
      });

    const combined = { ...person, ...feedback } as FeedbackQuery;

    this.feedbackService.sendFeedback(combined).subscribe({
      next: (response) => {
        if (response.statusCode == 200) {
          this.errorMessagesStore.setMessage(new Q90Message('Feedback sent', 'success'));
          this.formGroup.controls['feedbackAreas'].controls['attachments'].clear();
          this.formGroup.reset();
          this.filterGroup.reset();
          this.addAttachment();
        } else {
          this.errorMessagesStore.setErrorMessage(new Q90Response().deserialize(response));
        }
      },
      error: (error) => {
        this.errorMessagesStore.setErrorMessage(error);
      },
    });
  }

  private xOffset = 50;
  private duration = 0.35;

  chooseSection(section: string) {
    const cards: Element[] = gsap.utils.toArray('.animation-container');
    gsap.to(cards, {
      x: -this.xOffset,
      duration: this.duration,
      opacity: 0,
      ease: 'power1.in',
      onComplete: () => {
        this.activeSection.set(section);
        gsap.fromTo(
          cards,
          { x: this.xOffset, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            duration: this.duration,
            ease: 'power1.out',
          },
        );
      },
    });
  }
  back() {
    const cards: Element[] = gsap.utils.toArray('.animation-container');
    gsap.to(cards, {
      x: this.xOffset,
      duration: this.duration,
      opacity: 0,
      ease: 'power1.in',
      onComplete: () => {
        this.activeSection.set('');
        gsap.fromTo(
          cards,
          { x: -this.xOffset, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            duration: this.duration,
            ease: 'power1.out',
          },
        );
      },
    });
  }

  completeSection(section: string) {
    if (this.filterGroup.get([section])) this.filterGroup.get([section])?.setValue(true);
    this.chooseSection('');
  }
}
